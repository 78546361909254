import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useSponsorIdQuery = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.PROFILE_SET_SPONSOR_ID],
		(data) => api.post(endPointUrls.PROFILE_SET_SPONSOR_ID, data),
		{
			onSuccess: (res: any) => {
				queryClient.invalidateQueries([endPointUrls.PROFILE_MANAGEMENT]);
				if (res?.status === 200) {
					toastHandler('success', res.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult;
};
