import Index from './pages';
import './assets/css/pagination.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import IndexProviders from 'core/providers/IndexProviders';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Text from 'core/utils/Text';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgButton from 'styles/UI/Button/DgButton';

const App: FC = () => {
	return (
		<>
			<IndexProviders>
				<SkeletonTheme
					baseColor='var(--dg-skeleton-bg)'
					highlightColor='var(--dg-primary-color-hover)'
				>
					<ErrorBoundary
						fallbackRender={({ error, resetErrorBoundary }: any) => {
							// console.log({ error });

							return (
								<div className='flex items-center justify-center w-full min-h-screen flex-col gap-4'>
									<Text tid={'error-boundary-hint'} />

									<Text className='opacity-50' tid={error.message} />

									<div className='flex items-center gap-8'>
										<Link
											to={routes.DASHBOARD}
											onClick={() => {
												window.location.href = routes.DASHBOARD;
											}}
											className=''
										>
											<Text tid={'back'} />
										</Link>

										<DgButton
											title='reload'
											onClick={() => {
												resetErrorBoundary();

												const currentLocation = window.location.href;

												window.location.href = currentLocation;
											}}
										/>
									</div>
								</div>
							);
						}}
						onError={(...rest: any) => {
							// console.log({ rest });
						}}
					>
						<Index />
					</ErrorBoundary>
				</SkeletonTheme>
			</IndexProviders>
		</>
	);
};

export default App;
