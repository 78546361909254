import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { userDetail } from 'core/types/models/userInfo';
import { useParams } from 'react-router-dom';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { useDispatch } from 'react-redux';
import { setUserDetail } from 'core/store/slice/userInfo/userInfoSlice';

export const getUserInfoQuery = () => {
	const { userId } = useParams();
	const dispatch = useDispatch();

	return useQueryHook(
		[endPointUrls.ADMIN_USER(), userId],
		(data: any) => api.get(endPointUrls.ADMIN_USER(userId), data),
		{
			// select: (res: any) => res.data,
			onSuccess: (res: any) => {
				dispatch(setUserDetail(res));
			},
		},
		false
	) as UseQueryResult<userDetail, Error>;
};
