import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useAdminPackageUpdateQuery } from './react-query/useAdminPackageUpdateQuery';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';

const useAdminPackageUpdate = (hideModalHandler: () => void) => {
	//hooks
	const dispatch = useDispatch();
	const [updatePackageForm] = Form.useForm();

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { packageDetail } = useSelector((store: RootState) => store.packageStore);

	// query
	const { mutate: updatePackage, isLoading: isLoadingUpdatePackage } = useAdminPackageUpdateQuery(() => {
		dispatch(setHiddenModal());
	});

	const onFinishUpdatePackage = (values: any) => {
		updatePackage({ ...values, package_id: packageDetail?.id, price: values?.price + '' }, { onSuccess: () => hideModalHandler() });
	};

	useEffect(() => {
		updatePackageForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	useEffect(() => {
		updatePackageForm.setFieldsValue({ ...packageDetail });
	}, [packageDetail]);

	return {
		updatePackageForm,
		isLoadingUpdatePackage,
		onFinishUpdatePackage,
	};
};

export default useAdminPackageUpdate;
