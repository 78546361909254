import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { IWalletDepositResponse } from 'core/types/models/walletDepositType';
import { useDispatch } from 'react-redux';

// ADMIN_SUM_INVEST
// ADMIN_SUM_COMMISSIONS
// ADMIN_SUM_INCOMES
// ADMIN_SUM_ORDERS
// ADMIN_SUM_USERS
// ADMIN_WITHDRAW_COUNTS
// ADMIN_BEST_LEAST_SELLER

export const useAdminAccountingQueries = () => {
	const dispatch = useDispatch();
	const { setUrlParam } = useQueryUrlParam();

	return {
		sumInvest: useMutationHook(
			[endPointUrls.ADMIN_SUM_INVEST],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_INVEST, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		sumGatewayDeposits: useMutationHook(
			[endPointUrls.ADMIN_SUM_GATEWAY_DEPOSITS],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_GATEWAY_DEPOSITS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		sumCommissions: useMutationHook(
			[endPointUrls.ADMIN_SUM_COMMISSIONS],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_COMMISSIONS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		sumIncomes: useMutationHook(
			[endPointUrls.ADMIN_SUM_INCOMES],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_INCOMES, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		sumOrders: useMutationHook(
			[endPointUrls.ADMIN_SUM_ORDERS],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_ORDERS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		sumUsers: useMutationHook(
			[endPointUrls.ADMIN_SUM_USERS],
			(data: any) => api.get(endPointUrls.ADMIN_SUM_USERS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		withdrawCounts: useMutationHook(
			[endPointUrls.ADMIN_WITHDRAW_COUNTS],
			(data: any) => api.get(endPointUrls.ADMIN_WITHDRAW_COUNTS, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		usersDepositWalletBalances: useMutationHook(
			[endPointUrls.ADMIN_USERS_DEPOSIT_WALLET_BALANCES],
			(data: any) =>
				api.get(endPointUrls.ADMIN_USERS_DEPOSIT_WALLET_BALANCES, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		bestLeastSeller: useMutationHook(
			[endPointUrls.ADMIN_BEST_LEAST_SELLER],
			(data: any) => api.get(endPointUrls.ADMIN_BEST_LEAST_SELLER, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
		feeSum: useMutationHook(
			[endPointUrls.ADMIN_FEE_SUM],
			(data: any) => api.get(endPointUrls.ADMIN_FEE_SUM, { params: data }),
			{
				onSuccess: (res) => {
					const response = res as any;

					if (response?.status === 200) {
						// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
						// toastHandler('success', response.data.message);
						dispatch(setApiCatcherFormErrors([]));
					}
				},
			}
		) as UseMutationResult<any, Error>,
	};
};

export const useAdminSumInvestQuery = () => {
	const dispatch = useDispatch();
	const { setUrlParam } = useQueryUrlParam();

	return useMutationHook(
		[endPointUrls.ADMIN_SUM_INVEST],
		(data: any) => api.get(endPointUrls.ADMIN_SUM_INVEST, { params: data }),
		{
			onSuccess: (res) => {
				const response = res as IWalletDepositResponse;

				if (response?.status === 200) {
					// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
					// toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<IWalletDepositResponse, Error>;
};

export const useAdminSumCommissionsQuery = () => {
	const dispatch = useDispatch();
	const { setUrlParam } = useQueryUrlParam();

	return useMutationHook(
		[endPointUrls.ADMIN_SUM_COMMISSIONS],
		(data: any) => api.get(endPointUrls.ADMIN_SUM_COMMISSIONS, { params: data }),
		{
			onSuccess: (res) => {
				const response = res as IWalletDepositResponse;

				if (response?.status === 200) {
					// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
					// toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<IWalletDepositResponse, Error>;
};
