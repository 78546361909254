import UserPlanCommissionsTable from 'components/UserPlanCommissions/List/components/UserPlanCommissionsTable';
import UserPlansCommissionsFilter from 'components/UserPlanCommissions/List/components/UserPlansCommissionsFilter';
import { useUserPlanCommissionsQuery } from 'components/UserPlanCommissions/List/hooks/react-query/useUserPlanCommissionsQuery';
import UserWithdraw from 'components/UserWithdraw/UserWithdraw';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useSubUsersOrders } from './react-query';
import { useWindowSize } from 'usehooks-ts';
import useTranslate from 'core/utils/useTranslate';
import Text from 'core/utils/Text';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgCopyClipboard from 'styles/UI/CopyClipboard/DgCopyClipboard';
import { convertTimeStampToDate, hiddenText } from 'core/utils/utils';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Button, Popover } from 'antd';

const SubUserOrdersPage = () => {
	const limit = 10;

	const { data: orders, isFetching: isFetching } = useSubUsersOrders();
	const { query, handlePagination } = useQueryUrlParam();

	let data: any = [];

	if (limit && orders?.data?.list) {
		data = orders?.data?.list.slice(0, limit);
	}

	return (
		<>
			<DgCard>
				<DgCardHeader title='subordinate-user-orders' />

				<>
					<DgTable
						rowKey='id'
						columns={Table()}
						dataSource={data.length > 0 ? data : orders?.data?.list}
						count={orders?.data.pagination.total}
						loading={isFetching}
						current={orders?.data.pagination.current_page}
						per_page={query?.per_page}
						// {...props}
					/>

					{!limit && (
						<DgPagination
							onPaginationHandler={handlePagination}
							responsive={true}
							per_page={query?.per_page}
							current={orders?.data.pagination.current_page}
							total={orders?.data.pagination.total}
						/>
					)}
				</>
			</DgCard>
		</>
	);
};

const Table = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='user' />,
			// key: 'user',
			// dataIndex: 'user',
			render: (text: any, record: any) => {
				return <span>{record?.full_name}</span>;
			},
		},
		{
			title: <Text tid='total-orders' />,
			// key: 'amount',
			// dataIndex: 'amount',
			render: (text: any, record: any) => (
				<Popover
					content={
						<div className='flex flex-col gap-2'>
							{!!record?.orders?.length && (
								<>
									{record.orders.map((item) => {
										return (
											<div
												key={item.id}
												className='flex items-center justify-between gap-4'
											>
												<span>{item?.package_name}</span>
												<DgPrice price={item?.total_cost_in_pf} />
											</div>
										);
									})}
								</>
							)}
						</div>
					}
					title={t('total-orders')}
				>
					<div>
						<Button type='ghost' className='dark:text-white text-black'>
							<DgPrice price={record?.sum_orders} />
						</Button>
					</div>
				</Popover>
			),
		},
		{
			title: <Text tid='total-commissions' />,
			// key: 'amount',
			// dataIndex: 'amount',
			render: (text: any, record: any) => <DgPrice price={record?.sum_commissions} />,
		},
		{
			title: <Text tid='date-time' />,
			render: (_text: any, record: any) => (
				<DgTimeStamp nowrap timeStamp={record.created_at} />
			),
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);
				const { value, hiddenValue } = hiddenText(record.transaction_id + '');

				return (
					<>
						<DgTableColumnResponsive
							title={t('transaction-id')}
							value={<DgCopyClipboard hiddenValue={hiddenValue} value={value} />}
						/>
						<DgTableColumnResponsive
							title={t('amount')}
							value={<DgPrice price={record.amount} />}
						/>
						<DgTableColumnResponsive
							title={t('type')}
							value={<Text tid={record?.type} />}
						/>
						<DgTableColumnResponsive
							title={t('duration')}
							value={
								record?.type === 'invest-profit-commission' ||
								record?.type === 'cooperation-profit-commission' ? (
									<Text tid='monthly' />
								) : record?.type === 'marketing-profit-commission' ||
								  record?.type === 'team-profit-commission' ? (
									<Text tid='weekly' />
								) : (
									<></>
								)
							}
						/>

						<DgTableColumnResponsive
							title={t('invest-package')}
							value={
								<span
									className={`flex items-center gap-3 justify-end ${
										record?.orderedPackage?.order?.canceled_at
											? 'text-[var(--dg-red-color-2)]'
											: 'text-[var(--dg-green-color)]'
									}`}
								>
									<DgTooltip
										overlayStyle={{ maxWidth: '300px' }}
										title={
											record?.orderedPackage?.order?.canceled_at
												? t('package-canceled') + `${dateTime}`
												: t('package-active')
										}
									>
										<AiOutlineInfoCircle size={20} />
									</DgTooltip>
									{record?.orderedPackage?.order?.package.name}
								</span>
							}
						/>

						<DgTableColumnResponsive
							title={t('date-time')}
							value={<DgTimeStamp nowrap timeStamp={record.created_at} />}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default SubUserOrdersPage;
