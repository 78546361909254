import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import DgSkeleton from '../Skeleton/DgSkeleton';

interface IDgDataCard {
	className?: string;
	loading?: boolean;
	children?: ReactNode;
}

const DgDataCard: FC<IDgDataCard> = ({ className, loading, children }) => {
	return (
		<div className={twMerge(` justify-start ${loading ? 'h-32' : 'p-3'} relative bg-[var(--dg-card-bg-1)] border border-[var(--dg-border-color)] rounded-md shadow-sm ${className}`)}>
			{loading ? <DgSkeleton height={128} className='absolute w-full h-full' /> : children}
		</div>
	);
};

export default DgDataCard;
