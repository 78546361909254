import { useAdd2FAOnTokenQuery } from 'components/LoginSignUp/Login/hooks/react-query/useAdd2FAOnTokenQuery';
import { useLoginQuery } from 'components/LoginSignUp/Login/hooks/react-query/useLoginQuery';
import useLogin from 'components/LoginSignUp/Login/hooks/useLogin';
import LoginRegister from 'components/LoginSignUp/LoginRegister';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DgModal from 'styles/UI/Modal/DgModal';
// import { useMutationState } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { Button } from 'antd';
import DgButton from 'styles/UI/Button/DgButton';
import Text from 'core/utils/Text';

const LoginRegisterPage = () => {
	const { theme, lang } = useSelector((store: RootState) => store.settingStore);
	const kycModal = useModal();

	const loginQuery = useLoginQuery();
	const add2FAOnTokenQuery = useAdd2FAOnTokenQuery();

	console.log({ loginQuery, add2FAOnTokenQuery });

	// const mutationState = useMutationState({
	// 	filters: { mutationKey: [endPointUrls.LOGIN] },
	// 	select: (mutation) => mutation.state,
	// });

	// useEffect(() => {
	// 	if (mutationState?.length) {
	// 		const lastState: any = mutationState[mutationState.length - 1];

	// 		if (lastState?.error?.response?.status === 409) {
	// 			showModal();
	// 		}
	// 	}
	// }, [mutationState]);

	return (
		<>
			<DgModal
				title='description'
				open={kycModal.modal}
				onCancel={kycModal.hideModal}
				footer={
					<div className='flex items-center gap-2 flex-wrap'>
						<a href='https://exfinito.com' target='_blank' rel='noreferrer'>
							<DgButton title='open-exchanger' />
						</a>
						<DgButton onClick={kycModal.hideModal} type='default' title='confirm' />
					</div>
				}
			>
				<Text tid='please-verify-user-account' />
			</DgModal>

			<div className='min-h-[calc(100vh_-_90px)] flex items-center justify-start relative w-full md:px-16 px-4'>
				<div className='grow max-w-2xl md:py-12'>
					<div
						className={`relative z-10 backdrop-blur-md rounded-lg md:p-12 p-6 ${
							theme === 'dark' ? ' bg-[rgba(0,0,0,0.35)]' : 'bg-[rgba(255,255,255,0.5)]'
						}`}
					>
						<LoginRegister />
					</div>
				</div>

				<div className='min-h-screen min-w-screen h-full w-full fixed top-0 left-0 right-0 bottom-0'>
					<img
						src={require('assets/images/pic.jpg')}
						className='h-full w-full object-cover'
						alt=''
					/>
				</div>
			</div>
		</>
	);
};

export default LoginRegisterPage;
