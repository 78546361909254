import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { userActivityList } from 'core/types/models/userActivityType';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useParams } from 'react-router-dom';

export const useUserActivityQuery = () => {
	const { filterParams } = useQueryUrlParam();
	const { userId } = useParams();
	return useQueryHook(
		[endPointUrls.ADMIN_USER_ACTIVITY(userId), filterParams],
		() => api.get(endPointUrls.ADMIN_USER_ACTIVITY(userId, filterParams)),
		{
			// select: (res: any) => res.data,
		}
	) as UseQueryResult<userActivityList, Error>;
};
