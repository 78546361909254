import { RootState } from 'core/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserFreezeUnFreezeQuery } from './react-query/useUserFreezeUnFreezeQuery';

const useUserFreezeUnFreeze = () => {
	// hooks
	const { userId } = useParams();

	// state
	const [block, setBlock] = useState(false);

	// query
	const { mutate: userFreezeUnFreeze, isLoading: freezeUnFreezeIsLoading } = useUserFreezeUnFreezeQuery();

	// store
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	useEffect(() => {
		setBlock(userDetail.data.is_freeze);
	}, [userDetail.data]);

	const onConfirm = () => {
		userFreezeUnFreeze({ user_id: userId, status: block ? 'unfreeze' : 'freeze' });
		setBlock(!block);
	};

	return { block, onConfirm, freezeUnFreezeIsLoading };
};

export default useUserFreezeUnFreeze;
