import { FC } from 'react';
import UserDepositWallets from './components/UserDepositWallets';
import UserInvoice from 'components/UserInvoice/UserInvoice';
import UserEarnWallets from './components/UserEarnWallets';

const UserWallets: FC = () => {
	return (
		<div className='grid gap-5'>
			<UserDepositWallets />
			<UserInvoice />
			{/* <UserEarnWallets /> */}
		</div>
	);
};

export default UserWallets;
