import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import { IPlanCommission } from 'core/types/models/planCommissionsType';
import { convertTimeStampToDate } from 'core/utils/utils';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgTimeStamp from '../../../../../styles/UI/Timstamp/DgTimeStamp';

const AdminPlanCommissionsTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='user' />,
			key: 'name',
			dataIndex: '',
			render: (text: any, record: any) => {
				return (
					<Link
						to={routes.ADMIN_USER_Detail.link(record.user?.id)}
						className='w-full flex flex-col items-start justify-center gap-1 group'
					>
						<Text
							tid={`${record.user?.full_name} ${
								(record.user?.member_id || record?.user_member_id) &&
								`(${record.user?.member_id || record?.user_member_id})`
							}`}
						/>
						<Text tid={`(${record.user?.email || record.user?.username})`} />
					</Link>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			render: (text: any) => <DgPrice price={text} />,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => <span className='whitespace-nowrap'>{t(text)}</span>,
			sorter: false,
		},
		{
			title: <Text tid='invest-package' />,
			render: (_text: any, record: IPlanCommission) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);
				return (
					<DgTooltip
						overlayStyle={{ maxWidth: '300px' }}
						title={
							record?.orderedPackage?.order?.canceled_at
								? t('package-canceled') + `${dateTime}`
								: t('package-active')
						}
					>
						<span
							className={`whitespace-nowrap ${
								record?.orderedPackage?.order?.canceled_at
									? 'text-[var(--dg-red-color-2)]'
									: 'text-[var(--dg-green-color)]'
							}`}
						>
							{record?.orderedPackage?.order?.package.name}
						</span>
					</DgTooltip>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='payment-date' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
		},
	];

	const RColumn = [
		{
			sorter: false,
			title: '',
			render: (_text: any, record: any) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);

				return (
					<>
						<DgTableColumnResponsive
							title={t('user')}
							value={
								<>
									<Link
										to={routes.ADMIN_USER_Detail.link(record?.user?.id)}
										className='w-full flex flex-col items-end justify-center gap-1 group'
									>
										<Text
											tid={`${record.user?.full_name} ${
												(record.user?.member_id || record?.user_member_id) &&
												`(${record.user?.member_id || record?.user_member_id})`
											}`}
										/>
										<Text tid={`(${record.user?.email || record.user?.username})`} />
									</Link>
								</>
							}
						/>
						<DgTableColumnResponsive
							title={t('amount')}
							value={<DgPrice price={record.amount} />}
						/>
						<DgTableColumnResponsive title={t('type')} value={t(record?.type)} />

						<DgTableColumnResponsive
							title={t('invest-package')}
							value={
								<span
									className={`flex items-center gap-3 justify-end ${
										record?.orderedPackage?.order?.canceled_at
											? 'text-[var(--dg-red-color-2)]'
											: 'text-[var(--dg-green-color)]'
									}`}
								>
									<DgTooltip
										overlayStyle={{ maxWidth: '300px' }}
										title={
											record?.orderedPackage?.order?.canceled_at
												? t('package-canceled') + `${dateTime}`
												: t('package-active')
										}
									>
										<AiOutlineInfoCircle size={20} />
									</DgTooltip>
									{record?.orderedPackage?.order?.package.name}
								</span>
							}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminPlanCommissionsTableColumn;
