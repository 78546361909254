import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import DgAlert from 'styles/UI/Alert/DgAlert';
import DgButton from 'styles/UI/Button/DgButton';

const Google2faAlert = () => {
	const navigate = useNavigate();
	return (
		<DgAlert
			className='bg-[var(--dg-red-color-2)] border-0 lg-600:flex-row flex-col flex-wrap gap-12'
			message={
				<p className='flex gap-x-3 m-0 h-10 items-center'>
					<span>
						<AiFillCloseCircle size={20} />
					</span>
					<Text
						tid='please-active-totp-verification'
						className='text-base md:whitespace-nowrap'
					/>
				</p>
			}
			action={
				<DgButton
					title='totp-verification'
					onClick={() => navigate(routes.PROFILE_SECURITY_ACTIVE_2FA)}
				/>
			}
		/>
	);
};

export default Google2faAlert;
