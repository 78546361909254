import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { INotificationList } from 'core/types/models/notificationType';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationList } from 'core/store/slice/notification/notificationSlice';
import { RootState } from 'core/store';

export const useUserNotificationsQuery = (query?: string) => {
	const { filterParams } = useQueryUrlParam();
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	return useQueryHook(
		[endPointUrls.NOTIFICATIONS_LIST(), query || filterParams],
		() => api.get(endPointUrls.NOTIFICATIONS_LIST(query || filterParams)),
		{
			// select: (res: any) => res.data,
			onSuccess: (res: any) => {
				const response: INotificationList = res;
				query &&
					dispatch(
						setNotificationList(
							response?.data?.list?.filter((item) => item.is_read === 0).slice(0, 11)
						)
					);
			},
			enabled: isAuthenticated,
		},
		false
	) as UseQueryResult<INotificationList, Error>;
};
