import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ITransferList } from 'core/types/models/transferType';

export const useUserTransferQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.WALLET_DEPOSIT_TRANSFERS_LIST(), filterParams],
		(data: any) =>
			api.get(endPointUrls.WALLET_DEPOSIT_TRANSFERS_LIST(filterParams), data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<ITransferList, Error>;
};
