import { Form, Steps } from 'antd';
import PackageList from 'components/PackageList/PackageList';
import { routes } from 'core/constants/routes';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useTranslate from 'core/utils/useTranslate';
import { extractNumberFromString } from 'core/utils/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DgButton from 'styles/UI/Button/DgButton';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import OrderPayment from './components/OrderPayment';
import toastHandler from 'core/helpers/toast/toast';
import { useOrderSubmitQuery } from './hooks/react-query/useOrderSubmitQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import OrderSuccessFull from './components/OrderSuccessFull';
import DgPrice from 'styles/UI/Price/DgPrice';
import Text from 'core/utils/Text';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useDebounce } from 'usehooks-ts';
import DgPackageDateDelay from '../Basic/DgPackageDateDelay';

const OrderSubmit = () => {
	const { pathnameLastPart, query, filterParams } = useQueryUrlParam();
	const navigate = useNavigate();
	const { t } = useTranslate();
	const queryClient = useQueryClient();
	const [form] = Form.useForm();
	const { modal, showModal, hideModal } = useModal();

	const { data: order,mutate, isLoading: isLoadingSubmitOrder } = useOrderSubmitQuery(() => next());

	const [customPrice, setCustomPrice] = useState('');

	const debouncedCustomPrice = useDebounce<string>(customPrice, 1000);

	const { userWallet } = useSelector((store: RootState) => store.walletStore);

	const steps = [
		{
			title: t('select-package'),
			content: <PackageList queryType='package_id' />,
			key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		},
		{
			title: t('package-payment'),
			content: (
				<OrderPayment
					customPrice={customPrice}
					setCustomPrice={setCustomPrice}
					onValuesChange={({ price }: { price: any }) => {
						setCustomPrice(price);
					}}
					form={form}
				/>
			),
			key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		},
		{
			title: t('invest-successful'),
			content: <OrderSuccessFull order={order}  />,
			key: extractNumberFromString(pathnameLastPart ? pathnameLastPart : ''),
		},
	];

	const [current, setCurrent] = useState(
		+extractNumberFromString(pathnameLastPart ? pathnameLastPart : '')
	);

	const next = () => {
		if (!query?.package_id) return toastHandler('error', t('please select package'));
		queryClient.invalidateQueries([endPointUrls.WALLETS]);
		setCurrent(current + 1);
		navigate(
			routes.ORDER_SUBMIT_STEPS.link(`package-buy-step-${current + 1}?${filterParams}`)
		);
		hideModal();
	};

	const prev = () => {
		setCurrent(current - 1);
		navigate(
			routes.ORDER_SUBMIT_STEPS.link(`package-buy-step-${current - 1}?${filterParams}`)
		);
	};
	const items = steps.map((item) => ({ key: item.key, title: item.title }));

	const onSubmitOrder = (values: { one_time_password: string }) => {
		mutate({
			package_id: query?.package_id,
			one_time_password: values.one_time_password,
			price: form.getFieldsValue().price || undefined,
		});
	};

	const onFinishOrder = (values: { one_time_password: string }) => {
		onSubmitOrder(values);
	};

	useEffect(() => {
		setCustomPrice('');
	}, [current]);

	return (
		<>
			<DgModal title='totp-verification-code' open={modal} onCancel={hideModal}>
				<DgForm form={form} onFinish={onFinishOrder}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>
						<DgButton title='submit' isLoading={isLoadingSubmitOrder} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>


			<div className={'mb-4'}>
				<DgPackageDateDelay />
			</div>

			<DgCard>
				<DgCardHeader
					title='package-purchase'
					customButton={
						<div className='flex items-center gap-10'>
							<span className='flex flex-col'>
								<Text tid='my-balance' className='text-[var(--dg-orange-color)]' />
								<DgPrice price={userWallet?.balance} />
							</span>
							<DgButton
								title='deposit-wallets'
								onClick={() => navigate(routes.WALLETS)}
							/>
						</div>
					}
				/>
				<Steps
					current={+extractNumberFromString(pathnameLastPart ? pathnameLastPart : '') - 1}
					items={items}
				/>

				<div className='steps-content'>
					<div className='my-10'>{steps[current - 1].content}</div>
				</div>

				<div className='steps-action my-5 text-center flex flex-col gap-5 xs:flex-row justify-center'>
					{current - 1 === 0 && (
						<DgButton
							className='min-w-[200px]'
							title='next-step'
							type='primary'
							onClick={() => next()}
						/>
					)}
					{current - 1 === 1 && (
						<DgButton
							className='min-w-[200px]'
							title='confirm-invest'
							type='primary'
							onClick={showModal}
						/>
					)}
					{current - 1 === steps.length - 1 && (
						<DgButton
							className='min-w-[200px]'
							type='primary'
							title='order-list'
							onClick={() => navigate(routes.ORDERS_LIST)}
						/>
					)}
					{current - 1 !== steps.length - 1 && current - 1 > 0 && (
						<DgButton
							className='min-w-[200px]'
							title='previous-step'
							type='default'
							onClick={() => prev()}
						/>
					)}
				</div>
			</DgCard>
		</>
	);
};

export default OrderSubmit;
