import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import { useBannersQuery } from './hooks/react-query/useBannersQuery';
import DgButton from 'styles/UI/Button/DgButton';
import Dragger from 'antd/lib/upload/Dragger';
import { FaInbox, FaUpload } from 'react-icons/fa';
import { useMemo, useState } from 'react';
import { useBannersMutation } from './hooks/react-query/useBannersMutation';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';

export const useBannerPreview = (image?: string) => {
	const b64toBlob = (b64Data: any, contentType: any = '', sliceSize: any = 512) => {
		const byteCharacters = window.atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const makePreview = (image: string | undefined) =>
		image?.length ? URL.createObjectURL(b64toBlob(image, 'image/png')) : undefined;

	const preview = useMemo(() => {
		return makePreview(image);
	}, [image]);

	return { preview, makePreview, b64toBlob };
};

const AdminBanner = () => {
	const { data: { data: banners } = {} as any, isFetching: bannersIsFetching } =
		useBannersQuery() as any;

	// // console.log(banners);

	return (
		<>
			<DgCard>
				{/* <DgCardHeader
					title='banner-management'
					onClick={showModal}
					buttonText='new-banner'
				/> */}
				<DgCardHeader title='banner-management' />

				<div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
					{[
						{ key: 'first', data: banners?.first },
						{ key: 'second', data: banners?.second },
					].map((item, index) => (
						<BannerItem
							key={item.data?.file || item.key}
							data={item.data}
							// index={item.key}
							index={index + 1}
						/>
					))}
				</div>
			</DgCard>
		</>
	);
};

const BannerItem = ({ data: item, index }: any) => {
	const { refetch } = useBannersQuery({ refetchOnMount: false });

	const { data, isLoading, mutate } = useBannersMutation(() => {
		refetch();
		setFile(undefined);
	});

	const [file, setFile]: any = useState(undefined);
	const [link, setLink]: any = useState(item?.link);

	const { preview: image } = useBannerPreview(item?.file);

	// // console.log({ link, file, image, item });

	return (
		<>
			<div
				className={
					'relative flex flex-col items-stretch justify-start gap-4 p-5 rounded-lg hover:shadow-xl bg-dg-card-bg-3'
				}
			>
				<h1 className='text-dg-white-color text-2xl '>{index} banner</h1>

				{!!image?.length && <img className='w-full' src={image} />}

				<div className='mb-12 flex flex-col gap-12'>
					<Dragger
						{...{
							key: index,
							name: 'file',
							multiple: false,
							maxCount: 1,
							accept: 'image/*',
							disabled: isLoading,
							beforeUpload: () => false,
							onChange(info) {
								// const { status } = info.file;

								setFile(info.file);
							},
							onRemove() {
								setFile(undefined);
							},
							className: 'w-full',
						}}
					>
						<div className='flex  flex-col gap-2 items-center justify-center'>
							<span className='ant-upload-drag-icon'>
								<FaUpload size={'2.5rem'} />
							</span>
							{/* <p className='ant-upload-text'>Click or drag file to this area to upload</p>
						<p className='ant-upload-hint'>
							Support for a single or bulk upload. Strictly prohibited from uploading
							company data or other banned files.
						</p> */}
						</div>
					</Dragger>

					<DgInputLabel
						key={'link' + index}
						label='link'
						value={link}
						onChange={(e) => {
							// // console.log(e.currentTarget.value);

							setLink(e.currentTarget.value);
						}}
					/>
				</div>

				{!!file && !!link?.length && (
					<DgButton
						key={item?.key || index}
						disabled={isLoading}
						onClick={() => {
							mutate({ banner: index, image: file, link });
						}}
						title='upload'
					/>
				)}
			</div>
		</>
	);
};

export default AdminBanner;
