import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import { FC, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import useRegister from '../hooks/useRegister';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import { Checkbox, Collapse } from 'antd';
import DgModal from 'styles/UI/Modal/DgModal';
import useModal from 'core/hooks/useModal';
import { RulesModal } from 'components/rules/rules-modal';
import { useSearchParams } from 'react-router-dom';

const Register: FC = () => {
	const [rulesAccepted, setRulesAccepted] = useState<boolean>(false);

	const {
		onFinishSignUp,
		signUpForm,
		isLoadingRegister,
		onChangeCaptcha,
		isShowCaptcha,
		genderOnChangeHandler,
	} = useRegister();

	const { query, setUrlParam } = useMasterQueryUrlParam();

	const [search] = useSearchParams();

	const { t } = useTranslate();

	const modal = useModal();
	useEffect(() => {
		const rules = search.get('rules');
		if (!!rules) modal.showModal();
	}, [search]);

	useEffect(() => {
		if (query?.rules === 1) {
			modal.showModal();
		}
	}, [query?.rules]);

	return (
		<>
			<RulesModal useModal={modal} />

			<DgForm autoComplete='off' onFinish={onFinishSignUp} form={signUpForm}>
				<DgFormWrapper>
					<DgFormItem
						// rules={[
						// 	{
						// 		required: true,
						// 		message: t('first-name-is-required'),
						// 	},
						// ]}
						name='first_name'
					>
						<DgInputLabel label={t('first-name')} required />
					</DgFormItem>

					<DgFormItem
						// rules={[
						// 	{
						// 		required: true,
						// 		message: t('last-name-is-required'),
						// 	},
						// ]}
						name='last_name'
					>
						<DgInputLabel label={t('last-name')} required />
					</DgFormItem>

					<DgFormItem
						// rules={[
						// 	{
						// 		required: true,
						// 		message: t('username-is-required'),
						// 	},
						// ]}
						name='username'
					>
						<DgInputLabel label={t('username')} required />
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('email-is-required'),
							},
							{
								type: 'email',
								message: t('email-format-incorrect'),
							},
						]}
						name='email'
					>
						<DgInputLabel autoComplete='new-password' label='email' required />
					</DgFormItem>

					<DgFormItem
						name='password'
						rules={[
							{
								required: true,
								message: t('password-is-required'),
							},
						]}
					>
						<DgInputPasswordLabel label='password' autoComplete='new-password' required />
					</DgFormItem>

					<DgFormItem
						name='password_confirmation'
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: t('confirm-password-is-required'),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(t('password-not-same')));
								},
							}),
						]}
					>
						<DgInputPasswordLabel label='confirm-password' required />
					</DgFormItem>

					<DgFormItem
						rules={
							[
								// {
								// 	required: true,
								// 	message: t('sponsor-id-is-required'),
								// },
							]
						}
						full
						name='sponsor_id'
					>
						<DgInputLabel label='sponsor_id' />
					</DgFormItem>
				</DgFormWrapper>

				<DgFormWrapper>
					<DgFormItem name='gender'>
						<DgRadioButtonGroup onChange={genderOnChangeHandler}>
							<DgRadioButton value='Male'>{t('male')}</DgRadioButton>
							<DgRadioButton value='Female'>{t('female')}</DgRadioButton>
						</DgRadioButtonGroup>
					</DgFormItem>
				</DgFormWrapper>

				<DgFormWrapper>
					{isShowCaptcha && (
						<DgFormItem>
							<ReCAPTCHA
								theme='dark'
								lang='persian'
								sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
								onChange={onChangeCaptcha}
							/>
						</DgFormItem>
					)}
				</DgFormWrapper>

				<Checkbox
					checked={rulesAccepted}
					onChange={(e) => {
						// console.log({ rulesAccepted, modal });

						if (!rulesAccepted) {
							modal.showModal();
						}
						setRulesAccepted(e.target.checked);
					}}
				>
					{t('i-agree-the-rules')}
				</Checkbox>

				<DgFormItem button>
					<div className='flex justify-between items-center gap-6 flex-wrap'>
						<div className='flex items-center gap-3'>
							<Text tid='have-account' />

							<span
								onClick={() => setUrlParam({ page: 1 })}
								className='hover:border-b text-base border-b border-b-transparent hover:border-b-[var(--dg-orange-color)] text-[var(--dg-orange-color)] cursor-pointer'
							>
								<Text tid='login' />
							</span>
						</div>
						<DgButton
							htmlType='submit'
							isLoading={isLoadingRegister}
							disabled={isLoadingRegister || !rulesAccepted}
							title='signup'
						/>
					</div>
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default Register;
