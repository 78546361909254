import { Badge, DatePicker } from 'antd';
import DgCard from 'styles/UI/Card/DgCard';
import Text from 'core/utils/Text';
import { BsCalculator } from 'react-icons/bs';
import { useState } from 'react';
import dayjs from 'dayjs';
import DgButton from 'styles/UI/Button/DgButton';
import { useAccountingQueries } from './hooks/react-query/useAccountingQuery';
import DgDataCard from 'styles/UI/DataCard/DgDataCard';
import DashboardTitle from 'components/Dashboard/components/DashboardTitle/DashboardTitle';
import DgPrice from 'styles/UI/Price/DgPrice';

const { RangePicker } = DatePicker;

const Accounting = () => {
	const [startDate, setStartDate] = useState(undefined);
	const [endDate, setEndDate] = useState(undefined);

	const {
		INVEST_INFO,
		ORDER_INCOME_OUTCOME,
		SUM_COMMISSIONS,
		SUM_ORDERS,
		TOTAL_WITHDRAWS_COUNT,
	} = useAccountingQueries();

	const submit = () => {
		const data = {
			from_date: dayjs(startDate).format('YYYY-MM-DD'),
			to_date: dayjs(endDate).format('YYYY-MM-DD'),
		};

		INVEST_INFO.mutate(data);
		ORDER_INCOME_OUTCOME.mutate(data);
		SUM_COMMISSIONS.mutate(data);
		SUM_ORDERS.mutate(data);
		TOTAL_WITHDRAWS_COUNT.mutate(data);
	};

	return (
		<>
			<div className='grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-xl lg:max-w-none m-auto'>
				<div className='col-span-1'>
					<DgCard className='flex flex-col items-center justify-center gap-4'>
						<span>
							<Text
								tid='accounting'
								className='text-[var(--dg-orange-color)] font-bold text-2xl'
							/>
						</span>

						<BsCalculator size={50} className='mx-auto' />

						<div className='flex flex-col gap-4 w-full max-w-sm'>
							<div className='text-center flex flex-col gap-3'>
								{/* <Text tid='withdraw-title' className='text-lg font-bold' /> */}
								<Text tid='accounting-info' />
							</div>

							<RangePicker
								onChange={(value) => {
									if (!!value?.length) {
										setStartDate(dayjs(value[0]));
										setEndDate(dayjs(value[1]));
									} else {
										setStartDate(undefined);
										setEndDate(undefined);
									}
								}}
								className='w-full'
							/>

							<DgButton
								title='calculate'
								className='w-full'
								htmlType='button'
								type='default'
								loading={
									INVEST_INFO.isLoading ||
									ORDER_INCOME_OUTCOME.isLoading ||
									SUM_COMMISSIONS.isLoading ||
									SUM_ORDERS.isLoading ||
									TOTAL_WITHDRAWS_COUNT.isLoading
								}
								disabled={!startDate || !endDate}
								onClick={() => submit()}
							/>
						</div>
					</DgCard>
				</div>

				<div className='lg:col-span-2 col-span-1 grid lg:grid-cols-2 grid-cols-1 gap-4'>
					{[
						{
							label: 'sumInvest',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={INVEST_INFO?.data?.data?.data} />
									</div>
								);
							},
							queryObject: INVEST_INFO,
						},
						{
							label: 'sumIncomes',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={ORDER_INCOME_OUTCOME?.data?.data?.data?.income_sum} />
									</div>
								);
							},
							queryObject: ORDER_INCOME_OUTCOME,
						},
						{
							label: 'sumCommissions',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={SUM_COMMISSIONS?.data?.data?.data} />
									</div>
								);
							},
							queryObject: SUM_COMMISSIONS,
						},
						{
							label: 'sumOrders',
							component: () => {
								return (
									<div dir='ltr'>
										<DgPrice price={SUM_ORDERS?.data?.data?.data?.paid_orders_sum} />
									</div>
								);
							},
							queryObject: SUM_ORDERS,
						},
						{
							label: 'withdraw_count_all_requests',
							component: () => {
								return (
									<div>
										<Text
											tid={
												(TOTAL_WITHDRAWS_COUNT?.data?.data?.data
													?.count_all_requests as any) || 0
											}
										/>{' '}
										<Text tid={'requests'} />
									</div>
								);
							},
							queryObject: TOTAL_WITHDRAWS_COUNT,
						},
						{
							label: 'withdraw_pending_requests',
							component: () => {
								return (
									<div className='flex flex-col items-center justify-center gap-2'>
										<div>
											<Text
												tid={
													(TOTAL_WITHDRAWS_COUNT?.data?.data?.data
														?.count_pending_requests as any) || 0
												}
											/>{' '}
											<Text tid={'requests'} />
										</div>

										<DgPrice
											price={
												TOTAL_WITHDRAWS_COUNT?.data?.data?.data
													?.sum_amount_pending_requests
											}
										/>
									</div>
								);
							},
							queryObject: TOTAL_WITHDRAWS_COUNT,
						},
						{
							label: 'withdraw_postponed_requests',
							component: () => {
								return (
									<div className='flex flex-col items-center justify-center gap-2'>
										<div>
											<Text
												tid={
													(TOTAL_WITHDRAWS_COUNT?.data?.data?.data
														?.count_postponed_requests as any) || 0
												}
											/>{' '}
											<Text tid={'requests'} />
										</div>

										<DgPrice
											price={
												TOTAL_WITHDRAWS_COUNT?.data?.data?.data
													?.sum_amount_postponed_requests
											}
										/>
									</div>
								);
							},
							queryObject: TOTAL_WITHDRAWS_COUNT,
						},
						{
							label: 'withdraw_processed_requests',
							component: () => {
								return (
									<div className='flex flex-col items-center justify-center gap-2'>
										<div>
											<Text
												tid={
													(TOTAL_WITHDRAWS_COUNT?.data?.data?.data
														?.count_processed_requests as any) || 0
												}
											/>{' '}
											<Text tid={'requests'} />
										</div>

										<DgPrice
											price={
												TOTAL_WITHDRAWS_COUNT?.data?.data?.data
													?.sum_amount_processed_requests
											}
										/>
									</div>
								);
							},
							queryObject: TOTAL_WITHDRAWS_COUNT,
						},
						{
							label: 'withdraw_rejected_requests',
							component: () => {
								return (
									<div className='flex flex-col items-center justify-center gap-2'>
										<div>
											<Text
												tid={
													(TOTAL_WITHDRAWS_COUNT?.data?.data?.data
														?.count_rejected_requests as any) || 0
												}
											/>{' '}
											<Text tid={'requests'} />
										</div>

										<DgPrice
											price={
												TOTAL_WITHDRAWS_COUNT?.data?.data?.data
													?.sum_amount_rejected_requests
											}
										/>
									</div>
								);
							},
							queryObject: TOTAL_WITHDRAWS_COUNT,
						},
					].map((item) => {
						const { queryObject, label, component } = item;

						return (
							<DgDataCard
								key={label}
								loading={queryObject.isLoading}
								className={'col-span-1 flex flex-col gap-4 justify-between items-center'}
							>
								<DashboardTitle>
									<Text tid={label} />
								</DashboardTitle>
								<div className={'px-4'}>{component()}</div>
							</DgDataCard>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default Accounting;
