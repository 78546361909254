import { MenuProps } from 'antd';
import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminUserAssignPackage } from '../../AssignPackage';
import DgModal from 'styles/UI/Modal/DgModal';

const UserTableQuickAccess = ({ userId }: { userId: number }) => {
	const items: MenuProps['items'] = [
		// {
		// 	key: '1',
		// 	label: (
		// 		<Link
		// 			className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
		// 			to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-status')}
		// 		>
		// 			<Text tid='verify-user' />
		// 		</Link>
		// 	),
		// },
		// {
		// 	key: '2',
		// 	label: (
		// 		<Link
		// 			className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
		// 			to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-wallets')}
		// 		>
		// 			<Text tid='user-wallets' />
		// 		</Link>
		// 	),
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<Link
		// 			className='py-2 px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
		// 			to={routes.ADMIN_USER_Detail_PAGE.link(userId, 'user-security')}
		// 		>
		// 			<Text tid='user-security' />
		// 		</Link>
		// 	),
		// },
		{
			key: '4',
			label: (
				<>
					<Assign userId={userId} />
				</>
			),
		},
	];
	return items;
};

const Assign = ({ userId }: { userId: number }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<span
				onClick={() => {
					setOpen(true);
				}}
			>
				<Text
					className='py-2 cursor-pointer px-3 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'
					tid='assign-package'
				/>
			</span>
			<DgModal open={open} onCancel={() => setOpen(false)}>
				<AdminUserAssignPackage
					callback={() => {
						setOpen(false);
					}}
					user={userId}
				/>
			</DgModal>
		</>
	);
};

export default UserTableQuickAccess;
