import Text from 'core/utils/Text';
import { FaClipboardCheck } from 'react-icons/fa';
import DgButton from '../../../styles/UI/Button/DgButton';
import useModal from '../../../core/hooks/useModal';
import DgModal from '../../../styles/UI/Modal/DgModal';

const OrderSuccessFull = ({ order: any }) => {

	const { modal, showModal, hideModal } = useModal();

	return (
		<div className='text-xl text-[var(--dg-green-color-hover)] flex items-center justify-center flex-col gap-10'>
			<FaClipboardCheck size={50} />
			<p className='text-center'>
				<Text tid='your-order-successfully-submitted' />
			</p>
			<DgButton
				title={'download-invoice'}
			/>

			<DgModal
				onCancel={hideModal}
				open={modal}
			>

			</DgModal>
		</div>
	);
};

export default OrderSuccessFull;
