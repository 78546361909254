import { ReactComponent as ActiveCheck } from 'assets/images/active-check.svg';
import { ReactComponent as DeActiveCheck } from 'assets/images/deactive-check.svg';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';

import { FC } from 'react';
import { useSelector } from 'react-redux';

interface IDepositSteps {
	isSelected: boolean;
	title: string;
	desc: string;
}

const DepositSteps: FC<IDepositSteps> = ({ isSelected, desc, title }) => {
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);
    
	return (
		<div
			className={`py-3 px-5 relative ${
				isSelected
					? 'before:border-l-4 before:absolute before:h-4/5 before:-translate-y-1/2 ltr:before:left-0 rtl:before:right-0 before:top-1/2 before:rounded-full before:border-[var(--dg-orange-color)]'
					: ''
			}`}
		>
			<div className='flex items-center gap-2'>
				{isSelected ? <ActiveCheck width={35} /> : <DeActiveCheck width={35} />}

				<Text tid={title} className={isSelected ? '' : 'text-[var(--dg-disabled-color-light)]'} />
			</div>
			<Text tid={desc} className={`text-xs mt-1 ${isEnglish ? 'ml-2' : 'mr-2'} ${isSelected ? '' : 'text-[var(--dg-disabled-color-light)]'}`} />
		</div>
	);
};

export default DepositSteps;
