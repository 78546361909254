import DgCard from '../../../styles/UI/Card/DgCard';
import DgCardHeader from '../../../styles/UI/CardHeader/DgCardHeader';
import RegistersTable from '../../../components/Admin/Registers/RegistersTable';

const AdminRegisters = () => {
	return (
		<DgCard>
			<DgCardHeader title='registers' />
			<RegistersTable />
		</DgCard>
	);
};

export default AdminRegisters;
