import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IOrderList } from 'core/types/models/orderType';

export const useUserActiveOrdersQuery = () => {
	return useQueryHook(
		[endPointUrls.ORDERS_ACTIVE()],
		() => api.get(endPointUrls.ORDERS_ACTIVE()),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IOrderList, Error>;
};
