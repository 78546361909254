import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { INotification } from 'core/types/models/notificationType';
import DgTag from 'styles/UI/Tag/DgTag';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import useTranslate from 'core/utils/useTranslate';

const ProfileNotificationsColumn = (setSeenNotification: (val: any) => void, showModal: () => void, setDescription: (val: any) => void) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='title' />,
			key: 'title',
			dataIndex: 'title',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='description' />,
			key: 'body',
			dataIndex: 'body',
			render: (text: any) => (
				<DgTooltip title={<div dangerouslySetInnerHTML={{ __html: text }} />}>
					<div className='line-clamp-3'>
						<div dangerouslySetInnerHTML={{ __html: text }} />
					</div>
				</DgTooltip>
			),
			sorter: false,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => <span className='break-all w-[15ch]'>{text}</span>,
			sorter: false,
		},
		{
			title: <Text tid='seen' />,
			key: 'is_read',
			dataIndex: 'is_read',
			render: (text: any) =>
				text === 0 ? (
					<DgTag color='var(--dg-red-color)'>unread</DgTag>
				) : (
					<DgTag color='var(--dg-green-color)' className='text-[var(--dg-primary-color-fixed)]'>
						read
					</DgTag>
				),
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp nowrap={false} timeStamp={text} />,
		},
		{
			title: '',
			render: (text: any, record: INotification) =>
				record?.is_read === 0 && (
					<DgTooltip title='Read'>
						<AiOutlineCheckCircle onClick={() => setSeenNotification({ ids: [record.id] })} size={25} color='var(--dg-green-color)' className='cursor-pointer' />
					</DgTooltip>
				),
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: INotification) => {
				return (
					<>
						<DgTableColumnResponsive title={t('title')} value={record?.title} />
						<DgTableColumnResponsive
							title={<Text tid='description' />}
							value={
								<div className='flex flex-col items-end'>
									<div className='line-clamp-4'>
										<div dangerouslySetInnerHTML={{ __html: record.body }} />
									</div>
									<DgTag
										onClick={() => {
											showModal();
											setDescription(record.body);
										}}
										color='var(--dg-orange-color)'
										className='text-[var(--dg-primary-color-fixed)] cursor-pointer inline-block'
									>
										<Text tid='more-description' />
									</DgTag>
								</div>
							}
						/>
						<DgTableColumnResponsive title={t('type')} value={<span className='break-all w-[10ch]'>{record?.type}</span>} />
						<DgTableColumnResponsive
							title={t('seen')}
							value={
								record?.is_read === 0 ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<Text tid='unread' />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='read' />
									</span>
								)
							}
						/>
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={+record?.created_at} />} />
						{record.is_read === 0 && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={
									<div className='flex justify-end'>
										<DgTooltip title='Read'>
											<AiOutlineCheckCircle onClick={() => setSeenNotification({ ids: [record.id] })} size={25} color='var(--dg-green-color)' className='cursor-pointer' />
										</DgTooltip>
									</div>
								}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 1200 ? Columns : RColumn;
};

export default ProfileNotificationsColumn;
