import Text from 'core/utils/Text';
import { BsArrowBarDown } from 'react-icons/bs';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import { ReactComponent as StepTwo } from 'assets/images/step-2.svg';
import { FaRegCopy } from 'react-icons/fa';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import DgPrice from 'styles/UI/Price/DgPrice';
import QRCode from 'react-qr-code';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import useDepositStepTwo from '../hooks/useDepositStepTwo';
import DgButton from 'styles/UI/Button/DgButton';
import { routes } from '../../../core/constants/routes';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import UserDepositWithdrawUpdateInvoice from './UserDepositWithdrawUpdateInvoice';

const UserDepositStepTwoForm = () => {
	const { copyHandler, depositDetail, invoice, isFetchingInvoice, navigate } = useDepositStepTwo();

	return (
		<DgSpinnerFull isLoading={isFetchingInvoice}>
			<span>
				<Text tid='deposit-title-2' /> <Text tid='deposit-wallet' className='text-[var(--dg-orange-color)] font-bold' />
			</span>

			<BsArrowBarDown size={50} className='mx-auto' />

			<div className='max-w-md mx-auto'>
				<div className='text-center my-7 flex flex-col gap-3'>
					<Text tid='wait-for-deposit' className='text-lg font-bold' />
					<Text tid='copy-address-or-scan-qr-code' />
				</div>

				<DgFormWrapper singleColumn>
					<div className='flex flex-col gap-1'>
						<Text tid='deposit-address' />

						<div className='bg-[var(--dg-input-bg)] p-3 border border-[var(--dg-border-color)] rounded-md flex items-center justify-between'>
							<span className='xs:max-w-full max-w-[30ch] break-all xs:break-before-auto'>{invoice?.data?.checkout_link}</span>
							<DgTooltip title='copy'>
								<FaRegCopy size={22} className='cursor-pointer' onClick={() => copyHandler(invoice?.data?.checkout_link + '')} />
							</DgTooltip>
						</div>
					</div>

					<div className='flex gap-5 mt-3'>
						<div className='p-2 bg-dg-white-color'>
							<QRCode size={130} value={invoice?.data?.checkout_link + ''} />
						</div>

						<div className='flex flex-col flex-1 justify-around'>
							<div className='flex items-center justify-between'>
								<Text tid='amount' />
								<DgPrice price={invoice?.data?.amount} />
							</div>

							<div className='flex items-center justify-between'>
								<Text tid='payment-amount' />
								<DgPrice price={depositDetail?.paid_amount || invoice?.data?.paid_amount} />
							</div>

							<div className='flex items-center justify-between'>
								<Text tid='currency' />
								<span>USDT</span>
							</div>
						</div>
					</div>

					<div className='flex justify-between items-center mt-3'>
						<Text tid='status' />
						
						{depositDetail?.status ? (
							depositDetail?.status === 'success' ? (
								<Text tid='success' className='bg-[var(--dg-green-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
							) : depositDetail?.status === 'pending' ? (
								<Text tid='pending' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md animate-bounce' />
							) : depositDetail?.status === 'confirming' ? (
								<Text tid='confirm' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md' />
							) : depositDetail?.status === 'error' ? (
								<Text tid='error' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
							) : depositDetail?.status === 'expired' ? (
								<Text tid='expired' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
							) : depositDetail?.status === 'partiallyPaid' ? (
								<Text tid='partially-paid' className='bg-[var(--dg-orange-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
							) : depositDetail?.status === 'refunded' ? (
								<Text tid='refunded' className='bg-[var(--dg-info-color)] py-2 px-5 rounded-md' />
							) : (
								''
							)
						) : invoice?.data?.status === 'success' ? (
							<Text tid='success' className='bg-[var(--dg-green-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'pending' ? (
							<Text tid='pending' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md animate-bounce' />
						) : invoice?.data?.status === 'confirming' ? (
							<Text tid='confirm' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'error' ? (
							<Text tid='error' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'expired' ? (
							<Text tid='expired' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'partiallyPaid' ? (
							<Text tid='partially-paid' className='bg-[var(--dg-orange-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'refunded' ? (
							<Text tid='refunded' className='bg-[var(--dg-info-color)] py-2 px-5 rounded-md' />
						) : (
							''
						)}
					</div>

					<div className='flex justify-between items-center mt-3'>
						<Text tid='expire' />
						<DgCountDown type='detailResult' timeStamp={invoice?.data?.expiration_time || 0} />
					</div>

					<UserDepositWithdrawUpdateInvoice timeStamp={invoice?.data?.expiration_time || 0} />

					{(depositDetail?.is_paid || invoice?.data?.is_paid) && <DgButton title='view-invoice-list' onClick={() => navigate(routes.WALLETS)} />}

					<StepTwo className='mx-auto mt-5' />
				</DgFormWrapper>
			</div>
		</DgSpinnerFull>
	);
};

export default UserDepositStepTwoForm;
