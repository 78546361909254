import useModal from 'core/hooks/useModal';
import { useDisableGoogle2FAQuery } from './react-query/useDisableGoogle2FAQuery';
import { Form } from 'antd';

const useGoogle2FA = () => {
	// hooks
	const { hideModal: hide2FAModal, showModal: show2FAModal, modal: modal2FA } = useModal();
	const [disableGoogle2FAForm] = Form.useForm();

	// query
	const { mutate: disableGoogle2FA, isLoading: isLoadingGoogle2FA } = useDisableGoogle2FAQuery();

	const onFinishGoogle2FA = (values: { one_time_password: string }) => {
		disableGoogle2FA(values, {
			onSuccess: () => {
				disableGoogle2FAForm.resetFields();
				hide2FAModal();
			},
		});
	};

	return { hide2FAModal, show2FAModal, modal2FA, onFinishGoogle2FA, isLoadingGoogle2FA, disableGoogle2FAForm };
};

export default useGoogle2FA;
