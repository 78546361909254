import { InputRef } from 'antd';
import { useEffect, useRef } from 'react';
import { HiArrowSmLeft } from 'react-icons/hi';
import DgButton from 'styles/UI/Button/DgButton';
import DgInput from 'styles/UI/Input/DgInput';
import useRegisterVerification from '../hooks/useRegisterVerification';
import style from './RegisterVerification.module.scss';
import DgModal from 'styles/UI/Modal/DgModal';
import Text from 'core/utils/Text';
import useModal from 'core/hooks/useModal';

const RegisterVerification = () => {
	const {
		code1,
		code2,
		code3,
		code4,
		code5,
		code6,
		t,
		focusHandler,
		onKeyDown,
		passChangeHandler,
		resend,
		resendHandler,
		seconds,
		verifyPass,
		query,
		goBackToLoginForm,
		isLoadingRegisterVerify,
		registerError,
	} = useRegisterVerification();

	const textInput = useRef<InputRef>(null);

	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled && textInput.current) {
			textInput.current.focus();
		}
		return () => {
			isCancelled = true;
		};
	}, [textInput]);

	const verifyHandler = (e: any) => {
		e.preventDefault();
		verifyPass();
	};

	const kycModal = useModal();

	useEffect(() => {
		if ((registerError as any)?.response?.status === 409) {
			kycModal.showModal();
		}
	}, [registerError]);

	return (
		<>
			<DgModal
				title='description'
				open={kycModal.modal}
				onCancel={kycModal.hideModal}
				footer={
					<div className='flex items-center gap-2 flex-wrap'>
						<a href='https://exfinito.com' target='_blank' rel='noreferrer'>
							<DgButton title='open-exchanger' />
						</a>
						<DgButton onClick={kycModal.hideModal} type='default' title='confirm' />
					</div>
				}
			>
				<Text tid='please-verify-user-account' />
			</DgModal>
			<form autoComplete='off'>
				<div className='flex gap-x-5 items-center mb-5'>
					<HiArrowSmLeft
						className='cursor-pointer'
						size={35}
						onClick={goBackToLoginForm}
					/>
				</div>
				<div className={style.mobile}>{`${t('enter-email-one-time-password')} ${
					query?.email || ''
				}`}</div>
				<div className={style.formContainer}>
					<div className='flex gap-x-2' dir='ltr'>
						<DgInput
							tabIndex={1}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code1')}
							onInput={focusHandler}
							value={code1}
							type='text'
							ref={textInput}
						/>
						<DgInput
							tabIndex={2}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code2')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code2}
							type='text'
						/>
						<DgInput
							tabIndex={3}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code3')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code3}
							type='text'
						/>
						<DgInput
							tabIndex={4}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code4')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code4}
							type='text'
						/>
						<DgInput
							tabIndex={5}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code5')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code5}
							type='text'
						/>
						<DgInput
							tabIndex={6}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code6')}
							onKeyDown={onKeyDown}
							value={code6}
							type='text'
						/>
					</div>
				</div>
				<div className={style.action}>
					<div className={style.timerContainer}>
						{resend ? (
							<button className={style.resendBtn} onClick={resendHandler}>
								{t('resend')}
							</button>
						) : (
							<span>{`${t('resend-in')} 00:${seconds}`}</span>
						)}
					</div>

					<DgButton
						isLoading={isLoadingRegisterVerify}
						htmlType='submit'
						onClick={verifyHandler}
						title='submit'
					/>
				</div>
			</form>
		</>
	);
};

export default RegisterVerification;
