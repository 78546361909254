import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { RootState } from 'core/store';
import { setAuthenticated, setHas2FA } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';

export const useAdd2FAOnTokenQuery = () => {
	const dispatch = useDispatch();
	const { token } = useSelector((store: RootState) => store.mainInfoStore);
	const clientQuery = useQueryClient();

	return useMutationHook(
		[endPointUrls.ADD2FA_ON_TOKEN],
		(data: any) =>
			api.post(endPointUrls.ADD2FA_ON_TOKEN, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					if (token) {
						dispatch(setAuthenticated(true));
						dispatch(setHas2FA(false));

						Cookie.set('jwt', token || '');
						clientQuery.invalidateQueries([endPointUrls.PROFILE_MANAGEMENT]);
						toastHandler('success', res.data.message);
					}
				}
			},
		}
	) as UseMutationResult;
};
