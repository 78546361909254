import { Button, ButtonProps } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { twMerge } from 'tailwind-merge';

interface IDgButton extends ButtonProps {
	customTitle?: ReactNode | string;
	minWidth?: string;
	height?: string;
	isLoading?: boolean;
	noLeftRound?: boolean;
	noRightRound?: boolean;
	responsive?: boolean;
	danger?: boolean;
}

const DgButton: FC<IDgButton> = ({
	disabled = false,
	className,
	isLoading = false,
	title = '',
	type = 'primary',
	customTitle = <></>,
	minWidth = '100px',
	htmlType = 'submit',
	noLeftRound = false,
	noRightRound = false,
	responsive = true,
	size,
	danger,
	height = 'h-[40px]',
	...props
}) => {
	const { t } = useTranslation();

	return (
		<Button
			disabled={disabled}
			type={type}
			htmlType={htmlType}
			className={twMerge(
				`text-center xs:px-4 px-3 ${danger ? 'btn-danger' : ''} shadow-md rounded-xl ${height} ${responsive ? 'xs:h-[40px] h-[30px]' : `${height}`} ${
					noLeftRound ? 'rounded-tl-none rounded-bl-none' : ''
				} ${noRightRound ? 'rounded-tr-none rounded-br-none' : ''} ${size === 'small' ? ' h-[30px]' : ''} ${className}`
			)}
			style={{ minWidth }}
			size='middle'
			{...props}
		>
			{isLoading ? <PulseLoader size={9} color='#191c20' /> : title ? t(title) : customTitle}
		</Button>
	);
};

export default DgButton;
