import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import { FC, useEffect } from 'react';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import useLogin from '../hooks/useLogin';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HiArrowSmLeft } from 'react-icons/hi';
import { setHas2FA } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useWindowSize } from 'core/hooks/useWindowSize';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';

const Login: FC = () => {
	const {
		onFinishLogin,
		loginForm,
		isLoadingLogin,
		onChangeCaptcha,
		isShowCaptcha,
		forgetPasswordForm,
		isLoadingAdd2FAOnToken,
		onFinishAdd2FAOnToken,
		loginData,
		loginError,
	} = useLogin();
	const { setUrlParam } = useMasterQueryUrlParam();
	const { has2FA } = useSelector((store: RootState) => store.mainInfoStore);
	const { t } = useTranslate();
	const { width } = useWindowSize();
	const dispatch = useDispatch();

	const kycModal = useModal();

	useEffect(() => {
		if ((loginError as any)?.response?.status === 409) {
			kycModal.showModal();
		}
	}, [loginError]);

	return (
		<>
			<DgModal
				title='description'
				open={kycModal.modal}
				onCancel={kycModal.hideModal}
				footer={
					<div className='flex items-center gap-2 flex-wrap'>
						<a href='https://exfinito.com' target='_blank' rel='noreferrer'>
							<DgButton title='open-exchanger' />
						</a>
						<DgButton onClick={kycModal.hideModal} type='default' title='confirm' />
					</div>
				}
			>
				<Text tid='please-verify-user-account' />
			</DgModal>

			{has2FA && (
				<div className='flex gap-x-5 items-center mb-5'>
					<HiArrowSmLeft
						className='cursor-pointer'
						size={35}
						onClick={() => dispatch(setHas2FA(false))}
					/>
				</div>
			)}
			<DgForm
				autoComplete='off'
				onFinish={has2FA ? onFinishAdd2FAOnToken : onFinishLogin}
				form={loginForm}
			>
				<DgFormWrapper singleColumn>
					<DgFormItem
						rules={[
							{
								required: true,
								message: t('email-is-required'),
							},
							{
								type: 'email',
								message: t('email-format-incorrect'),
							},
						]}
						name='email'
					>
						<DgInputLabel
							autoFocus={width > 600}
							readOnly={has2FA}
							autoComplete='new-password'
							label='email'
						/>
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('password-is-required'),
							},
						]}
						name='password'
					>
						<DgInputPasswordLabel
							readOnly={has2FA}
							autoComplete='new-password'
							label='password'
						/>
					</DgFormItem>

					{has2FA && (
						<DgFormItem
							rules={[
								{
									required: true,
									message: t('totp-verification-code-is-required'),
								},
							]}
							name='one_time_password'
						>
							<DgInputPasswordLabel autoFocus label='totp-verification-code' />
						</DgFormItem>
					)}
				</DgFormWrapper>

				<DgFormWrapper singleColumn>
					<div className='flex items-center justify-between'>
						{isShowCaptcha && (
							<DgFormItem>
								<ReCAPTCHA
									theme='dark'
									lang='persian'
									sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
									onChange={onChangeCaptcha}
								/>
							</DgFormItem>
						)}

						<span
							onClick={forgetPasswordForm}
							className='hover:border-b border-b border-b-transparent hover:border-b-[var(--dg-orange-color-hover)] hover:text-[var(--dg-orange-color-hover)] cursor-pointer'
						>
							<Text tid='forget-password' />
						</span>
					</div>
				</DgFormWrapper>

				<DgFormWrapper>
					<DgFormItem button>
						<div className='flex justify-between items-center gap-6 flex-wrap'>
							<div className='flex items-center gap-3'>
								<Text tid='not-have-account' />

								<span
									onClick={() => setUrlParam({ page: 2 })}
									className='hover:border-b text-base border-b border-b-transparent hover:border-b-[var(--dg-orange-color)] text-[var(--dg-orange-color)] cursor-pointer'
								>
									<Text tid='signup' />
								</span>
							</div>

							<DgButton
								htmlType='submit'
								isLoading={has2FA ? isLoadingAdd2FAOnToken : isLoadingLogin}
								title='login'
							/>
						</div>
					</DgFormItem>
				</DgFormWrapper>
			</DgForm>
		</>
	);
};

export default Login;
