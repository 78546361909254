import { getAllCountriesQuery } from 'core/hooks/react-query/getAllCountriesQuery';
import { setCountrySelectedData } from 'core/store/slice/country/countrySlice';
import { useDispatch } from 'react-redux';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectCountry = ({ ...props }) => {
	const dispatch = useDispatch();
	const { data, isFetching } = getAllCountriesQuery();

	const options = data?.data?.map((item) => ({ label: item.name, value: item.id, filter: item.name, allItems: { ...item } }));

	const customOnChange = (value: any, allData: any) => {
		dispatch(setCountrySelectedData(allData?.allItems));
	};

	return <DgSelectLabel label='user-role' options={options} loading={isFetching} customOnChange={customOnChange} {...props} />;
};

export default DgSelectCountry;
