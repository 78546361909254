import { Form } from 'antd';
import { RootState } from 'core/store';
import { blockUnBlockPayload } from 'core/types/models/userStatusType';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserBlockUnBlockQuery } from './react-query/useUserBlockUnBlockQuery';

const useUserBlockUnBlock = () => {
	// hooks
	const [blockUnBlockForm] = Form.useForm();
	const { userId } = useParams();

	// state
	const [block, setBlock] = useState(false);

	// query
	const { mutate: userBlockUnBlock, isLoading: blockUnBlockIsLoading } = useUserBlockUnBlockQuery();

	// store
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	useEffect(() => {
		blockUnBlockForm.setFieldsValue({ ...userDetail.data });
	}, [userDetail.data]);

	useEffect(() => {
		setBlock(!!userDetail.data.block_type);
	}, [userDetail.data]);

	const onChangeSwitchHandler = (e: boolean) => {
		setBlock(e);
	};

	const onFinishBlockUnBlockForm = (values: blockUnBlockPayload) => {
		userBlockUnBlock({ ...values, user_id: userId, block });
	};

	return { block, onChangeSwitchHandler, blockUnBlockForm, onFinishBlockUnBlockForm, blockUnBlockIsLoading };
};

export default useUserBlockUnBlock;
