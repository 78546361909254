import { Form, RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { ICreateUserPayload } from 'core/types/models/createUserType';
import { useAdminUserCreateQuery } from './react-query/useAdminUserCreateQuery';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { removeArrayElement } from 'core/utils/utils';

const useAdminUserCreate = (callback = () => {}) => {
	//hooks
	const dispatch = useDispatch();

	const [roles, setRoles] = useState<number[]>([]);
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const [createUserForm] = Form.useForm();
	const [genderType, setGenderType] = useState('Male');
	const {
		mutate: adminCreateUser,
		isLoading: isLoadingCreateUser,
		data: createdUser,
	} = useAdminUserCreateQuery(() => {
		// dispatch(setHiddenModal());
		callback();
	});

	const onChangeRoles = (roleId: number) => {
		setRoles((prevState: number[]) => [...prevState, roleId]);
	};

	const onDeselectRole = (roleId: number) => {
		// console.log(removeArrayElement(roles, roleId));
		setRoles([...removeArrayElement(roles, roleId)]);
	};

	const onFinishCreateUser = (values: ICreateUserPayload) => {
		adminCreateUser({ ...values, role_ids: roles });
	};

	const genderOnChangeHandler = (e: RadioChangeEvent): void => {
		setGenderType(e.target.value);
	};

	useEffect(() => {
		createUserForm.setFieldsValue({ role_ids: roles });
	}, [roles]);

	useEffect(() => {
		createUserForm.setFieldsValue({ gender: genderType });
	}, [genderType]);

	useEffect(() => {
		createUserForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	return {
		createUserForm,
		onFinishCreateUser,
		isLoadingCreateUser,
		genderOnChangeHandler,
		onChangeRoles,
		roles,
		onDeselectRole,
		createdUser,
	};
};

export default useAdminUserCreate;
