import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import { routes } from 'core/constants/routes';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useAdminAssignPackageQuery = (callBack?: () => void) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return useMutationHook(
		[endPointUrls.ADMIN_ORDER_CREATE],
		(data: any) => api.post(endPointUrls.ADMIN_ORDER_CREATE, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
					callBack?.();
					dispatch(setHiddenModal());

					// navigate(routes.ADMIN_USER_Detail_PAGE.link(response.data?.data?.id));
				}
			},
		}
	) as UseMutationResult;
};
