import DgCard from 'styles/UI/Card/DgCard';
import DepositSteps from './components/DepositSteps';
import useSelected from 'core/hooks/useSelected';
import UserDepositStepOneForm from './components/UserDepositStepOneForm';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import UserDepositStepTwoForm from './components/UserDepositStepTwoForm';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';

const UserDeposit = () => {
	const depositSteps = [
		{ id: 1, title: 'deposit-step-title-1', desc: 'deposit-step-desc-1' },
		{ id: 2, title: 'deposit-step-title-2', desc: 'deposit-step-desc-2' },
	];
	const { selectedType } = useSelected(1);
	const { query } = useMasterQueryUrlParam();

	return (
		<div className='grid grid-cols-8 gap-6'>
			<div className='sm:col-span-3 col-span-8'>
				<DgCard isPadding>
					<DgCardHeader hasBorder={false} tabTitle='deposit-wallet' />
					{depositSteps.map((item) => (
						<DepositSteps
							key={item.id}
							isSelected={selectedType.index === item.id}
							{...item}
						/>
					))}
				</DgCard>
			</div>

			<div className='sm:col-span-5 col-span-8'>
				<DgCard
					hasBackground={false}
					className='border-2 border-dashed border-[var(--dg-opposite-color)]'
				>
					{query?.tab !== 2 && <UserDepositStepOneForm />}

					{query?.tab === 2 && <UserDepositStepTwoForm />}
				</DgCard>
			</div>
		</div>
	);
};

export default UserDeposit;
