import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationCount } from 'core/store/slice/notification/notificationSlice';
import { INotificationUnreadDetail } from 'core/types/models/notificationType';
import { RootState } from 'core/store';

export const getNotificationUnreadCount = () => {
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	return useQueryHook(
		[endPointUrls.NOTIFICATIONS_UNREAD],
		(data: any) => api.get(endPointUrls.NOTIFICATIONS_UNREAD, data),
		{
			// select: (res: any) => res.data,
			onSuccess: (res: any) => {
				dispatch(setNotificationCount(res?.data?.unread_notifications));
			},
			enabled: isAuthenticated,
		},
		false
	) as UseQueryResult<INotificationUnreadDetail, Error>;
};
