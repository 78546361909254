import { useNotificationSeenQuery } from 'components/Profile/hooks/react-query/useNotificationSeenQuery';
import { RootState } from 'core/store';
import Text from 'core/utils/Text';
import { AiOutlineEye } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import DgEmpty from 'styles/UI/Empty/DgEmpty';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';

const NotificationWidget = () => {
	const { mutate: setSeenNotification, isLoading: seenNotificationIsLoading } = useNotificationSeenQuery();
	const { notificationList } = useSelector((store: RootState) => store.notificationStore);

	return (
		<div className='px-3 py-5 bg-[var(--dg-card-bg-1)] rounded-lg w-[300px] border border-[var(--dg-border-color)]'>
			<div className='pb-2 border-b flex items-center gap-3 justify-between'>
				<Text tid='messages' />
				<span onClick={() => setSeenNotification({ ids: [] })}>
					<Text tid='read-all' className='text-[var(--dg-orange-color)] cursor-pointer' />
				</span>
			</div>

			{notificationList.length === 0 && <DgEmpty />}

			<div className='max-h-[500px] overflow-y-auto'>
				<DgSpinnerFull isLoading={seenNotificationIsLoading}>
					{notificationList.map((notification, index) => (
						<div key={notification.id} className='border-b border-[var(--dg-border-color)] last:border-b-0 py-4 px-1 gap-5 flex items-center justify-between'>
							<span>{`${index + 1}. ${notification.title}`}</span>

							<div className='flex gap-2'>
								<DgTooltip title={<div dangerouslySetInnerHTML={{ __html: notification.body }} />}>
									<AiOutlineEye size={20} color='var(--dg-primary-color)' className='cursor-pointer' />
								</DgTooltip>

								<DgTooltip title='read'>
									<MdClose size={20} color='var(--dg-primary-color)' className='cursor-pointer' onClick={() => setSeenNotification({ ids: [notification.id] })} />
								</DgTooltip>
							</div>
						</div>
					))}
				</DgSpinnerFull>
			</div>
		</div>
	);
};

export default NotificationWidget;
