import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable, { IDgTable } from 'styles/UI/Table/DgTable';
import { useAdminUserInvoiceTableQuery } from '../hooks/react-query/useAdminUserInvoiceTableQuery';
import AdminUserInvoiceTableColumn from './AdminUserInvoiceTableColumn';
import { FC } from 'react';

interface IAdminUserInvoiceTable extends IDgTable {
	limit?: number;
}

const AdminUserInvoiceTable: FC<IAdminUserInvoiceTable> = () => {
	const { data: invoices, isFetching: isFetchingInvoices } = useAdminUserInvoiceTableQuery();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='transaction_id'
				columns={AdminUserInvoiceTableColumn()}
				dataSource={invoices?.data?.list}
				count={invoices?.data.pagination.total}
				loading={isFetchingInvoices}
				current={invoices?.data.pagination.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={invoices?.data.pagination.current_page}
				total={invoices?.data.pagination.total}
			/>
		</>
	);
};

export default AdminUserInvoiceTable;
