import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { filterOptionsType } from 'core/types/components/filterType';
import DgSelectBoolean from 'components/Basic/DgSelectBoolean';
import { stringToBoolean } from 'core/utils/utils';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { useWindowSize } from 'core/hooks/useWindowSize';

const AdminOrderFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();
	const { width } = useWindowSize();

	const filterOptions: filterOptionsType[] = [
		{
			filterTitle: 'payment-date',
			filterNames: ['is_paid_at_from', 'is_paid_at_to'],
			filterItems: [<DgDatePickerLabel key='date-from' label='date-from' />, <DgDatePickerLabel key='date-to' label='date-to' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterNames: ['email'],
			filterTitle: 'email',
			filterItems: [<DgInputLabel key='email' label='email' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterTitle: 'status',
			filterNames: ['is_canceled', 'is_expired'],
			filterItems: [<DgSelectBoolean key='is_canceled' label='is_canceled' />, <DgSelectBoolean key='is_expired' label='is_expired' />],
			formAttribute: Form.useForm(),
			filterResult: [
				{ title: 'is_canceled', value: stringToBoolean(query?.is_canceled) ? 'Yes' : 'No' },
				{ title: 'is_expired', value: stringToBoolean(query?.is_expired) ? 'Yes' : 'No' },
			],
			query,
		},
		{
			filterNames: ['full_name'],
			filterTitle: 'full_name',
			filterItems: [<DgInputLabel key='full_name' label='full_name' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterNames: ['package_name'],
			filterTitle: 'package_name',
			filterItems: [<DgInputLabel key='package_name' label='package_name' />],
			formAttribute: Form.useForm(),
			query,
		},
		...(width < 769
			? [
					{
						filterTitle: 'created_at',
						filterNames: ['created_at'],
						formAttribute: Form.useForm(),
						sortNames: ['created_at'],
						query,
					},
			  ]
			: [{ filterTitle: '', formAttribute: Form.useForm(), query, filterNames: [] }]),
		...(width < 769
			? [
					{
						filterTitle: 'packages_cost_in_pf',
						filterNames: ['packages_cost_in_pf'],
						formAttribute: Form.useForm(),
						sortNames: ['packages_cost_in_pf'],
						query,
					},
			  ]
			: [{ filterTitle: '', formAttribute: Form.useForm(), query, filterNames: [] }]),
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter width={width} filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default AdminOrderFilter;
