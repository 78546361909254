import { useWindowSize } from '../../../core/hooks/useWindowSize';
import useTranslate from '../../../core/utils/useTranslate';
import Text from '../../../core/utils/Text';
import DgCopyAddress from '../../../styles/UI/WalletAddress/DgCopyAddress';
import DgPrice from '../../../styles/UI/Price/DgPrice';
import DgTag from '../../../styles/UI/Tag/DgTag';
import DgTimeStamp from '../../../styles/UI/Timstamp/DgTimeStamp';
import { FiMoreVertical } from 'react-icons/fi';
import DgDropDown from '../../../styles/UI/DropDown/DgDropDown';
import { useAdminRegistersAction } from './useAdminRegistersAction';
import DgTableColumnResponsive from '../../../styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { ConfirmationPopover } from 'components/confirmation-popover';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import { useState } from 'react';
import { useAdminRegisters } from './useAdminRegisters';
import { useAdminDeactivatedUserQuery } from '../User/List/hooks/react-query/useAdminUserQuery';

const RegistersTableColumns = (type = 'admin') => {
	const { data: users, isFetching, refetch } = useAdminDeactivatedUserQuery();
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const { mutateAsync: registerAction } = useAdminRegisters();

	const { mutate: withdrawAction } = useAdminRegistersAction();

	const onAction = async (record, status) => {
		await registerAction({
			user_id: record?.id,
			status,
		});

		refetch();
	};

	const Columns = [
		{
			title: <Text tid='full-name' />,
			// key: 'ful-name',
			// dataIndex: 'ful-name',

			render: (text, record) => (
				<Link
					to={routes.ADMIN_USER_Detail.link(record?.id)}
					className='w-full flex items-center gap-3 group'
				>
					<Text
						tid={
							`${record?.full_name} (${record?.member_id})` ||
							record?.email ||
							record?.username
						}
					/>
				</Link>
			),
		},
		{
			title: <Text tid='email' />,
			// key: 'ful-name',
			// dataIndex: 'ful-name',

			render: (text, record) => (
				<Link
					to={routes.ADMIN_USER_Detail.link(record?.id)}
					className='w-full flex items-center gap-3 group'
				>
					<Text tid={record?.email || record?.username} />
				</Link>
			),
		},
		{
			title: <Text tid='status' />,
			render: (text, record) => {
				return <RecordStatus record={record} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	if (type === 'admin') {
		Columns.push({
			title: '',
			sorter: false,
			render: (text, record) => {
				return <Operation record={record} onAction={onAction} />;
			},
		});
	}

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (text, record) => {
				return (
					<>
						<DgTableColumnResponsive
							title={'wallet-hash'}
							value={
								<DgCopyAddress
									hasTitle={false}
									hasQrCode={false}
									address={record?.wallet_hash}
								/>
							}
						/>

						<DgTableColumnResponsive
							title={t('full-name')}
							value={
								<>
									<Link
										to={routes.ADMIN_USER_Detail.link(record.user?.id)}
										className='flex items-center gap-3 group'
									>
										<Text
											tid={
												`${record?.user_full_name} (${record?.user_member_id})` ||
												record.user?.email ||
												record.user?.username
											}
										/>
									</Link>
								</>
							}
						/>

						<DgTableColumnResponsive
							title={'amount'}
							value={<DgPrice price={record?.pf_amount} />}
						/>
						<DgTableColumnResponsive
							title={'fee'}
							value={<DgPrice price={record?.fee} />}
						/>
						<DgTableColumnResponsive
							title={'status'}
							value={<RecordStatus record={record} />}
						/>
						<DgTableColumnResponsive title={'type'} value={record?.type} />
						<DgTableColumnResponsive
							title={'date-time'}
							value={<DgTimeStamp timeStamp={record?.createdAt} />}
						/>
						{type === 'admin' && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={<Operation record={record} onAction={onAction} />}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

const RecordStatus = ({ record }) => {
	return (
		<>
			{!record?.is_deactivate ? (
				<DgTag
					color='var(--dg-green-color)'
					className='text-[var(--dg-primary-color-fixed)]'
				>
					<Text tid='active' />
				</DgTag>
			) : (
				<DgTag color='var(--dg-red-color-2)'>
					<Text tid='deactive' />
				</DgTag>
			)}
		</>
	);
};

const Operation = ({ record, onAction }) => {
	const [description, setDescription] = useState('');

	return (
		<DgDropDown
			trigger={['hover']}
			menu={{
				items: [
					{
						key: '1',
						label: (
							<ConfirmationPopover callback={() => onAction(record, 'activate')}>
								<div className='cursor-pointer py-2 px-5 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'>
									<Text tid={'active'} />
								</div>
							</ConfirmationPopover>
						),
					},
					{
						key: '2',
						label: (
							<ConfirmationPopover
								callback={() => onAction(record, 'deactivate')}
								// extra={
								// 	<>
								// 		<DgTextAreaLabel
								// 			value={description}
								// 			onChange={(e) => {
								// 				setDescription(e.target.value);
								// 			}}
								// 			label='description'
								// 		/>
								// 	</>
								// }
							>
								<div className='cursor-pointer py-2 px-5 w-full hover:bg-[var(--dg-card-bg-2)] bg-[var(--dg-card-bg-1)] border-b border-[var(--dg-border-color)] inline-block'>
									<Text tid={'deactive'} />
								</div>
							</ConfirmationPopover>
						),
					},
				],
			}}
			placement='bottomRight'
		>
			<FiMoreVertical className='cursor-pointer' size={25} />
		</DgDropDown>
	);
};

export default RegistersTableColumns;
