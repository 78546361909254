import { endPointUrls } from '../../../core/constants/endPointUrls';
import api from '../../../core/services/fetch-api/useApi';
import useMutationHook from '../../../core/hooks/masterQuery/useMutationHook';
import { useQueryClient } from '@tanstack/react-query';
import toastHandler from '../../../core/helpers/toast/toast';

export const useAdminRegistersAction = () => {
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.WITHDRAW_ACTION],
		(data) => api.patch(endPointUrls.WITHDRAW_ACTION, data),
		{
			onSuccess: (res) => {
				if (res?.status == 200) {
					toastHandler('success', res?.data.message);
					queryClient.invalidateQueries([endPointUrls.ADMIN_WITHDRAWS()]);
				}
			},
		}
	);
};
