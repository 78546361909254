import DgDeleteIcon from '../Icon/DgDeleteIcon';
import DgPopConfirm from '../PopConfirm/DgPopConfirm';
import DgTooltip from '../Tooltip/DgTooltip';
import { FC } from 'react';

interface IDgDeleteButton {
	onClick: () => void;
	onConfirm?: () => void;
	confirmText?: string;
	tooltip?: string;
}

const DgDeleteButton: FC<IDgDeleteButton> = ({ onClick, onConfirm, confirmText, tooltip }) => {
	return (
		<DgTooltip title={tooltip ? tooltip : 'delete'}>
			{onConfirm && (
				<DgPopConfirm onConfirm={onConfirm} title={confirmText ? confirmText : 'این مورد حذف شود؟'}>
					<DgDeleteIcon />
				</DgPopConfirm>
			)}

			{onClick && <DgDeleteIcon onClick={onClick} />}
		</DgTooltip>
	);
};

export default DgDeleteButton;
