import { getTransactionTypesQuery } from 'core/hooks/react-query/getTransactionTypesQuery';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectTransactionType = ({ ...props }) => {
	// react query
	const { data, isFetching } = getTransactionTypesQuery();

	const options = data?.data?.map((item) => ({ label: item.name, value: item.name, filter: item.name }));

	return <DgSelectLabel label='type' options={options} loading={isFetching} {...props} />;
};

export default DgSelectTransactionType;
