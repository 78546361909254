import { UseMutationResult, UseQueryResult, useMutation } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IAdminOrderList } from 'core/types/models/orderType';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import { AxiosResponse } from 'axios';

export const useAdminOrderQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.ADMIN_ORDERS(), filterParams],
		(data: any) => api.get(endPointUrls.ADMIN_ORDERS(filterParams), data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IAdminOrderList, Error>;
};

export const useExportAdminOrderQuery = () => {
	const { filterParams } = useQueryUrlParam();

	// return useMutationHook(
	// 	[endPointUrls.ADMIN_ORDERS_EXPORT(), filterParams],
	// 	(data: any) => api.get(endPointUrls.ADMIN_ORDERS_EXPORT(filterParams), data),
	// 	{
	// 		// select: (res: any) => res.data,
	// 		keepPreviousData: true,
	// 		refetchOnMount: false,
	// 	}
	// ) as UseQueryResult<any, Error>;

	return useMutationHook(
		[endPointUrls.ADMIN_ORDERS_EXPORT()],
		(data: any) => api.get(endPointUrls.ADMIN_ORDERS_EXPORT(), data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				// console.log({ response });

				if (response?.status === 200) {
					// toastHandler('success', response.data.message);
					// dispatch(setApiCatcherFormErrors([]));
					// queryClient.invalidateQueries([endPointUrls.PACKAGES_LIST]);
					// callBack?.();
				}
			},
		}
	) as UseMutationResult;
};
