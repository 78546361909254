export const handleDownloadExcel = (prefixTitle, data) => {
	// Replace 'data' with your binary or encoded data
	// const data = 'Hello, World!';

	// Create a Blob from the data
	const blob = new Blob([data], { type: 'application/octet-stream' });

	// Create a URL for the Blob
	const url = window.URL.createObjectURL(blob);

	// Create a temporary link element
	const a = document.createElement('a');
	a.href = url;
	a.download = prefixTitle + new Date().toISOString() + '.xlsx'; // Set the desired filename

	// Trigger a click event on the link to start the download
	a.click();

	// Clean up by revoking the URL
	window.URL.revokeObjectURL(url);
};
