import { useDispatch, useSelector } from 'react-redux';
import { useAdminSettingsQuery } from './react-query/useAdminSettingsQuery';
import { setModalData, setShowModal, setHiddenModal } from 'core/store/slice/modal/modalSlice';
import { ISetting } from '../../../../core/types/models/settingsType';
import { RootState } from 'core/store';

const userAdminSettings = () => {
	//hooks
	const dispatch = useDispatch();

	// query
	const { data: settings, isFetching: settingIsLoading } = useAdminSettingsQuery();

	// store
	const { modal } = useSelector((store: RootState) => store.modalStore);

	const onEditSettingsHandler = (settingData: ISetting) => {
		console.log(settingData,233232323);
		dispatch(setShowModal());
		dispatch(setModalData(settingData));
	};

	const onHiddenModalHandler = () => {
		dispatch(setHiddenModal());
	};

	return { settings, settingIsLoading, onEditSettingsHandler, onHiddenModalHandler, modal };
};

export default userAdminSettings;
