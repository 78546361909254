import { FormInstance } from 'antd';
import { RootState } from 'core/store';
import useTranslate from 'core/utils/useTranslate';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';

interface IChangePasswordForm {
	isUserPassword: boolean;
	onFinishTransactionPassword: (val: any) => void;
	transactionPasswordForm: FormInstance;
	transactionPasswordIsLoading: boolean;
	onFinishUserPassword: (val: any) => void;
	userPasswordForm: FormInstance;
	userPasswordIsLoading: boolean;
}

const ChangePasswordForm: FC<IChangePasswordForm> = ({
	isUserPassword,
	onFinishTransactionPassword,
	transactionPasswordForm,
	transactionPasswordIsLoading,
	onFinishUserPassword,
	userPasswordForm,
	userPasswordIsLoading,
}) => {
	const { currentUserInfo } = useSelector((store: RootState) => store.userInfoStore);
	const { t } = useTranslate();

	return (
		<DgForm form={isUserPassword ? userPasswordForm : transactionPasswordForm} onFinish={isUserPassword ? onFinishUserPassword : onFinishTransactionPassword}>
			<DgFormWrapper singleColumn>
				{!!currentUserInfo.data.google2fa_enable && (
					<DgFormItem
						name='one_time_password'
						rules={[
							{
								required: true,
								message: t('totp-verification-code-is-required'),
							},
						]}
					>
						<DgInputLabel label='totp-verification-code' required />
					</DgFormItem>
				)}

				<DgFormItem
					noMargin
					name='current_password'
					rules={[
						{
							required: true,
							message: t('current-password-is-required'),
						},
					]}
				>
					<DgInputPasswordLabel label='current-password' autoComplete='new-password' required />
				</DgFormItem>

				<DgFormItem
					name='password'
					rules={[
						{
							required: true,
							message: t(`${isUserPassword ? 'user' : 'transaction'}-password-is-required`),
						},
					]}
				>
					<DgInputPasswordLabel label={`new-${isUserPassword ? 'user' : 'transaction'}-password`} autoComplete='new-password' required />
				</DgFormItem>

				<DgFormItem
					name='password_confirmation'
					dependencies={['password']}
					rules={[
						{
							required: true,
							message: t(`confirm-${isUserPassword ? 'user' : 'transaction'}-password-is-required`),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error(t('password-not-same')));
							},
						}),
					]}
				>
					<DgInputPasswordLabel label={`confirm-${isUserPassword ? 'user' : 'transaction'}-password`} required />
				</DgFormItem>
			</DgFormWrapper>

			<DgFormItem button>
				<DgButton title='change-password' htmlType='submit' isLoading={isUserPassword ? userPasswordIsLoading : transactionPasswordIsLoading} />
			</DgFormItem>
		</DgForm>
	);
};

export default ChangePasswordForm;
