import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { userActivityList } from 'core/types/models/userActivityType';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useUsersActivityQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.ADMIN_USER_ACTIVITY_LIST(), filterParams],
		() => api.get(endPointUrls.ADMIN_USER_ACTIVITY_LIST(filterParams)),
		{
			// select: (res: any) => res.data,
		}
	) as UseQueryResult<userActivityList, Error>;
};
