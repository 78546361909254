import 'react-loading-skeleton/dist/skeleton.css';
import 'text-security/text-security.css';

import './assets/css/index.scss';

import './core/theme/ant-config.less';
import './core/theme/antdConfigure.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './core/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './config';
import { createRoot } from 'react-dom/client';

// ReactDOM.render(
// 	<Router>
// 		<App />
// 	</Router>,
// 	document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container as any); // createRoot(container!) if you use TypeScript
root.render(
	<Router>
		<App />
	</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
