import Text from 'core/utils/Text';
import { BsArrowBarUp } from 'react-icons/bs';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useSelected from 'core/hooks/useSelected';
import UserWithdrawNetworkItem from './UserWithdrawNetworkItem';
import DgButton from 'styles/UI/Button/DgButton';
import { FC } from 'react';
import DgSelectWithdrawCurrencies from './DgSelectWithdrawCurrencies';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../assets/images/usdt.png';
import useTranslate from 'core/utils/useTranslate';
import DgModal from 'styles/UI/Modal/DgModal';
import UserWithdrawInvoice from './UserWithdrawInvoice';
import useWithdrawStepOne from '../hooks/useWithdrawStepOne';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';

const UserWithdrawFormStepOne: FC = () => {
	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	const depositSteps = [
		{ id: 0, title: 'ERC20' },
		{ id: 1, title: 'TRC20' },
		{ id: 2, title: 'BEP20' },
	];
	const {
		modal,
		onWithdrawHandler,
		withdrawIsLoading,
		hideModal,
		amount,
		form,
		onChangeInputHandler,
		invoicePreviewDetails,
		onFinishWithdrawHandler,
	} = useWithdrawStepOne();
	const { t } = useTranslate();
	const { selectedType, selectedTypeHandler: selectedNetworkTypeHandler } = useSelected(
		1,
		false
	);

	return (
		<>
			<DgModal open={modal} closable={false} className='wallet-invoice' width={860}>
				<UserWithdrawInvoice
					onWithdrawHandler={onWithdrawHandler}
					withdrawIsLoading={withdrawIsLoading}
					invoicePreviewDetails={invoicePreviewDetails}
					onCancel={hideModal}
				/>
			</DgModal>

			<span>
				<Text tid='withdraw-wallet' className='text-[var(--dg-orange-color)] font-bold' />
			</span>

			<BsArrowBarUp size={50} className='mx-auto' />

			<div className='px-5 mx-auto'>
				<div className='text-center my-7 flex flex-col gap-3'>
					<Text tid='withdraw-title' className='text-lg font-bold' />
					<Text tid='withdraw-select-currencies-and-amount' />
				</div>

				<DgForm form={form} onFinish={onFinishWithdrawHandler}>
					<DgFormWrapper singleColumn>
						<DgFormItem>
							<DgSelectWithdrawCurrencies label='currency' />
						</DgFormItem>

						<DgFormItem
							rules={[{ required: true, message: t('amount-is-required') }]}
							name='amount'
						>
							<DgInputLabel
								value={amount}
								onChange={onChangeInputHandler}
								label='amount'
								suffix={
									<DgImage
										src={usdt}
										width={22}
										className='rounded-full relative top-1.5'
									/>
								}
							/>
						</DgFormItem>

						<DgFormItem
							rules={[{ required: true, message: t('address-is-required') }]}
							name='address'
						>
							<DgInputLabel label='address' />
						</DgFormItem>

						<div className='flex flex-col gap-1'>
							<Text tid='network' />

							<div className='grid grid-flow-col gap-3 overflow-x-auto'>
								{depositSteps.map((item) => (
									<UserWithdrawNetworkItem
										disabled={selectedType.index !== item.id}
										network={item.title}
										key={item.id}
										selected={selectedType.index === item.id}
										onClick={() =>
											selectedType.index !== item.id
												? null
												: selectedNetworkTypeHandler(item.id)
										}
									/>
								))}
							</div>
						</div>

						{!isFetching && !currentUserInfo?.data?.google2fa_enable ? (
							<Google2faAlert />
						) : (
							<>
								<DgFormItem
									name='one_time_password'
									rules={[
										{ required: true, message: t('totp-verification-code-is-required') },
									]}
								>
									<DgInputLabel label='totp-verification-code' required />
								</DgFormItem>

								<DgButton
									title='view-withdraw-invoice'
									htmlType='submit'
									className='mt-5 w-full'
								/>
							</>
						)}
					</DgFormWrapper>
				</DgForm>
			</div>
		</>
	);
};

export default UserWithdrawFormStepOne;
