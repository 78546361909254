import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getUserInfoQuery } from '../../../../../core/hooks/react-query/getUserInfoQuery';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgReferralCode from 'styles/UI/RefferalCode/DgReferralCode';

const SidebarUserInfo = () => {
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);
	const { isFetching } = getUserInfoQuery();

	return (
		<DgSpinnerFull isLoading={isFetching}>
			<div className='bg-[var(--dg-card-bg-1)] rounded-t-xl px-3 py-5 flex flex-col gap-y-2 text-center border-b-0 md:mb-0 mb-5 md:border-b border-[var(--dg-border-color)]'>
				<span>{userDetail?.data?.full_name}</span>
				<DgReferralCode hasHorizontal={false} member_id={userDetail?.data?.member_id} />
			</div>
		</DgSpinnerFull>
	);
};

export default SidebarUserInfo;
