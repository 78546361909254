import { Form } from 'antd';
import { useAdminAssignPackageQuery } from './useAssignPackage';
import DgSelectRole from 'components/Basic/DgSelectRole';
import useTranslate from 'core/utils/useTranslate';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { usePackageQuery } from '../Package/List/hooks/react-query/usePackageQuery';
import { PulseLoader } from 'react-spinners';
import DgDatePickerLabel from 'styles/UI/DatePicker/DgDatePickerLabel';
import { convertDatePicker } from 'core/utils/utils';

export const AdminUserAssignPackage = ({ user, callback = () => {} }) => {
	const [form] = Form.useForm();

	const { mutate, isLoading } = useAdminAssignPackageQuery(callback);
	const { data: packages, isFetching: packageIsFetching } = usePackageQuery();

	const { t } = useTranslate();

	// console.log({ user, packages });

	return (
		<>
			<DgCardHeader title='assign-package' loading={packageIsFetching || isLoading} />

			<DgForm
				autoComplete='off'
				onFinish={(values) => {
					if (values?.start_date) {
						values.start_date = convertDatePicker(values.start_date);
					}

					mutate({ user_id: user, ...values });

					// console.log({ user_id: user?.data?.data?.id, ...values });
				}}
				form={form}
			>
				<DgFormWrapper>
					{packages?.data?.length && (
						<DgFormItem name='package_id'>
							<DgSelectLabel
								options={packages.data.map((item) => ({
									label: item.name,
									value: item.id,
								}))}
								label='select-package'
							/>
						</DgFormItem>
					)}

					<DgFormItem name='start_date'>
						<DgDatePickerLabel key='date-from' label='date-from' />
					</DgFormItem>

					<DgFormItem
						name='price'
						rules={[
							{
								required: true,
								message: t('price-is-required'),
							},
						]}
					>
						<DgInputLabel label='price' />
					</DgFormItem>
				</DgFormWrapper>

				<DgFormItem button>
					<DgButton htmlType='submit' isLoading={isLoading} title='assign-package' />
				</DgFormItem>
			</DgForm>
		</>
	);
};
