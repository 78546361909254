import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';

const ToastProvider: FC = () => {
	const { theme, lang } = useSelector((store: RootState) => store.settingStore);

	return ReactDOM.createPortal(
		<ToastContainer rtl={lang === 'en' ? false : true} limit={3} theme={theme === 'light' ? 'light' : 'dark'} />,
		document.getElementById('toast') as HTMLElement
	);
};

export default ToastProvider;
