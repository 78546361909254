import { Form } from 'antd';
import useModal from 'core/hooks/useModal';
import { useRunAfterUpdate } from 'core/hooks/useRunAfterUpdate';
import { onInputValueChangeUtil } from 'core/utils/useInputValueChange';
import { extractNumberFromString } from 'core/utils/utils';
import { FormEvent, useEffect, useState } from 'react';
import { useWithdrawQuery } from './react-query/useWithdrawQuery';

const useWithdrawStepOne = () => {
	// hooks
	const { modal, showModal, hideModal } = useModal();
	const [form] = Form.useForm();
	const runAfterUpdate = useRunAfterUpdate();

	// state
	const [amount, setAmount] = useState<string>('');
	const [invoicePreviewDetails, setInvoicePreviewDetails] = useState<{
		currency: string;
		network: string;
		amount: string;
		address: string;
		one_time_password: string;
	} | null>(null);

	// query
	const { mutate: withdraw, isLoading: withdrawIsLoading } = useWithdrawQuery();

	const onChangeInputHandler = (e: FormEvent<HTMLInputElement>) => {
		setAmount(onInputValueChangeUtil(e, runAfterUpdate));
	};

	const onFinishWithdrawHandler = (values: {
		amount: string;
		address: string;
		one_time_password: string;
	}) => {
		setInvoicePreviewDetails({
			...values,
			amount: extractNumberFromString(values.amount),
			one_time_password: values.one_time_password,
			currency: 'USDT',
			network: 'TRC20',
		});
		showModal();
	};

	const onWithdrawHandler = () => {
		withdraw(
			{
				amount: invoicePreviewDetails?.amount,
				address: invoicePreviewDetails?.address,
				one_time_password: invoicePreviewDetails?.one_time_password,
			},
			{ onSuccess: () => hideModal() }
		);
	};

	useEffect(() => {
		form.setFieldsValue({ amount });
	}, [amount]);

	return {
		modal,
		showModal,
		hideModal,
		form,
		amount,
		onChangeInputHandler,
		onFinishWithdrawHandler,
		invoicePreviewDetails,
		onWithdrawHandler,
		withdrawIsLoading,
	};
};

export default useWithdrawStepOne;
