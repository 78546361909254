import useUserInfo from 'components/Admin/User/Detail/UserInfo/hooks/useUserInfo';
import { routes } from 'core/constants/routes';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { getExchangeKYCQuery } from 'core/hooks/react-query/kyc';
import Text from 'core/utils/Text';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import DgAlert from 'styles/UI/Alert/DgAlert';
import DgButton from 'styles/UI/Button/DgButton';

const KycAlert = () => {
	const { data: kycInfo, refetch: refetchKyc, isRefetching } = getExchangeKYCQuery();

	const { data: userInfo, isLoading: isLoadingGetUser } = getCurrentUserInfoQuery();

	return (
		<>
			{(userInfo?.data?.is_deactivate || !userInfo?.data) && (
				<>
					<DgAlert
						key={'kyc'}
						className='bg-[var(--dg-red-color-2)] border-0 lg-600:flex-row flex-col flex-wrap gap-12'
						message={
							<p className='flex gap-x-3 m-0 h-10 items-center'>
								<span>
									<AiFillCloseCircle size={20} />
								</span>
								<Text
									tid='please-verify-user-account'
									className='text-base md:whitespace-nowrap'
								/>
							</p>
						}
						action={
							<DgButton
								isLoading={isRefetching}
								title='review'
								onClick={() => refetchKyc()}
							/>
						}
					/>
				</>
			)}
		</>
	);
};

export default KycAlert;
