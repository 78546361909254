import { Form } from 'antd';
import { useEffect } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgFilter from 'styles/UI/Filter/DgFilter';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { filterOptionsType } from 'core/types/components/filterType';
import { useWindowSize } from 'core/hooks/useWindowSize';

const AdminUsersFilter = () => {
	const { query, resetQuery } = useQueryUrlParam();
	const { width } = useWindowSize();

	const filterOptions: filterOptionsType[] = [
		{
			filterNames: ['first_name'],
			filterTitle: 'first-name',
			filterItems: [<DgInputLabel key='first-name' label='first-name' />],
			formAttribute: Form.useForm(),
			query,
			sortNames: ['first_name'],
		},
		{
			filterNames: ['last_name'],
			filterTitle: 'last-name',
			filterItems: [<DgInputLabel key='last-name' label='last-name' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterNames: ['email'],
			filterTitle: 'email',
			filterItems: [<DgInputLabel key='email' label='email' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterNames: ['username'],
			filterTitle: 'username',
			filterItems: [<DgInputLabel key='username' label='username' autoComplete='new-password' />],
			formAttribute: Form.useForm(),
			query,
		},
		{
			filterNames: ['member_id'],
			filterTitle: 'member_id',
			filterItems: [<DgInputLabel key='member_id' label='member_id' />],
			formAttribute: Form.useForm(),
			query,
		},
	];

	useEffect(() => {
		filterOptions.map((item) => {
			const { formAttribute } = item;
			const [form] = formAttribute;

			form.setFieldsValue({ ...query });
		});
	}, []);

	return <DgFilter width={width} filterOptions={filterOptions} resetQuery={() => resetQuery(undefined, { tab: query?.tab }, filterOptions)} />;
};

export default AdminUsersFilter;
