import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { IWalletDepositResponse } from 'core/types/models/walletDepositType';
import { useDispatch } from 'react-redux';

export const useInternalTransferQuery = () => {
	const dispatch = useDispatch();
	const { setUrlParam } = useQueryUrlParam();

	return useMutationHook(
		[endPointUrls.INTERNAL_TRANSFER],
		(data: any) => api.post(endPointUrls.INTERNAL_TRANSFER, data),
		{
			onSuccess: (res) => {
				const response = res as IWalletDepositResponse;

				if (response?.status === 200) {
					// setUrlParam({ tab: 2, transaction_id: response?.data?.data?.transaction_id });
					toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<IWalletDepositResponse, Error>;
};
