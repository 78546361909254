import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { ICountryList } from 'core/types/models/countryType';

export const getAllCountriesQuery = () => {
	return useQueryHook([endPointUrls.COUNTRIES], () => api.get(endPointUrls.COUNTRIES), {
		// select: (res: any) => res.data,
		staleTime: 24 * 60 * 60 * 1000,
		cacheTime: 24 * 60 * 60 * 1000,
	}) as UseQueryResult<ICountryList, Error>;
};
