import { useInvoiceDetailQuery } from './react-query/useInvoiceDetailQuery';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearWithdrawDetail } from 'core/store/slice/withdraw/withdrawSlice';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { clearDepositDetail } from 'core/store/slice/deposit/depositSlice';

const useWithdrawStepTwo = () => {
	// query
	const { data: invoice, isFetching: isFetchingInvoice } = useInvoiceDetailQuery();

	// store
	const { withdrawDetail } = useSelector((store: RootState) => store.withdrawStore);

	// hooks
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearWithdrawDetail());
			dispatch(clearDepositDetail());
			queryClient.setQueryData([endPointUrls.PAYMENT_INVOICES()], { data: undefined });
		};
	}, []);

	return { invoice, isFetchingInvoice, withdrawDetail, navigate };
};

export default useWithdrawStepTwo;
