import { FC, useEffect, useState } from 'react';
import { CFlexStart, FlexCenter } from '../../../styles/CommonStyles';
import { QrBox } from '../../../styles/SecurityStyles';
import { FaRegCopy, FaStop } from 'react-icons/fa';
import Text from '../../../core/utils/Text';
import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import DgButton from 'styles/UI/Button/DgButton';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { IGoogle2faSecretResponse } from 'core/types/models/google2faType';
import DgImage from 'styles/UI/Image/DgImage';
import { convertSvgToUrl } from 'core/utils/utils';

interface IRestoreCode {
	onPrevStepHandler: () => void;
	onNextStepHandler: (val: 'valid') => void;
	setLoading: (val: boolean) => void;
	active2fa?: IGoogle2faSecretResponse;
}

const RestoreCode: FC<IRestoreCode> = ({ onPrevStepHandler, onNextStepHandler, setLoading, active2fa }) => {
	const [nextStep, setNextStep] = useState(true);
	const { copyHandler } = useCopyToClipboard();
	const { setUrlParam } = useQueryUrlParam();

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 300);
	}, []);

	useEffect(() => {
		if (!active2fa?.data?.data?.secret) setUrlParam({ active: 2 });
	}, []);

	const onCopyClicked = () => {
		copyHandler(active2fa?.data?.data?.secret || '');
		setNextStep(false);
	};

	return (
		<FlexCenter style={{ flexDirection: 'column', maxWidth: '700px', margin: '0 auto' }}>
			<h3>
				<Text tid='restore-title' />
			</h3>
			<DgImage preview width={200} className='mt-3' src={convertSvgToUrl(active2fa?.data?.data?.google2fa_url || '')} />

			{active2fa && (
				<QrBox>
					<FlexCenter>
						<div className='mt-1 text-base'>{active2fa?.data?.data?.secret}</div>
						<FaRegCopy onClick={() => onCopyClicked()} style={{ margin: '0 10px', cursor: 'pointer' }} size={28} />
					</FlexCenter>
				</QrBox>
			)}
			<CFlexStart>
				<FlexCenter className='flex-nowrap' style={{ margin: '25px 0' }}>
					<FaStop color='var(--dg-orange-color)' size={22} style={{ transform: 'rotate(45deg)', margin: '0 10px' }} />
					<div className='text-[var(--dg-orange-color)]'>
						<Text tid='restore-note-1' />
					</div>
				</FlexCenter>
				<FlexCenter className='flex-nowrap'>
					<FaStop color='var(--dg-orange-color)' size={22} style={{ transform: 'rotate(45deg)', margin: '0 10px' }} />
					<div className='text-[var(--dg-orange-color)]'>
						<Text tid='restore-note-2' />
					</div>
				</FlexCenter>
			</CFlexStart>

			<div className='flex justify-between w-full'>
				<DgButton type='default' className='mt-8' onClick={onPrevStepHandler} title='prev-step' />
				<DgButton disabled={nextStep} className='mt-8' onClick={() => onNextStepHandler('valid')} title='next-step' />
			</div>
		</FlexCenter>
	);
};

export default RestoreCode;
