import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminUserTransactionQuery } from '../hooks/react-query/useAdminUserTransactionQuery';
import AdminUserTransactionTableColumn from './AdminUserTransactionTableColumn';

const AdminUserTransactionTable = () => {
	const { data: transactions, isFetching: transactionIsFetching } = useAdminUserTransactionQuery();

	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={AdminUserTransactionTableColumn()}
				dataSource={transactions?.data?.list}
				count={transactions?.data.pagination.total}
				loading={transactionIsFetching}
				current={transactions?.data.pagination.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={transactions?.data.pagination.current_page}
				total={transactions?.data.pagination.total}
			/>
		</>
	);
};

export default AdminUserTransactionTable;
