import Text from 'core/utils/Text';
import DgTable from 'styles/UI/Table/DgTable';
import { getUserInfoQuery } from '../../../../../../core/hooks/react-query/getUserInfoQuery';

const UserRoleTable = () => {
	const { data: userInfo, isFetching: userInfoIsFetching } = getUserInfoQuery();

	const columns = [
		{
			title: <Text tid='name' />,
			key: 'name',
			dataIndex: 'name',
			render: (text: any) => text,
			sorter: false,
		},
	];

	return <DgTable rowKey='id' columns={columns} dataSource={userInfo?.data.roles} count={userInfo?.data.roles.length
    } loading={userInfoIsFetching} />;
};

export default UserRoleTable;
