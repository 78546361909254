import {
	QueryFunction,
	QueryKey,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query';
import useApiCatcher from '../useApiCatcher';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setAuthenticated, setIsHijack } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import { stringToBoolean } from 'core/utils/utils';
import toastHandler from 'core/helpers/toast/toast';
// import useTranslate from 'core/utils/useTranslate';

const useQueryHook = (
	queryKey: QueryKey,
	queryFn: QueryFunction,
	queryOptions: UseQueryOptions = {},
	showError = true
) => {
	//hooks
	// const { t } = useTranslate();

	const apiCatcher = useApiCatcher();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// console.log({ queryOptions });

	return useQuery({
		queryKey,
		queryFn,
		select: (r) => r.data,
		onError: (res: any) => {
			// queryOptions.onError?.call(this);
			if (
				res.response?.status === 401 &&
				!stringToBoolean(process.env.REACT_APP_LOGIN_LOCAL)
			) {
				Cookies.remove('jwt');
				Cookies.remove('refresh-jwt');
				dispatch(setAuthenticated(false));
				dispatch(setIsHijack(false));
				navigate(routes.REGISTER_SIGNING);
				document.title = process.env.REACT_APP_TAB_NAME || '';
			}

			if (res.response?.status !== 409) {
				showError &&
					apiCatcher(
						res?.response?.data?.errors ||
							res?.response?.errors || [res?.response?.message]
					);
			}
		},

		...queryOptions,

		// enabled: false,

		// keepPreviousData: false,
	});
};

export default useQueryHook;
