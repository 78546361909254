import { MenuProps } from 'antd';
import { FiSettings } from 'react-icons/fi';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import LanguageAndThemeDropDown from './components/LanguageAndThemeDropDown';
import { CLOSE_SIDEBAR_SIZE } from 'core/constants/common';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import useLanguageAndThemeWidget from './hooks/useLanguageAndThemeWidget';

const LanguageAndThemeWidget = () => {
	const { width } = useWindowSize();
	const {
		lang,
		languageOnChangeHandler,
		theme,
		languageAndThemeDropdown,
		onToggleLanguageAndThemeDropdown,
		themeOnChangeHandler,
	} = useLanguageAndThemeWidget();

	const notificationDropdown: MenuProps['items'] = [
		{
			label: (
				<LanguageAndThemeDropDown
					lang={lang}
					theme={theme}
					languageOnChangeHandler={languageOnChangeHandler}
					themeOnChangeHandler={themeOnChangeHandler}
				/>
			),
			key: '0',
		},
	];

	return (
		<DgDropDown
			menu={{ items: notificationDropdown }}
			placement='bottomLeft'
			open={languageAndThemeDropdown}
			onOpenChange={onToggleLanguageAndThemeDropdown}
		>
			<FiSettings
				size={width < CLOSE_SIDEBAR_SIZE ? 20 : 25}
				color='var(--dg-opposite-color)'
				className='cursor-pointer hover:text-dg-orange-color'
			/>
		</DgDropDown>
	);
};

export default LanguageAndThemeWidget;
