import { Link, useLocation } from 'react-router-dom';
import { CaretDownFilled } from '@ant-design/icons';
import { useEffect, FC, useState, ReactNode, memo } from 'react';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import useACL from 'core/services/role/useACL';
import { routes } from 'core/constants/routes';
import { RulesModal } from 'components/rules/rules-modal';
import useModal from 'core/hooks/useModal';
import clsx from 'clsx';
import { Button, Popover } from 'antd';
import { useSelector } from 'react-redux';
import { getMainTheme } from 'core/utils/theme';

interface subMenu {
	name: string;
	to: string;
	role: boolean;
	navKey: string;
}

interface IDgSidebarMenu {
	name: string;
	navKey?: string;
	openTab?: boolean;
	isOpen?: boolean;
	to?: string;
	type?: 'horizontal' | 'vertical';
	icon?: ReactNode;
	role?: boolean;
	subMenu?: subMenu[];
}

const DgSidebarMenu: FC<IDgSidebarMenu> = ({
	name,
	icon,
	to,
	subMenu,
	role,
	navKey,
	openTab,
	type = 'vertical',
}) => {
	const isVertical = type === 'vertical';
	const isHorizontal = type === 'horizontal';

	// console.log('aa', { isVertical, isHorizontal });

	//state
	const [isOpen, setIsOpen] = useState<boolean>(false);

	//hooks
	const { pathname } = useLocation();
	const { hasAccessTo } = useACL();
	const { t } = useTranslate();

	//effects
	useEffect(() => {
		if (!subMenu) return;
		setIsOpen(isAnySubMenusSelected());
	}, [pathname]);

	//functions
	const getLinkLength = (link?: string) => {
		if (!link) return 0;
		return link.split('/').length - 1;
	};

	const getImportantPartOfPathname = (customLink?: string) => {
		if (!to && !customLink && navKey) return;
		const result = pathname.split('/');
		result.shift();
		return result.slice(0, getLinkLength(navKey || customLink)).join('');
	};

	const isAnySubMenusSelected: () => boolean = () =>
		!!subMenu?.some((item) => {
			return item.to.replaceAll('/', '') === getImportantPartOfPathname(item.to);
		});

	const isSelected = (customLink?: string) => {
		if (customLink)
			return customLink.replaceAll('/', '') === getImportantPartOfPathname(customLink);
		if (!navKey) return false;
		return navKey.replaceAll('/', '') === getImportantPartOfPathname();
	};

	const subMenuItems = () => {
		return subMenu?.map((item, index) => {
			if (!hasAccessTo(item.role)) return <></>;

			return (
				<div
					className={clsx(
						'px-2 my-3 transition-all duration-300 ease-out group',
						isVertical && 'w-full m-0'
					)}
					key={index}
					onClick={(e) => e.stopPropagation()}
				>
					<Link to={item.to}>
						<div
							className={clsx(
								'flex items-center px-3 py-1.5 transition-all ease-out duration-300 relative rounded-md',
								isVertical && 'w-3/4 mx-auto',
								isSelected(item.to) ? 'text-[var(--dg-opposite-color)]' : ''
							)}
						>
							<div
								className={`w-2 ${
									isSelected(item.to)
										? 'dark:bg-[var(--dg-primary-color-hover)] bg-[var(--dg-opposite-color)] transition-all after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0 rtl:after:right-0'
										: ''
								}`}
							></div>
							{t(item.name)}
						</div>
					</Link>
				</div>
			);
		});
	};

	const subMenuIsEmpty = (items: subMenu[]) => {
		if (!items || items?.length === 0) return true;
		return items.every((item: subMenu) => !hasAccessTo(item.role));
	};

	const aboutUsModal = useModal();
	const rulesModal = useModal();

	//conditions
	if (!subMenu && !hasAccessTo(role)) return <></>;
	if (subMenu && subMenuIsEmpty(subMenu)) return <></>;

	if (to) {
		return (
			<>
				<RulesModal useModal={rulesModal} />

				{openTab ? (
					<a
						onClick={(e) => {
							if (to === routes.ABOUT) {
								e.preventDefault();
								aboutUsModal.showModal();
							}
							if (to === routes.TERMS) {
								e.preventDefault();
								rulesModal.showModal();
							}
						}}
						href={to}
						target='_blank'
						rel='noreferrer'
						className={`${
							isSelected()
								? 'bg-[var(--dg-primary-color-hover)] text-[var(--dg-orange-color)] after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0 rtl:after:right-0'
								: ''
						} w-full my-1 py-3 px-2 cursor-pointer transition-all duration-[0.3s] relative text-sm dark:hover:bg-[var(--dg-primary-color-hover)] hover:bg-[var(--dg-primary-color-light-hover-3)] flex items-center justify-center`}
					>
						<div className='w-full mx-auto px-3 flex items-center'>
							{icon}
							<div className='mx-3'>
								<Text className='whitespace-nowrap' tid={name} />
							</div>
						</div>
					</a>
				) : (
					<Link
						to={to}
						className={`${
							isSelected()
								? 'bg-[var(--dg-primary-color-light-hover-3)] dark:bg-[var(--dg-primary-color-hover)] after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0 rtl:after:right-0'
								: ''
						} w-full my-1 py-3 px-2 cursor-pointer transition-all duration-[0.3s] relative text-sm dark:hover:bg-[var(--dg-primary-color-hover)] hover:bg-[var(--dg-primary-color-light-hover-3)] flex items-center justify-center`}
					>
						<div className='w-full mx-auto px-3 flex items-center'>
							{icon}
							<div className='mx-3'>
								<Text className='whitespace-nowrap' tid={name} />
							</div>
						</div>
					</Link>
				)}
			</>
		);
	}
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	if (subMenu)
		return (
			<div
				key={type}
				className={`w-full my-1 py-3 cursor-pointer transition-all duration-[0.3s] relative text-sm 
				${!!isVertical && 'overflow-hidden'}
				${
					isOpen
						? 'dark:bg-[var(--dg-primary-color-hover)] bg-[var(--dg-primary-color-light-hover-3)]'
						: ''
				} `}
				style={{
					maxHeight: isVertical
						? isOpen
							? `${subMenu.length * 47 + 40}px`
							: '44px'
						: undefined,
				}}
				onClick={() => setIsOpen(!isOpen)}
			>
				<div
					className={`flex w-full px-5 items-center mb-2 transition-all ease-out duration-300  rounded-md group- ${
						isOpen && ' '
					} `}
				>
					<span style={{ color: 'white !important' }} className='w-[22px] h-[22px]'>
						{' '}
						{icon}
					</span>
					<span className='ml-3 rtl:mr-3 whitespace-nowrap'>{t(name)}</span>
					{subMenu && (
						<CaretDownFilled
							className='flex-1 ltr:text-right text-left transition-all'
							style={{ transform: isOpen ? 'scaleY(-1)' : '' }}
						/>
					)}

					{isHorizontal && (
						<Popover
							content={
								<div>
									<div
										className={clsx(
											'flex flex-col items-start justify-start max-h-[50vh] overflow-y-auto'
										)}
									>
										{subMenuItems()}
									</div>
								</div>
							}
							trigger='click'
							open={isOpen}
							placement='bottomRight'
							onOpenChange={setIsOpen}
							overlayClassName=' '
							showArrow={false}
							openClassName=''
							overlayInnerStyle={{ background: getMainTheme(theme, lang).primaryBg }}
						>
							{/* <Button type='primary'>Click me</Button> */}
						</Popover>
					)}
				</div>

				{isVertical && subMenuItems()}
			</div>
		);

	return <></>;
};

export default memo(DgSidebarMenu);
