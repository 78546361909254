import ProfileSidebar from 'components/Profile/components/ProfileSidebar';
import ProfileSidebarHeader from 'components/Profile/components/ProfileSidebarHeader';
import useProfile from 'components/Profile/hooks/useProfile';
import { routes } from 'core/constants/routes';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import useTranslate from 'core/utils/useTranslate';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import DgCard from 'styles/UI/Card/DgCard';
import DgSelect from 'styles/UI/Select/DgSelect';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';

interface IProfileLayout {
	children: ReactNode;
}

// sidebar links
const SIDEBAR_LINKS = [
	{
		title: 'personal',
		to: routes.PROFILE,
	},
	// {
	// 	title: 'new-member-position',
	// 	to: routes.PROFILE_MLM,
	// },
	{
		title: 'messages',
		to: routes.PROFILE_NOTIFICATIONS,
	},
	{
		title: 'activity',
		to: routes.PROFILE_ACTIVITY,
	},
	{
		title: 'security',
		to: routes.PROFILE_SECURITY,
	},
];

const ProfileLayout: FC<IProfileLayout> = ({ children }) => {
	const {
		userInfo: currentUserInfo,
		avatarRef,
		isLoadingUpdateAvatar,
		isLoadingGetUser,
		onUpdateAvatarClicked,
		getDropdownStatus,
	} = useProfile();
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { pathname } = useMasterQueryUrlParam();

	const options = SIDEBAR_LINKS.map((item) => ({
		label: t(item.title),
		value: item.to,
		filter: t(item.title),
	}));

	return (
		<>
			<div className='grid sm:grid-cols-2 grid-cols-1 gap-10 mb-8'>
				<div className='h-[200px] w-full border border-[var(--dg-orange-color)] rounded-lg flex items-center justify-center'>
					Banner 1
				</div>
				<div className='h-[200px] w-full border border-[var(--dg-orange-color)] rounded-lg flex items-center justify-center'>
					Banner 2
				</div>
			</div>

			<DgCard className='border border-[var(--dg-border-color)] md:hidden block'>
				<ProfileSidebarHeader
					avatarRef={avatarRef}
					currentUserInfo={currentUserInfo}
					getDropdownStatus={getDropdownStatus}
					isLoadingUpdateAvatar={isLoadingUpdateAvatar}
					isLoadingGetUser={isLoadingGetUser}
					onUpdateAvatarClicked={onUpdateAvatarClicked}
				/>

				<DgSelect
					value={pathname}
					options={options}
					className='w-full my-5 text-center'
					customOnChange={(key) => navigate(key)}
				/>
			</DgCard>
			<div className='border border-[var(--dg-border-color)] bg-[var(--dg-card-bg-1)] rounded-lg'>
				<div className='flex'>
					<div className='lg:p-10 p-5 flex-1'>
						<DgSpinnerFull isLoading={isLoadingGetUser}>{children}</DgSpinnerFull>
					</div>

					<ProfileSidebar />
				</div>
			</div>
		</>
	);
};

export default ProfileLayout;
