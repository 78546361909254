import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgSwitch from 'styles/UI/Switch/DgSwitch';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import useUserFreezeUnFreeze from '../hooks/useUserFreezeUnFreeze';

const UserFreezeUnFreezeStatus = () => {
	const { block, onConfirm, freezeUnFreezeIsLoading } = useUserFreezeUnFreeze();
	const { t } = useTranslate();

	return (
		<>
			<DgDevider orientation='center' title='freeze-user' />

			<DgShowInfo
				title={t('freeze-user-account')}
				value={
					<DgTooltip title={block ? 'unfreeze' : 'freeze '}>
						<DgPopConfirm onConfirm={onConfirm} title={<Text tid={block ? 'are-you-sure-to-unfreeze-user' : 'are-you-sure-to-freeze-user'} />}>
							<DgSwitch checked={block} loading={freezeUnFreezeIsLoading} />
						</DgPopConfirm>
					</DgTooltip>
				}
			/>
		</>
	);
};

export default UserFreezeUnFreezeStatus;
