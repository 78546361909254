import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import useTranslate from 'core/utils/useTranslate';
import { IInvoice } from 'core/types/models/invoiceType';
import DgTag from 'styles/UI/Tag/DgTag';
import DgCopyAddress from 'styles/UI/WalletAddress/DgCopyAddress';

const AdminUserInvoiceTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='transaction-id' />,
			key: 'transaction_id',
			dataIndex: 'transaction_id',
			render: (text: any) => {
				return <DgCopyAddress hasTitle={false} hasQrCode={false} address={text} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='wallet-address' />,
			key: 'checkout_link',
			dataIndex: 'checkout_link',
			render: (text: any) => {
				return <DgCopyAddress hasTitle={false} hasQrCode={false} address={text} />;
			},
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			dataIndex: 'amount',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='payment-amount' />,
			key: 'paid_amount',
			dataIndex: 'paid_amount',
			render: (text: any) => <DgPrice price={text} colored />,
			sorter: false,
		},
		{
			title: <Text tid='wage' />,
			render: (text: any, record: IInvoice) => <DgPrice price={record.amount - record.paid_amount} />,
			sorter: false,
		},
		{
			title: <Text tid='status' />,
			render: (text: any, record: IInvoice) => {
				return (
					<>
						{record?.status === 'success' && (
							<DgTag color='var(--dg-green-color)' className='text-[var(--dg-primary-color-fixed)]'>
								<Text tid='success' />
							</DgTag>
						)}
						{record?.status === 'pending' && (
							<DgTag color='var(--dg-input-bg)'>
								<Text tid='pending' />
							</DgTag>
						)}
						{record?.status === 'confirming' && (
							<DgTag color='var(--dg-input-bg)'>
								<Text tid='confirm' />
							</DgTag>
						)}
						{record?.status === 'error' && (
							<DgTag color='var(--dg-red-color-2)'>
								<Text tid='error' />
							</DgTag>
						)}
						{record?.status === 'expired' && (
							<DgTag color='var(--dg-red-color-2)'>
								<Text tid='expired' />
							</DgTag>
						)}
						{record?.status === 'partiallyPaid' && (
							<DgTag color='var(--dg-orange-color)' className='text-[var(--dg-primary-color-fixed)]'>
								<Text tid='partially-paid' />
							</DgTag>
						)}
						{record?.status === 'refunded' && (
							<DgTag color='var(--dg-info-color)'>
								<Text tid='refunded' />
							</DgTag>
						)}
					</>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='transaction-counts' />,
			render: (_text: any, record: IInvoice) => record.transactions?.length || 0,
			sorter: false,
		},
		{
			title: <Text tid='payment_type' />,
			key: 'payment_type',
			dataIndex: 'payment_type',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IInvoice) => {
				return (
					<>
						<DgTableColumnResponsive title={t('transaction-id')} value={<DgCopyAddress hasTitle={false} hasQrCode={false} address={record.transaction_id} />} />
						<DgTableColumnResponsive title={t('wallet-address')} value={<DgCopyAddress hasQrCode={false} hasTitle={false} address={record.checkout_link} />} />
						<DgTableColumnResponsive title={t('amount')} value={<DgPrice price={record.amount} />} />
						<DgTableColumnResponsive title={t('payment-amount')} value={<DgPrice price={record.paid_amount} colored />} />
						<DgTableColumnResponsive title={t('wage')} value={<DgPrice price={record.amount - record.paid_amount} />} />
						<DgTableColumnResponsive
							title={t('status')}
							value={
								<>
									{record?.status === 'success' && (
										<DgTag color='var(--dg-green-color)' className='text-[var(--dg-primary-color-fixed)]'>
											<Text tid='success' />
										</DgTag>
									)}
									{record?.status === 'pending' && (
										<DgTag color='var(--dg-input-bg)'>
											<Text tid='pending' />
										</DgTag>
									)}
									{record?.status === 'confirming' && (
										<DgTag color='var(--dg-input-bg)'>
											<Text tid='confirm' />
										</DgTag>
									)}
									{record?.status === 'error' && (
										<DgTag color='var(--dg-red-color-2)'>
											<Text tid='error' />
										</DgTag>
									)}
									{record?.status === 'expired' && (
										<DgTag color='var(--dg-red-color-2)'>
											<Text tid='expired' />
										</DgTag>
									)}
									{record?.status === 'partiallyPaid' && (
										<DgTag color='var(--dg-orange-color)' className='text-[var(--dg-primary-color-fixed)]'>
											<Text tid='partially-paid' />
										</DgTag>
									)}
									{record?.status === 'refunded' && (
										<DgTag color='var(--dg-info-color)'>
											<Text tid='refunded' />
										</DgTag>
									)}
								</>
							}
						/>
						<DgTableColumnResponsive title={t('transaction-counts')} value={record.transactions?.length || 0} />
						<DgTableColumnResponsive title={t('payment_type')} value={record.payment_type || ''} />
						<DgTableColumnResponsive title={t('date-time')} value={<DgTimeStamp nowrap={false} timeStamp={record.created_at} />} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminUserInvoiceTableColumn;
