import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IInvoiceDetail } from 'core/types/models/invoiceType';

export const useInvoiceDetailQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.PAYMENT_INVOICES(), filterParams],
		(data: any) => api.get(endPointUrls.PAYMENT_INVOICES(filterParams), data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IInvoiceDetail, Error>;
};
