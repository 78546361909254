import useQueryUrlParam from '../../../core/hooks/useQueryUrlParam';
import { useAdminRegisters } from './useAdminRegisters';
import DgTable from '../../../styles/UI/Table/DgTable';
import RegistersTableColumns from './RegistersTableColumns';
import DgPagination from '../../../styles/UI/Pagination/DgPagination';
import {
	useAdminDeactivatedUserQuery,
	useAdminUserQuery,
} from '../User/List/hooks/react-query/useAdminUserQuery';

const RegistersTable = () => {
	const { data: users, isFetching } = useAdminDeactivatedUserQuery();

	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='transaction_id'
				columns={RegistersTableColumns()}
				dataSource={users?.data?.list}
				count={users?.data.pagination.total}
				loading={isFetching}
				current={users?.data.pagination.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={users?.data.pagination.current_page}
				total={users?.data.pagination.total}
			/>
		</>
	);
};

export default RegistersTable;
