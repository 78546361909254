import { FC, useMemo } from 'react';
import Text from 'core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import { IOrder } from 'core/types/models/orderType';
import DgTag from 'styles/UI/Tag/DgTag';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import { TbRefreshAlert } from 'react-icons/tb';
import { MdOutlineMonetizationOn } from 'react-icons/md';
import { IoHourglassOutline } from 'react-icons/io5';
import { BsCalendar2Check } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/store';

interface IUserOrderItem {
	item: IOrder;
	dashboard?: boolean;
	setOrderId: (val: any) => void;
	showModal: () => void;
	topUpCallback: () => void;
	setModalData: any;
}

const UserOrderItem: FC<IUserOrderItem> = ({
	item,
	dashboard = false,
	setOrderId,
	showModal,
	topUpCallback = () => {},
	setModalData
}) => {
	const { t } = useTranslation();
	const {
		package: {
			direct_percentage,
			name,
			category: { package_validity_in_days },
		},
		expires_at,
		created_at,
		total_cost_in_pf,
		id,
	} = item;

	// hooks
	const navigate = useNavigate();

	// useEffect(() => {
	// 	convertTimeStampToCountDown(expires_at, created_at, (val, prc) => setPercentage(prc || 0));
	// }, []);

	const validity_in_year = Math.floor(package_validity_in_days / 365);
	const validity_in_month =
		validity_in_year < 1
			? parseInt(
					parseFloat((package_validity_in_days / 365).toString())
						.toString()
						.match(/0\.(\d)/)?.[1] || ''
			  ) + 1
			: '';

	const { lang } = useSelector((store: RootState) => store.settingStore);

	const duration = useMemo(() => {
		const now = new Date();
		const expire = new Date(expires_at * 1000);
		if (expire < now) return 'expired';

		const yearsDiff = expire.getFullYear() - now.getFullYear();
		const monthsDiff = expire.getMonth() - now.getMonth();
		const daysDiff = expire.getDate() - now.getDate();

		let months = yearsDiff * 12 + monthsDiff;
		let days = daysDiff;

		if (days < 0) {
			months--;
			const lastMonthDate = new Date(
				expire.getFullYear(),
				expire.getMonth(),
				0
			).getDate();
			days += lastMonthDate;
		}

		let res = '';

		if (lang === 'en') {
			if (months > 0) res += `${months} month${months !== 1 ? 's' : ''}`;
			if (days > 0) res += ` ${days} day${days !== 1 ? 's' : ''}`;
		} else {
			if (months > 0) res += `${months} ماه`;
			if (days > 0) res += ` ${days} روز`;
		}

		return res.trim() || 'Expires today';
	}, [expires_at]);

	return (
		<div
			className={`${
				dashboard ? 'border border-[var(--dg-orange-color)]' : ''
			} items-center relative flex flex-col p-3 rounded-lg hover:shadow-xl dark:bg-dg-card-bg-3 bg-dg-card-bg-1 border shadow-md`}
		>
			{dashboard ? (
				<DgTag
					color='var(--dg-orange-color)'
					className='absolute z-50 top-5 right-5 text-[var(--dg-primary-color-fixed)]'
				>
					highest
				</DgTag>
			) : null}

			{/* <DgProgress strokeLinecap='butt' type='dashboard' percent={Number.parseInt(percentage + '')} /> */}

			<h1 className='text-dg-white-color text-2xl my-10'>{name}</h1>

			<div className='grid gap-5 w-full'>
				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
					<span className='flex items-center gap-1'>
						<MdOutlineMonetizationOn size={20} />
						<Text
							className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
							tid='investment-amount'
						/>
					</span>
					<span className='font-bold text-base'>
						<DgPrice price={total_cost_in_pf} />
					</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
					<span className='flex items-center gap-1'>
						<IoHourglassOutline size={20} />
						<Text
							className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
							tid='duration'
						/>
					</span>
					<span className='font-bold text-base'>
						{validity_in_year
							? `${validity_in_year} ${t(validity_in_year === 1 ? 'year' : 'years')}`
							: `${validity_in_month} ${t(validity_in_month === 1 ? 'month' : 'months')}`}
					</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
					<span className='flex items-center gap-1'>
						<BsCalendar2Check size={20} />
						<Text
							className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
							tid='invest-date'
						/>
					</span>
					<DgTimeStamp
						hasTime={false}
						timeStamp={created_at}
						nowrap
						className='font-bold text-base'
					/>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
					<span className='flex items-center gap-1'>
						<MdOutlineMonetizationOn size={20} />
						<Text
							className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
							tid='invest-profit'
							hintTitle='invest-profit'
							hintDescription='roi_percentage_info'
						/>
					</span>
					<span className='font-bold text-base'>{direct_percentage}%</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] xs:mx-4 mx-3 pb-5'>
					<span className='flex items-center gap-1'>
						<TbRefreshAlert size={20} />
						<Text
							className='font-bold text-base text-[var(--dg-primary-color-fixed)] dark:text-[var(--dg-primary-color-light-hover-2)]'
							tid='expire-date'
						/>
					</span>
					<span>{duration}</span>
					{/*<DgCountDown timeStamp={expires_at} />*/}
				</div>

				{dashboard ? null : (
					<div className='flex justify-between items-center flex-wrap gap-3 my-5'>
						<DgButton
							onClick={() => navigate(routes.PLANS_COMMISSIONS(`order_id=${id}`))}
							title='profits'
							className='grow'
						/>

						<DgButton
							onClick={topUpCallback}
							title='topup'
							type='default'
							className='grow'
						/>

						<DgButton
							title='cancel-investment'
							danger
							onClick={() => {
								setModalData(item);
								showModal();
								setOrderId(id);
							}}
							className='grow'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserOrderItem;
