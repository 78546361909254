import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserTransferTable from './components/UserTransferTable';
import UserTransferFilter from './components/UserTransferFilter';
import DgButton from '../../../styles/UI/Button/DgButton';
import useModal from '../../../core/hooks/useModal';
import DgModal from '../../../styles/UI/Modal/DgModal';
import DgForm from '../../../styles/UI/Form/DgForm';
import DgFormWrapper from '../../../styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from '../../../styles/UI/FormItem/DgFormItem';
import DgInputLabel from '../../../styles/UI/Input/DgInputLabel';
import DgInputPasswordLabel from '../../../styles/UI/Input/DgInputPasswordLabel';
import {useTranslation} from 'react-i18next';
import useWalletDepositTransfer from '../../UserWallets/hook/useWalletDepositTransfer';
import UserDepositWalletTransferPreview
	from '../../UserEarningWallet/components/Transfer/UserDepositWalletTranferPreview';

const UserTransfer = () => {

	const { t } = useTranslation();
	const { modal, showModal, hideModal } = useModal();
	const {
		hideModal: hideTransferModal,
		modal: transferModal,
		onFinishWalletDepositTransfer,
		google2faForm,
		google2faModal,
		google2faHideModal,
		onFinishGoogle2fa,
		walletDepositTransferFinalIsLoading,
		walletDepositTransferForm,
		walletDepositTransferPreviewIsLoading,
		walletDepositTransferPreviewResponse,
		google2faShowModal,
	} = useWalletDepositTransfer();

	return (
		<DgCard>
			<DgModal
				open={modal}
				onCancel={hideModal}
				title={'inter-user-transfer'}
			>
				<DgForm
					form={walletDepositTransferForm}
					onFinish={onFinishWalletDepositTransfer}
					autoComplete='off'
				>
					<DgFormWrapper className='lg-1200:grid-cols-2 md:grid-cols-1'>
						<DgFormItem
							name='member_id'
							rules={[
								{
									required: true,
									message: t('referral-code-is-required'),
								},
							]}
						>
							<DgInputLabel label='referral-code' required />
						</DgFormItem>

						<DgFormItem
							name='amount'
							rules={[
								{
									required: true,
									message: t('amount-is-required'),
								},
							]}
						>
							<DgInputLabel label='amount' required />
						</DgFormItem>

						<DgFormItem
							full
							name='transaction_password'
							rules={[
								{
									required: true,
									message: t('transaction-password-is-required'),
								},
							]}
						>
							<DgInputPasswordLabel
								label='transaction-password'
								autoComplete='new-password'
								required
							/>
						</DgFormItem>

						<DgFormItem button>
							<DgButton
								title='preview-transfer'
								htmlType='submit'
								isLoading={walletDepositTransferPreviewIsLoading}
							/>
						</DgFormItem>
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<DgModal
				title='totp-verification-code'
				open={google2faModal}
				onCancel={google2faHideModal}
			>
				<DgForm form={google2faForm} onFinish={onFinishGoogle2fa}>
					<DgFormWrapper singleColumn>
						<DgFormItem
							name='one_time_password'
							rules={[
								{ required: true, message: t('totp-verification-code-is-required') },
							]}
						>
							<DgInputLabel label='totp-verification-code' required />
						</DgFormItem>
						<DgButton title='submit' isLoading={walletDepositTransferFinalIsLoading} />
					</DgFormWrapper>
				</DgForm>
			</DgModal>

			<DgModal open={transferModal} title='inter-user-transfer-preview' closable={false}>
				{walletDepositTransferPreviewResponse && (
					<UserDepositWalletTransferPreview
						google2faShowModal={google2faShowModal}
						onCancel={hideTransferModal}
						response={walletDepositTransferPreviewResponse}
					/>
				)}
			</DgModal>

			<DgCardHeader
				customButton={
					<DgButton
						title={'inter-user-transfer'}
						onClick={showModal}
					/>
				}
				title='inter-user-transfer'
			/>

			<UserTransferFilter />
			<UserTransferTable />
		</DgCard>
	);
};

export default UserTransfer;
