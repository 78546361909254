import useProfileSecurity from 'components/Profile/hooks/useProfileSecurity';
import Text from 'core/utils/Text';
import { FC } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgModal from 'styles/UI/Modal/DgModal';
import ChangePasswordForm from './ChangePasswordForm';

const TransactionPassword: FC = () => {
	const {
		hideTransactionPasswordModal,
		modalTransactionPassword,
		showTransactionPasswordModal,
		onFinishTransactionPassword,
		transactionPasswordForm,
		transactionPasswordIsLoading,
		onFinishUserPassword,
		userPasswordForm,
		userPasswordIsLoading,
	} = useProfileSecurity();

	return (
		<>
			<DgModal
				title='transaction-password'
				open={modalTransactionPassword}
				onCancel={hideTransactionPasswordModal}
			>
				<ChangePasswordForm
					onFinishTransactionPassword={onFinishTransactionPassword}
					transactionPasswordForm={transactionPasswordForm}
					transactionPasswordIsLoading={transactionPasswordIsLoading}
					onFinishUserPassword={onFinishUserPassword}
					userPasswordForm={userPasswordForm}
					userPasswordIsLoading={userPasswordIsLoading}
					isUserPassword={false}
				/>
			</DgModal>
			<div className='border-b border-b-[var(--dg-border-color)] pb-3'>
				<div className='flex justify-between items-center flex-wrap'>
					<Text tid='transaction-password' />

					<DgButton
						title='set-transaction-password'
						onClick={showTransactionPasswordModal}
					/>
				</div>
			</div>
		</>
	);
};

export default TransactionPassword;
