import { RootState } from 'core/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserVerifyEmailQuery } from './react-query/useUserVerifyEmailQuery';

const useUserVerifyEmail = () => {
	// hooks
	const { userId } = useParams();

	// state
	const [verifyEmail, setVerifyEmail] = useState(false);

	// query
	const { mutate: userEmailVerify, isLoading: verifyEmailIsLoading } = useUserVerifyEmailQuery();

	// store
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	useEffect(() => {
		setVerifyEmail(!!userDetail.data.email_verified_at);
	}, [userDetail.data]);

	const onConfirm = async () => {
		await userEmailVerify({ user_id: userId });
		setVerifyEmail(!verifyEmail);
	};

	return { verifyEmail, onConfirm, verifyEmailIsLoading };
};

export default useUserVerifyEmail;
