import { FC, FormEvent, useEffect, useMemo } from 'react';
import Text from 'core/utils/Text';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import { useOrderSubmitPreviewQuery } from '../hooks/react-query/useOrderSubmitPreviewQuery';
import { useOrderSubmitQuery } from '../hooks/react-query/useOrderSubmitQuery';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgSelectPackageCategory from 'components/Basic/DgSelectPackageCategory';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgImage from 'styles/UI/Image/DgImage';
import useTranslate from 'core/utils/useTranslate';
import usdt from '../../../assets/images/usdt.png';
import DgButton from 'styles/UI/Button/DgButton';

const OrderPayment: FC<any> = ({
	form,
	customPrice,
	onValuesChange = () => {},
	setCustomPrice = () => {},
}) => {
	const { query, filterParams } = useQueryUrlParam();

	const { t } = useTranslate();

	const {
		mutate: submitOrderPreview,
		isLoading: isLoadingSubmitOrderPreview,
		data: orderPreviewData,
	} = useOrderSubmitPreviewQuery();

	const { isLoading: isLoadingSubmitOrder } = useOrderSubmitQuery();

	useEffect(() => {
		if (query?.package_id) {
			submitOrderPreview({
				package_id: query?.package_id,
				one_time_password: '1',
				price: customPrice || undefined,
			});
		}
	}, [filterParams, customPrice]);

	const isBronze =
		(orderPreviewData?.data?.data?.package?.name
			?.toLowerCase()
			?.indexOf('bronze') as any) >= 0;

	const isSilver =
		(orderPreviewData?.data?.data?.package?.name
			?.toLowerCase()
			?.indexOf('silver') as any) >= 0;

	const isGold =
		(orderPreviewData?.data?.data?.package?.name
			?.toLowerCase()
			?.indexOf('gold') as any) >= 0;

	const rules = useMemo(() => {
		const rules = [];

		if (isBronze) {
			rules.push({
				message: t('amount-should-be-between-100-1000'),
				validator: (_, value) => {
					if (value >= 100 && value <= 1000) {
						return Promise.resolve();
					} else {
						return Promise.reject('amount-should-be-between-100-1000');
					}
				},
			});
		} else if (isSilver) {
			rules.push({
				message: t('amount-should-be-minimum-1000'),
				validator: (_, value) => {
					if (value >= 1000) {
						return Promise.resolve();
					} else {
						return Promise.reject('amount-should-be-minimum-1000');
					}
				},
			});
		} else if (isGold) {
			rules.push({
				message: t('amount-should-be-minimum-100000'),
				validator: (_, value) => {
					if (value >= 100000) {
						return Promise.resolve();
					} else {
						return Promise.reject('amount-should-be-minimum-100000');
					}
				},
			});
		}

		return rules;
	}, [isSilver, isBronze, isGold]);

	useEffect(() => {
		form.setFieldsValue({ price: orderPreviewData?.data?.data?.package_cost_in_pf });
		// setCustomPrice(orderPreviewData?.data?.data?.package_cost_in_pf);
	}, [orderPreviewData?.data?.data?.package_cost_in_pf]);

	return (
		<DgSpinnerFull isLoading={isLoadingSubmitOrderPreview || isLoadingSubmitOrder}>
			<div className='max-w-2xl mx-auto grid sm:gap-5 gap-3 w-full sm:px-10 px-5 py-5 rounded-lg border border-[var(--dg-border-color)]'>
				<DgForm
					form={form}
					onFinish={(values) => {
						// console.log({ values }, form.getFieldError('price'));

						setCustomPrice(form.getFieldsValue(true).amount);
					}}
				>
					<div className='flex items-start justify-between gap-4'>
						<DgFormItem className='w-full' rules={rules} noMargin name='price'>
							<DgInputLabel
								suffix={
									<DgImage
										src={usdt}
										width={22}
										className='rounded-full relative top-1.5'
									/>
								}
								onChange={(e: FormEvent<HTMLInputElement>) =>
									form.setFieldsValue({ amount: e.currentTarget.value })
								}
								// defaultValue={orderPreviewData?.data?.data?.package_cost_in_pf}
								value={form.getFieldsValue(true).amount}
								label='amount'
							/>
						</DgFormItem>

						{/*<DgButton*/}
						{/*	disabled={form.getFieldError('price')?.length}*/}
						{/*	className='!min-w-[auto]'*/}
						{/*	title='change-price'*/}
						{/*	type='primary'*/}
						{/*/>*/}
					</div>
				</DgForm>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='package-name' />
					<span className='font-bold sm:text-xl text-base'>
						<span>{orderPreviewData?.data.data.package.name || ''}</span>
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='invest-amount' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data.data.package_cost_in_pf || 0} />
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='roi-percentage' />
					<span className='font-bold sm:text-xl text-base'>
						<span>{orderPreviewData?.data.data.package.roi_percentage || 0} %</span>
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='direct-percentage' />
					<span className='font-bold sm:text-xl text-base'>
						<span>{orderPreviewData?.data.data.package.direct_percentage || 0} %</span>
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='subscription' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data.data.registration_fee_in_pf || 0} />
					</span>
				</div>

				<div className='flex justify-between items-center border-b border-b-[var(--dg-border-color)] pb-2'>
					<Text className='font-bold text-base' tid='payment-type' />
					<span className='font-bold sm:text-xl text-base'>
						{orderPreviewData?.data.data.payment_type.toUpperCase()}
					</span>
				</div>

				<div className='flex justify-between items-center pb-2'>
					<Text className='font-bold text-base' tid='total-payment' />
					<span className='font-bold sm:text-xl text-base'>
						<DgPrice price={orderPreviewData?.data.data.total_cost_in_pf || 0} />
					</span>
				</div>
			</div>
		</DgSpinnerFull>
	);
};

export default OrderPayment;
