import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import { routes } from 'core/constants/routes';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import {
	setApiCatcherFormErrors,
	setIsHijack,
} from 'core/store/slice/mainInfo/mainInfoSlice';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useHijackQuery = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutationHook(
		[endPointUrls.ADMIN_USER_HIJACK],
		(data: any) => api.post(endPointUrls.ADMIN_USER_HIJACK, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
					dispatch(setIsHijack(true));
					Cookies.set('refresh-jwt', response.data?.data?.access_token);
					queryClient.invalidateQueries([endPointUrls.PROFILE_MANAGEMENT]);
					queryClient.invalidateQueries([endPointUrls.WALLETS]);
					queryClient.invalidateQueries([endPointUrls.PROFILE_ROLES]);
					navigate(routes.DASHBOARD);
				}
			},
		}
	) as UseMutationResult;
};
