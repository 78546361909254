import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IAllCommissionSumsDetail } from 'core/types/models/planCommissionsType';
import useACL from 'core/services/role/useACL';

export const useAdminAllCommissionCount = () => {
	const { roles, hasAccessTo } = useACL();

	return useQueryHook(
		[endPointUrls.ALL_COMMISSIONS_COUNTS],
		(data: any) => api.get(endPointUrls.ALL_COMMISSIONS_COUNTS, data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
			enabled: hasAccessTo(roles.user?.admin),
		}
	) as UseQueryResult<IAllCommissionSumsDetail, Error>;
};
