import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserOrdersTable from './components/UserOrdersTable';
import UserOrdersFilter from './components/UserOrdersFilter';
import { routes } from 'core/constants/routes';
import { useNavigate } from 'react-router-dom';

const UserPlanHistory = () => {
	const navigate = useNavigate();

	return (
		<DgCard>
			<DgCardHeader
				title='invests-history'
				buttonText='my-invest'
				onClick={() => navigate(routes.ORDERS_LIST)}
			/>

			<UserOrdersFilter />
			<UserOrdersTable showMoreColumn={true} />
		</DgCard>
	);
};

export default UserPlanHistory;
