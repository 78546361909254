import { mainFormatNumber } from 'core/utils/utils';
import { FC, ReactNode } from 'react';
import DgImage from '../Image/DgImage';
import usdt from '../../../assets/images/usdt.png';

interface IDgPrice {
	price: number | string | undefined;
	colored?: boolean;
	suffix?: string | ReactNode;
	suffixColor?: string;
	className?: string;
}

const DgPrice: FC<IDgPrice> = ({
	price,
	colored,
	className,
	suffix = <DgImage src={usdt} width={20} className='rounded-full' />,
	suffixColor = 'text-[var(--dg-disabled-color-light)]',
}) => {
	if (colored) {
		return price === 0 ? (
			<>{price}</>
		) : price ? (
			+price > 0 ? (
				<span className={`text-[var(--dg-green-color)] whitespace-nowrap ltr flex items-center gap-2 rtl:justify-end ${className}`}>
					<span>{price ? mainFormatNumber(Math.abs(+price)) : 0}</span> <span className={`text-xs ${suffixColor}`}>{suffix}</span>
				</span>
			) : (
				<span className={`text-[var(--dg-red-color-2)] whitespace-nowrap ltr flex items-center gap-2 rtl:justify-end ${className}`}>
					<span>{price ? '-' + mainFormatNumber(Math.abs(+price)) : 0}</span> <span className={`text-xs ${suffixColor}`}>{suffix}</span>
				</span>
			)
		) : (
			<>{price}</>
		);
	}

	return (
		<span className={`whitespace-nowrap flex items-center ltr gap-2 rtl:justify-end ${className}`}>
			<span>{price ? mainFormatNumber(price) : 0}</span> <span className={`text-xs ${suffixColor}`}>{suffix}</span>
		</span>
	);
};

export default DgPrice;
