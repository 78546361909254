import { Button, Checkbox, Collapse, Typography } from 'antd';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import { useEffect, useState } from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgModal from 'styles/UI/Modal/DgModal';
import logo from '../../assets/images/logo/logo.png';

const { Panel } = Collapse;

export const RulesModal = ({ useModal }: any) => {
	const { t } = useTranslate();

	const { modal, showModal, hideModal } = useModal;

	const onChange = (key: string | string[]) => {};

	useEffect(() => {});

	const [active, setActive] = useState(true);

	const onAccept = (e: any) => {
		setActive(e?.target.checked);
	};

	return (
		<>
			<DgModal
				title='rules-title-1'
				open={modal}
				closable={false}
				className='faq-modal'
				width={860}
			>
				<div className='flex flex-col gap-4'>
					<div className='flex items-center justify-center'>
						<img src={logo} width={'130px'} />
					</div>

					<Typography.Title level={3}>
						<Text tid={'rules-heading-1'}></Text>
					</Typography.Title>
					<Typography>
						<Text tid={'rules-description-1'}></Text>
					</Typography>

					<Typography.Title level={3}>
						<Text tid={'rules-heading-2'}></Text>
					</Typography.Title>
					<Typography>
						<Text tid={'rules-description-2'}></Text>
					</Typography>

					<Typography.Title level={3}>
						<Text tid={'rules-heading-3'}></Text>
					</Typography.Title>
					<Typography>
						<Text tid={'rules-description-3'}></Text>
					</Typography>

					<Typography.Title level={3}>
						<Text tid={'rules-heading-4'}></Text>
					</Typography.Title>

					<Collapse defaultActiveKey={['1']} onChange={onChange}>
						{[1, 2, 3, 4, 5].map((item) => (
							<Panel header={t(`rule-item-heading-${item}`)} key={item}>
								<Text tid={`rule-item-description-${item}`}></Text>
							</Panel>
						))}
					</Collapse>

					<Typography>
						<Text tid={'rules-description-4-0'}></Text>
					</Typography>

					<Typography>
						<Text tid={'rules-description-4'}></Text>
					</Typography>

					{/*<Typography>*/}
					{/*	<Text tid={'rules-description-5'}></Text>*/}
					{/*</Typography>*/}

					{/* <div className={'flex'}>
						<Checkbox onChange={onAccept} checked={active}/>
						<Typography className={'mx-2'}>
							<Text tid={'rules-check'} />
						</Typography>
					</div> */}

					<div className='flex flex-wrap gap-4 items-center justify-end'>
						<a target='_blank' href='/panel/rules.pdf' className=''>
							<Text tid='download-rules' />
						</a>

						<div
							onClick={() => active && hideModal()}
							className={`py-2 px-5 rounded-lg ${
								active ? 'bg-[#ffc800] text-black' : 'bg-gray-500'
							} cursor-pointer`}
						>
							{/* <Text tid={'i-agree-the-rules'} /> */}
							<Text tid={'rules-check'} />
						</div>
					</div>
				</div>
			</DgModal>
		</>
	);
};
