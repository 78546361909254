import React from 'react';
import { useState, useEffect } from 'react';
import { FaApple, FaQrcode, FaUnlockAlt, FaRegCaretSquareRight, FaClipboardCheck } from 'react-icons/fa';
import { AuthWrapper } from '../../styles/AuthenticationStyles';
import AppDownload from './components/AppDownload';
import ScanQr from './components/ScanQr';
import SetupApp from './components/SetupApp';
import RestoreCode from './components/RestoreCode';
import ProcessCompletion from './components/ProcessCompletion';
import { TABLET_SIZE } from '../../core/constants/common';
import RWizard from '../responsive/authentication/RWizard';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { routes } from 'core/constants/routes';
import DgSpinner from 'styles/UI/Spinner/DgSpinner';
import Wizard from './components/Wizard';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { IGoogle2faSecretResponse } from 'core/types/models/google2faType';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';

const GoogleAuth = () => {
	const [loading, setLoading] = useState(false);
	const [active2fa, setActive2fa] = useState<IGoogle2faSecretResponse>();
	const { setUrlParam, query } = useQueryUrlParam();
	const { data: profile, isFetching } = getCurrentUserInfoQuery();
	const { width } = useWindowSize();

	const onNextStepHandler = (type: 'valid') => {
		if (type === 'valid') {
			setUrlParam({ active: (query?.active || 0) + 1 });
		}
	};

	const onPrevStepHandler = () => {
		setUrlParam({ active: (query?.active || 0) - 1 });
	};

	const steps = [
		{ data: <AppDownload key='AppDownload' onNextStepHandler={onNextStepHandler} /> },
		{ data: <ScanQr key='ScanQr' onPrevStepHandler={onPrevStepHandler} onNextStepHandler={onNextStepHandler} setLoading={setLoading} setActive2fa={setActive2fa} /> },
		{ data: <RestoreCode key='RestoreCode' onPrevStepHandler={onPrevStepHandler} onNextStepHandler={onNextStepHandler} setLoading={setLoading} active2fa={active2fa} /> },
		{ data: <SetupApp key='SetupApp' onPrevStepHandler={onPrevStepHandler} onNextStepHandler={onNextStepHandler} setLoading={setLoading} /> },
		{ data: <ProcessCompletion key='ProcessCompletion' /> },
	];

	useEffect(() => {
		if (!profile?.data?.google2fa_enable || !query.active || isNaN(query.active) || query.active >= steps.length + 1 || query.active <= 0) setUrlParam({ active: 1 });
	}, [profile]);

	useEffect(() => {
		if (profile?.data?.google2fa_enable) setUrlParam({ active: 5 });
	}, [profile]);

	return (
		<DgSpinnerFull isLoading={isFetching}>
			{width > TABLET_SIZE ? <Wizard active={query?.active} items={wizardData} /> : <RWizard active={query?.active} items={wizardData} />}

			<AuthWrapper>
				<DgCard isMarginBottom={false}>
					<DgCardHeader title='active-google-two-authentication' tabTitle='active-google-two-authentication' back={routes.PROFILE_SECURITY} />
					{steps.map(({ data }, index) => index + 1 === query?.active && data)}

					{loading && (
						<div className='h-60'>
							<DgSpinner />
						</div>
					)}
				</DgCard>
			</AuthWrapper>
			{/* <AuthLoading loading={isFetching} /> */}
		</DgSpinnerFull>
	);
};

const wizardData = [
	{ title: 'app-download', icon: FaApple },
	{ title: 'scan-qr', icon: FaQrcode },
	{ title: 'restore-code', icon: FaUnlockAlt },
	{ title: 'setup-app', icon: FaRegCaretSquareRight },
	{ title: 'process-completion', icon: FaClipboardCheck },
];

export default GoogleAuth;
