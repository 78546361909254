import { Form } from 'antd';
import { getMlmInfoQuery } from 'core/hooks/react-query/getMlmInfoQuery';
import { useEffect } from 'react';
import { useUpdateBinaryPositionQuery } from './react-query/useUpdateBinaryPositionQuery';

const useProfileMlmSetting = () => {
	const [binaryPositionForm] = Form.useForm();
	const { data: mlmInfo, isFetching: mlmInfoIsFetching } = getMlmInfoQuery();
	const { mutate: updateBinaryPosition, isLoading: binaryPositionIsLoading } = useUpdateBinaryPositionQuery();

	useEffect(() => {
		binaryPositionForm.setFieldsValue({ default_binary_position: mlmInfo?.data.default_binary_position });
	}, [mlmInfo]);

	const onFinishUpdateBinaryPosition = (values: { default_binary_position: 'left' | 'right' }) => {
		updateBinaryPosition(values);
	};

	return { binaryPositionForm, mlmInfoIsFetching, onFinishUpdateBinaryPosition, binaryPositionIsLoading };
};

export default useProfileMlmSetting;
