import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import useForgetPassword from './hooks/useForgetPassword';

const ForgetPassword: FC = () => {
	const {
		forgetPasswordForm,
		isLoadingForgetPassword,
		onFinishForgetPassword,
		onChangeCaptcha,
		isShowCaptcha,
	} = useForgetPassword();
	const { setUrlParam } = useMasterQueryUrlParam();
	const { t } = useTranslate();

	return (
		<DgForm
			autoComplete='off'
			onFinish={onFinishForgetPassword}
			form={forgetPasswordForm}
		>
			<DgFormWrapper singleColumn>
				<DgFormItem
					rules={[
						{
							required: true,
							message: t('email-is-required'),
						},
						{
							type: 'email',
							message: t('email-format-incorrect'),
						},
					]}
					name='email'
				>
					<DgInputLabel autoComplete='new-password' label='email' />
				</DgFormItem>

				{isShowCaptcha && (
					<DgFormItem>
						<ReCAPTCHA
							theme='dark'
							lang='persian'
							sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
							onChange={onChangeCaptcha}
						/>
					</DgFormItem>
				)}

				<DgFormItem button>
					<div className='flex justify-between items-center gap-6 flex-wrap'>
						<span
							onClick={() => setUrlParam({ page: 1 })}
							className='hover:border-b text-base border-b border-b-transparent hover:border-b-[var(--dg-orange-color)] hover:text-[var(--dg-orange-color)] cursor-pointer'
						>
							<Text tid='signin' />
						</span>

						<DgButton
							htmlType='submit'
							isLoading={isLoadingForgetPassword}
							title='submit'
						/>
					</div>
				</DgFormItem>
			</DgFormWrapper>
		</DgForm>
	);
};

export default ForgetPassword;
