import { createSlice } from '@reduxjs/toolkit';
import { stringToBoolean } from 'core/utils/utils';
import Cookies from 'js-cookie';

const initialState: { apiCatcherFormErrors: any[]; isAuthenticated: boolean; token: string | null; has2FA: boolean; isHijack: boolean } = {
	has2FA: false,
	isHijack: !!Cookies.get('refresh-jwt'),
	token: null,
	apiCatcherFormErrors: [],
	isAuthenticated: stringToBoolean(process.env.REACT_APP_LOGIN_LOCAL) || !!Cookies.get('refresh-jwt') || !!Cookies.get('jwt'),
};

export const mainInfoSlice = createSlice({
	name: 'mainInfo',
	initialState: initialState,
	reducers: {
		setApiCatcherFormErrors: (state, { payload }) => {
			state.apiCatcherFormErrors = payload;
		},
		setAuthenticated: (state, { payload }) => {
			state.isAuthenticated = payload;
		},
		setToken: (state, { payload }) => {
			state.token = payload;
		},
		setHas2FA: (state, { payload }) => {
			state.has2FA = payload;
		},
		setIsHijack: (state, { payload }) => {
			state.isHijack = payload;
		},
	},
});

export const { setApiCatcherFormErrors, setAuthenticated, setToken, setHas2FA, setIsHijack } = mainInfoSlice.actions;
export default mainInfoSlice.reducer;
