import Text from '../../core/utils/Text';


const DgPackageDateDelay = () => {

    return (
        <div className={'bg-orange-500 bg-opacity-70 w-full lg:w-max px-5 lg:px-10 py-2 lg:py-3 rounded-md'}>
            <Text tid={'package-date-start-note'} />
        </div>
    );
};

export default DgPackageDateDelay;
