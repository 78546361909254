import { Form, FormItemProps } from 'antd';
import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface IDgFormItem extends FormItemProps {
	noMargin?: boolean;
	full?: boolean;
	button?: boolean;
	labelLeft?: boolean;
}

const DgFormItem: FC<IDgFormItem> = ({ noMargin, className, full, button, children, labelLeft, ...props }) => {
	const { lang } = useSelector((store: RootState) => store.settingStore);

	return (
		<Form.Item
			className={
				`${labelLeft ? (lang === 'en' ? 'label-right' : 'label-left') : ''} ${noMargin ? 'mb-0' : 'md:mb-2 mb-5'} ${full && 'col-span-full w-full'} ${
					button && `col-span-full  w-full mt-2  pt-4 ${lang === 'en' ? 'text-right' : 'text-left'} mb-0`
				} ` + className
			}
			{...props}
		>
			{children}
		</Form.Item>
	);
};

export default DgFormItem;
