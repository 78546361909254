import { FC, useEffect } from 'react';
import CountsItemsWrapper, { ICountsItemsWrapper } from './CountsItemWrapper';
import { sumObjectValues } from '../../../../core/utils/utils';
import useUserWallet from 'pages/UserWallets/hooks/useUserWallet';
import DgPrice from 'styles/UI/Price/DgPrice';
import { useAdminAllCommissionCount } from './hooks/react-query/useAdminAllCommissionCount';
import useACL from 'core/services/role/useACL';
import { useAdminOrdersCountsQuery } from './hooks/react-query/useAdminOrdersCountsQuery';
import { useAdminOrdersSumsQuery } from './hooks/react-query/useAdminOrdersSumsQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import useTranslate from 'core/utils/useTranslate';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/constants/routes';
import Text from 'core/utils/Text';
import { useAdminAccountingQueries } from 'pages/Admin/Accounting/hooks/react-query/useAdminAccountingQuery';

const Counts: FC = () => {
	// hooks
	const { roles, hasAccessTo } = useACL();
	const { t } = useTranslate();
	const navigate = useNavigate();

	// query
	const { data: adminOrdersCounts, isFetching: adminOrdersCountIsFetching } =
		useAdminOrdersCountsQuery();
	const { deposit_Wallet, userWalletIsFetching } = useUserWallet();
	const { data: allCommissionsSums, isFetching: allCommissionsSumsIsFetching } =
		useAdminAllCommissionCount();
	const { data: allAdminOrdersSums, isFetching: allAdminOrdersSumsIsFetching } =
		useAdminOrdersSumsQuery();

	const { isFetching: currentUserInfoIsFetching } = getCurrentUserInfoQuery();
	const { withdrawCounts, usersDepositWalletBalances } = useAdminAccountingQueries();

	// const { data: packagePurchaseCounts, isFetching: packagePurchaseCountsIsFetching } = usePackagesBuyQuery();
	// const { data: ordersCounts, isFetching: ordersCountIsFetching } = useOrdersCountsQuery();

	// store
	const { currentUserInfo } = useSelector((store: RootState) => store.userInfoStore);
	const { lang } = useSelector((store: RootState) => store.settingStore);

	// buttons
	const ButtonWalletsPage =
		lang === 'en' ? (
			<FaArrowCircleRight
				onClick={() => navigate(routes.WALLETS)}
				style={{ cursor: 'pointer' }}
				size={24}
				color='var(--dg-orange-color)'
			/>
		) : (
			<FaArrowCircleLeft
				onClick={() => navigate(routes.WALLETS)}
				style={{ cursor: 'pointer' }}
				size={24}
				color='var(--dg-orange-color)'
			/>
		);

	const ButtonPlansCommissionPage =
		lang === 'en' ? (
			<FaArrowCircleRight
				onClick={() => navigate(routes.ORDERS_HISTORY)}
				style={{ cursor: 'pointer' }}
				size={24}
				color='var(--dg-orange-color)'
			/>
		) : (
			<FaArrowCircleLeft
				onClick={() => navigate(routes.ORDERS_HISTORY)}
				style={{ cursor: 'pointer' }}
				size={24}
				color='var(--dg-orange-color)'
			/>
		);

	useEffect(() => {
		// withdrawCounts.mutate({});
		// usersDepositWalletBalances.mutate({});
	}, []);

	const row0: ICountsItemsWrapper[] = [
		{
			id: 0,
			title: (
				<div className='flex'>
					<Text tid='total-users-balances' />
				</div>
			),
			className: 'col-span-9 lg:col-span-3',
			wrapperClassName: 'grid grid-cols-1',
			role: hasAccessTo(roles.order?.admin),
			loading: usersDepositWalletBalances?.isLoading,
			data: [
				{
					title: t('total-users-balances'),
					titleClassName: 'justify-center',
					countClassName: 'h-full flex items-center justify-center',
					lineBarCount: usersDepositWalletBalances?.data?.data?.data.current_balance || 0,
					count: (
						<DgPrice
							price={usersDepositWalletBalances?.data?.data?.data.current_balance || 0}
						/>
					),
					color: 'bg-[var(--dg-info-color)]',
				},
			],
		},

		{
			id: 1,
			title: (
				<div className='flex items-center gap-2 justify-between'>
					<Text tid='withdraws' />
				</div>
			),
			className: 'col-span-9 lg:col-span-6',
			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
			loading: withdrawCounts.isLoading,
			role: hasAccessTo(roles.order?.admin),
			data: [
				{
					title:
						t('total') + ' ' + withdrawCounts?.data?.data?.data.count_all_requests || 0,
					lineBarCount: withdrawCounts?.data?.data?.data.count_all_requests || 0,
					count: (
						<div className='flex flex-col gap-1'>
							{/* <span className='text-sm'>
								<span>{withdrawCounts?.data?.data?.data.count_all_requests || 0}</span>{' '}
								<Text className='inline' tid='items' />
							</span> */}

							<DgPrice
								price={withdrawCounts?.data?.data?.data.sum_amount_all_requests || 0}
							/>
						</div>
					),
					color: 'bg-[var(--dg-info-color)]',
				},
				{
					title:
						t('processed') +
							' ' +
							withdrawCounts?.data?.data?.data.count_processed_requests || 0,
					lineBarCount: withdrawCounts?.data?.data?.data.count_processed_requests || 0,
					count: (
						<div className='flex flex-col gap-1'>
							{/* <span className='text-sm'>
								<span>
									{withdrawCounts?.data?.data?.data.count_processed_requests || 0}
								</span>{' '}
								<Text className='inline' tid='items' />
							</span> */}

							<DgPrice
								price={
									withdrawCounts?.data?.data?.data.sum_amount_processed_requests || 0
								}
							/>
						</div>
					),
					color: 'bg-[var(--dg-green-color)]',
				},
				{
					title:
						t('canceled') +
							' ' +
							withdrawCounts?.data?.data?.data.count_rejected_requests || 0,
					lineBarCount: withdrawCounts?.data?.data?.data.count_rejected_requests || 0,
					count: (
						<div className='flex flex-col gap-1'>
							{/* <span className='text-sm'>
								<span>
									{withdrawCounts?.data?.data?.data.count_rejected_requests || 0}
								</span>{' '}
								<Text className='inline' tid='items' />
							</span> */}

							<DgPrice
								price={withdrawCounts?.data?.data?.data.sum_amount_rejected_requests || 0}
							/>
						</div>
					),
					color: 'bg-[var(--dg-red-color)]',
				},
				{
					title:
						t('pending') +
							' ' +
							withdrawCounts?.data?.data?.data.count_pending_requests || 0,
					lineBarCount: withdrawCounts?.data?.data?.data.count_pending_requests || 0,
					count: (
						<div className='flex flex-col gap-1'>
							{/* <span className='text-sm'>
								<span>
									{withdrawCounts?.data?.data?.data.count_pending_requests || 0}
								</span>{' '}
								<Text className='inline' tid='items' />
							</span> */}

							<DgPrice
								price={withdrawCounts?.data?.data?.data.sum_amount_pending_requests || 0}
							/>
						</div>
					),
					color: 'bg-[var(--dg-orange-color)]',
				},
			],
		},
		// {
		// 	id: 2,
		// 	title: (
		// 		<div className='flex items-center gap-2 justify-between'>
		// 			<Text tid='sum-investment' /> <span>{ButtonPlansCommissionPage}</span>
		// 		</div>
		// 	),
		// 	className: 'col-span-9 lg:col-span-3',
		// 	wrapperClassName: 'grid grid-cols-1 text-center',
		// 	loading: currentUserInfoIsFetching,
		// 	role: hasAccessTo(roles.order?.admin),
		// 	data: [],
		// },
	];

	const row2: ICountsItemsWrapper[] = [
		{
			id: 0,
			title: t('all-profits'),
			className: 'col-span-8 lg:col-span-4',
			wrapperClassName: 'grid grid-cols-2 xs:grid-cols-3 gap-y-3',
			loading: allAdminOrdersSumsIsFetching || allCommissionsSumsIsFetching,
			role: hasAccessTo(roles.order?.admin),
			data: [
				{
					title: t('total-commissions'),
					lineBarCount: allCommissionsSums?.data
						? sumObjectValues(allCommissionsSums?.data)
						: 0,
					count: (
						<DgPrice
							price={
								allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0
							}
						/>
					),
					color: 'bg-[var(--dg-red-color)]',
				},

				{
					title: t('sum-investment'),
					titleClassName: 'justify-center',
					countClassName: 'h-full flex items-center justify-center',
					lineBarCount: allAdminOrdersSums?.data?.paid_orders_sum,
					count: <DgPrice price={allAdminOrdersSums?.data?.paid_orders_sum || 0} />,
					color: 'bg-[var(--dg-info-color)]',
				},
				// {
				// 	title: t('total-sales'),
				// 	lineBarCount: allAdminOrdersSums?.data?.paid_orders_sum || 0,
				// 	count: <DgPrice price={allAdminOrdersSums?.data?.paid_orders_sum || 0} />,
				// 	color: 'bg-[var(--dg-info-color)]',
				// },
				// {
				// 	title: t('total-revenue'),
				// 	lineBarCount:
				// 		(allAdminOrdersSums?.data?.paid_orders_sum || 0) -
				// 		(allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0),
				// 	count: (
				// 		<DgPrice
				// 			price={
				// 				(allAdminOrdersSums?.data?.paid_orders_sum || 0) -
				// 				(allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0)
				// 			}
				// 		/>
				// 	),
				// 	color: 'bg-[var(--dg-green-color)]',
				// },
			],
		},
		{
			id: 1,
			title: t('all-investments'),
			className: 'col-span-8 lg:col-span-4',
			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
			loading: adminOrdersCountIsFetching,
			role: hasAccessTo(roles.order?.admin),
			data: [
				{
					title: t('total'),
					lineBarCount: adminOrdersCounts?.data?.total_orders || 0,
					count: adminOrdersCounts?.data?.total_orders || 0,
					color: 'bg-[var(--dg-info-color)]',
				},
				{
					title: t('active'),
					lineBarCount: adminOrdersCounts?.data?.active_orders || 0,
					count: adminOrdersCounts?.data?.active_orders || 0,
					color: 'bg-[var(--dg-green-color)]',
				},
				{
					title: t('canceled'),
					lineBarCount: adminOrdersCounts?.data?.canceled_orders || 0,
					count: adminOrdersCounts?.data?.canceled_orders || 0,
					color: 'bg-[var(--dg-red-color)]',
				},
				{
					title: t('expired'),
					lineBarCount: adminOrdersCounts?.data?.expired_orders || 0,
					count: adminOrdersCounts?.data?.expired_orders || 0,
					color: 'bg-[var(--dg-orange-color)]',
				},
			],
		},
	];

	const row4: ICountsItemsWrapper[] = [
		{
			id: 0,
			title: t('all-profits'),
			className: 'col-span-8',
			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
			loading: allCommissionsSumsIsFetching,
			role: hasAccessTo(roles.user?.admin),
			data: [
				{
					title: t('total-profit'),
					lineBarCount: allCommissionsSums?.data
						? sumObjectValues(allCommissionsSums?.data)
						: 0,
					count: (
						<DgPrice
							price={
								allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0
							}
						/>
					),
					color: 'bg-[var(--dg-info-color)]',
				},
				// {
				// 	title: t('binary_sum'),
				// 	lineBarCount: allCommissionsSums?.data?.binary_sum || 0,
				// 	count: <DgPrice price={allCommissionsSums?.data?.binary_sum || 0} />,
				// 	color: 'bg-[var(--dg-green-color)]',
				// },
				{
					title: t('indirect_sale_sum'),
					lineBarCount: allCommissionsSums?.data?.indirect_sale_sum || 0,
					count: <DgPrice price={allCommissionsSums?.data?.indirect_sale_sum || 0} />,
					color: 'bg-[var(--dg-red-color)]',
				},
				{
					title: t('trading_profit_sum'),
					lineBarCount: allCommissionsSums?.data?.trading_profit_sum || 0,
					count: <DgPrice price={allCommissionsSums?.data?.trading_profit_sum || 0} />,
					color: 'bg-[var(--dg-orange-color)]',
				},
			],
		},
	];

	return (
		<>
			<div className='grid grid-cols-9 mb-3 gap-3'>
				{row0.map(
					(item) =>
						item.role && (
							<CountsItemsWrapper
								noBar={item.noBar}
								role={item.role}
								key={item.id}
								title={item.title}
								className={item.className}
								loading={item.loading}
								wrapperClassName={item.wrapperClassName}
								data={item.data}
							/>
						)
				)}
			</div>

			<div className='grid grid-cols-8 gap-3'>
				{row2.map(
					(item) =>
						item.role && (
							<CountsItemsWrapper
								role={item.role}
								key={item.id}
								title={item.title}
								className={item.className}
								loading={item.loading}
								wrapperClassName={item.wrapperClassName}
								data={item.data}
							/>
						)
				)}

				{/* {row4.map(
					(item) =>
						item.role && (
							<CountsItemsWrapper
								role={item.role}
								key={item.id}
								title={item.title}
								className={item.className}
								loading={item.loading}
								wrapperClassName={item.wrapperClassName}
								data={item.data}
							/>
						)
				)} */}
			</div>
		</>
	);
};

export default Counts;

// full version
// import { FC } from 'react';
// import CountsItemsWrapper, { ICountsItemsWrapper } from './CountsItemWrapper';
// import { useOrdersCountsQuery } from './hooks/react-query/useOrdersCountsQuery';
// import { usePackagesBuyQuery } from './hooks/react-query/usePackagesBuyQuery';
// import { mainFormatNumber, sumObjectValues } from '../../../../core/utils/utils';
// import useUserWallet from 'pages/UserWallets/hooks/useUserWallet';
// import DgPrice from 'styles/UI/Price/DgPrice';
// import { useAdminAllCommissionCount } from './hooks/react-query/useAdminAllCommissionCount';
// import useACL from 'core/services/role/useACL';
// import { useAdminOrdersCountsQuery } from './hooks/react-query/useAdminOrdersCountsQuery';
// import { useAdminOrdersSumsQuery } from './hooks/react-query/useAdminOrdersSumsQuery';
// import { useSelector } from 'react-redux';
// import { RootState } from 'core/store';
// import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
// import useTranslate from 'core/utils/useTranslate';
// import DgCountDown from 'styles/UI/CountDown/DgCountDown';

// const Counts: FC = () => {
// 	// hooks
// 	const { roles, hasAccessTo } = useACL();
// 	const { t } = useTranslate();

// 	// query
// 	const { data: ordersCounts, isFetching: ordersCountIsFetching } = useOrdersCountsQuery();
// 	const { data: adminOrdersCounts, isFetching: adminOrdersCountIsFetching } = useAdminOrdersCountsQuery();
// 	const { data: packagePurchaseCounts, isFetching: packagePurchaseCountsIsFetching } = usePackagesBuyQuery();
// 	const { deposit_Wallet, userWalletIsFetching } = useUserWallet();
// 	const { data: allCommissionsSums, isFetching: allCommissionsSumsIsFetching } = useAdminAllCommissionCount();
// 	const { data: allAdminOrdersSums, isFetching: allAdminOrdersSumsIsFetching } = useAdminOrdersSumsQuery();
// 	const { isFetching: currentUserInfoIsFetching } = getCurrentUserInfoQuery();

// 	// store
// 	const { currentUserInfo } = useSelector((store: RootState) => store.userInfoStore);

// 	const row0: ICountsItemsWrapper[] = [
// 		{
// 			title: t('subscription'),
// 			className: 'col-span-8 lg:col-span-3',
// 			wrapperClassName: 'grid grid-cols-1 text-center',
// 			role: true,
// 			loading: currentUserInfoIsFetching,
// 			data: [
// 				{
// 					titleClassName: 'justify-center',
// 					countClassName: 'h-full flex items-center justify-center',
// 					title: t('expire-time'),
// 					count: currentUserInfo.data?.expire_subscription ? <DgCountDown timeStamp={currentUserInfo.data?.expire_subscription} /> : t('No Subscribe'),
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 			],
// 		},
// 		{
// 			title: 'My Profit',
// 			className: 'col-span-8 lg:col-span-5',
// 			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-3 gap-y-3',
// 			loading: currentUserInfoIsFetching,
// 			role: true,
// 			data: [
// 				{
// 					title: 'Total Commission',
// 					lineBarCount: currentUserInfo?.data?.sum_commission,
// 					count: <DgPrice price={currentUserInfo?.data?.sum_commission || 0} />,
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 				{
// 					title: 'Sum of total investments',
// 					lineBarCount: currentUserInfo?.data?.sum_orders,
// 					count: <DgPrice price={currentUserInfo?.data?.sum_orders || 0} />,
// 					color: 'bg-[var(--dg-info-color)]',
// 				},
// 				{
// 					title: 'Total Profit',
// 					lineBarCount: (currentUserInfo?.data?.sum_commission || 0) - (currentUserInfo?.data?.sum_orders || 0),
// 					count: <DgPrice price={(currentUserInfo?.data?.sum_commission || 0) - (currentUserInfo?.data?.sum_orders || 0)} colored />,
// 					color: 'bg-[var(--dg-purple-color)]',
// 				},
// 			],
// 		},
// 	];

// 	const row1: ICountsItemsWrapper[] = [
// 		{
// 			title: 'Packages',
// 			className: 'col-span-8 lg:col-span-3',
// 			wrapperClassName: 'grid grid-cols-2 xs:grid-cols-2 gap-y-3',
// 			loading: packagePurchaseCountsIsFetching,
// 			role: true,
// 			data: [
// 				{
// 					title: 'One Year',
// 					lineBarCount: packagePurchaseCounts?.data?.O || 0,
// 					count: mainFormatNumber(packagePurchaseCounts?.data?.O || 0, { point: 2 }) || 0,
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 				{
// 					title: 'Two Year',
// 					lineBarCount: packagePurchaseCounts?.data?.T || 0,
// 					count: mainFormatNumber(packagePurchaseCounts?.data?.T || 0, { point: 2 }) || 0,
// 					color: 'bg-[var(--dg-info-color)]',
// 				},
// 			],
// 		},
// 		{
// 			title: 'My Investments',
// 			className: 'col-span-8 lg:col-span-5',
// 			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
// 			loading: ordersCountIsFetching,
// 			role: true,
// 			data: [
// 				{ title: 'total', lineBarCount: ordersCounts?.data?.total_orders || 0, count: ordersCounts?.data?.total_orders || 0, color: 'bg-[var(--dg-info-color)]' },
// 				{ title: 'paid', lineBarCount: ordersCounts?.data?.total_paid || 0, count: ordersCounts?.data?.total_paid || 0, color: 'bg-[var(--dg-purple-color)]' },
// 				{ title: 'active', lineBarCount: ordersCounts?.data?.total_active_count || 0, count: ordersCounts?.data?.total_active_count || 0, color: 'bg-[var(--dg-green-color)]' },
// 				{ title: 'expired', lineBarCount: ordersCounts?.data?.total_expired_count || 0, count: ordersCounts?.data?.total_expired_count || 0, color: 'bg-[var(--dg-red-color)]' },
// 			],
// 		},
// 	];

// 	const row2: ICountsItemsWrapper[] = [
// 		{
// 			title: 'All Profit',
// 			className: 'col-span-8 lg:col-span-5',
// 			wrapperClassName: 'grid grid-cols-2 xs:grid-cols-3 gap-y-3',
// 			loading: allAdminOrdersSumsIsFetching || allCommissionsSumsIsFetching,
// 			role: hasAccessTo(roles.order?.admin),
// 			data: [
// 				{
// 					title: 'Total Commission',
// 					lineBarCount: allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0,
// 					count: <DgPrice price={allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0} />,
// 					color: 'bg-[var(--dg-red-color)]',
// 				},
// 				{
// 					title: 'Total Sales',
// 					lineBarCount: allAdminOrdersSums?.data?.paid_orders_sum || 0,
// 					count: <DgPrice price={allAdminOrdersSums?.data?.paid_orders_sum || 0} />,
// 					color: 'bg-[var(--dg-info-color)]',
// 				},
// 				{
// 					title: 'Total Profit',
// 					lineBarCount: (allAdminOrdersSums?.data?.paid_orders_sum || 0) - (allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0),
// 					count: <DgPrice price={(allAdminOrdersSums?.data?.paid_orders_sum || 0) - (allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0)} />,
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 			],
// 		},
// 		{
// 			title: 'All Investments',
// 			className: 'col-span-8 lg:col-span-3',
// 			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
// 			loading: adminOrdersCountIsFetching,
// 			role: hasAccessTo(roles.order?.admin),
// 			data: [
// 				{ title: 'total', lineBarCount: adminOrdersCounts?.data?.total_orders || 0, count: adminOrdersCounts?.data?.total_orders || 0, color: 'bg-[var(--dg-info-color)]' },
// 				{ title: 'active', lineBarCount: adminOrdersCounts?.data?.active_orders || 0, count: adminOrdersCounts?.data?.active_orders || 0, color: 'bg-[var(--dg-green-color)]' },
// 				{
// 					title: 'canceled',
// 					lineBarCount: adminOrdersCounts?.data?.canceled_orders || 0,
// 					count: adminOrdersCounts?.data?.canceled_orders || 0,
// 					color: 'bg-[var(--dg-red-color)]',
// 				},
// 				{ title: 'expired', lineBarCount: adminOrdersCounts?.data?.expired_orders || 0, count: adminOrdersCounts?.data?.expired_orders || 0, color: 'bg-[var(--dg-orange-color)]' },
// 			],
// 		},
// 	];

// 	const row3: ICountsItemsWrapper[] = [
// 		{
// 			title: 'My Wallets',
// 			className: 'col-span-8',
// 			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
// 			loading: userWalletIsFetching,
// 			role: true,
// 			data: [
// 				{
// 					title: 'current-balance',
// 					lineBarCount: deposit_Wallet?.balance || 0,
// 					count: <DgPrice price={deposit_Wallet?.balance || 0} />,
// 					color: 'bg-[var(--dg-info-color)]',
// 				},
// 				{
// 					title: 'total-received',
// 					lineBarCount: deposit_Wallet?.total_received || 0,
// 					count: <DgPrice price={deposit_Wallet?.total_received || 0} colored />,
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 				{
// 					title: 'total-spent',
// 					lineBarCount: deposit_Wallet?.total_spent || 0,
// 					count: <DgPrice price={deposit_Wallet?.total_spent || 0} colored />,
// 					color: 'bg-[var(--dg-red-color)]',
// 				},
// 				{
// 					title: 'total-transfer',
// 					lineBarCount: deposit_Wallet?.total_transfer || 0,
// 					count: <DgPrice price={deposit_Wallet?.total_transfer || 0} colored />,
// 					color: 'bg-[var(--dg-orange-color)]',
// 				},
// 			],
// 		},
// 	];

// 	const row4: ICountsItemsWrapper[] = [
// 		{
// 			title: 'All Commissions',
// 			className: 'col-span-8',
// 			wrapperClassName: 'grid grid-cols-2 sm:grid-cols-4 gap-y-3',
// 			loading: allCommissionsSumsIsFetching,
// 			role: hasAccessTo(roles.user?.admin),
// 			data: [
// 				{
// 					title: 'Total Commission',
// 					lineBarCount: allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0,
// 					count: <DgPrice price={allCommissionsSums?.data ? sumObjectValues(allCommissionsSums?.data) : 0} />,
// 					color: 'bg-[var(--dg-info-color)]',
// 				},
// 				{
// 					title: 'binary_sum',
// 					lineBarCount: allCommissionsSums?.data?.binary_sum || 0,
// 					count: <DgPrice price={allCommissionsSums?.data?.binary_sum || 0} />,
// 					color: 'bg-[var(--dg-green-color)]',
// 				},
// 				{
// 					title: 'indirect_sale_sum',
// 					lineBarCount: allCommissionsSums?.data?.indirect_sale_sum || 0,
// 					count: <DgPrice price={allCommissionsSums?.data?.indirect_sale_sum || 0} />,
// 					color: 'bg-[var(--dg-red-color)]',
// 				},
// 				{
// 					title: 'trading_profit_sum',
// 					lineBarCount: allCommissionsSums?.data?.trading_profit_sum || 0,
// 					count: <DgPrice price={allCommissionsSums?.data?.trading_profit_sum || 0} />,
// 					color: 'bg-[var(--dg-orange-color)]',
// 				},
// 			],
// 		},
// 	];
// 	return (
// 		<>
// 			<div className='grid grid-cols-8 gap-3'>
// 				{row0.map(
// 					(item) =>
// 						item.role && (
// 							<CountsItemsWrapper
// 								role={item.role}
// 								key={item.id}
// 								title={item.title}
// 								className={item.className}
// 								loading={item.loading}
// 								wrapperClassName={item.wrapperClassName}
// 								data={item.data}
// 							/>
// 						)
// 				)}

// 				{row1.map(
// 					(item) =>
// 						item.role && (
// 							<CountsItemsWrapper
// 								role={item.role}
// 								key={item.id}
// 								title={item.title}
// 								className={item.className}
// 								loading={item.loading}
// 								wrapperClassName={item.wrapperClassName}
// 								data={item.data}
// 							/>
// 						)
// 				)}

// 				{row2.map(
// 					(item) =>
// 						item.role && (
// 							<CountsItemsWrapper
// 								role={item.role}
// 								key={item.id}
// 								title={item.title}
// 								className={item.className}
// 								loading={item.loading}
// 								wrapperClassName={item.wrapperClassName}
// 								data={item.data}
// 							/>
// 						)
// 				)}

// 				{row3.map(
// 					(item) =>
// 						item.role && (
// 							<CountsItemsWrapper
// 								role={item.role}
// 								key={item.id}
// 								title={item.title}
// 								className={item.className}
// 								loading={item.loading}
// 								wrapperClassName={item.wrapperClassName}
// 								data={item.data}
// 							/>
// 						)
// 				)}

// 				{row4.map(
// 					(item) =>
// 						item.role && (
// 							<CountsItemsWrapper
// 								role={item.role}
// 								key={item.id}
// 								title={item.title}
// 								className={item.className}
// 								loading={item.loading}
// 								wrapperClassName={item.wrapperClassName}
// 								data={item.data}
// 							/>
// 						)
// 				)}
// 			</div>
// 		</>
// 	);
// };

// export default Counts;
