import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import { userActivity } from 'core/types/models/userActivityType';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const UserActivityTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='user' />,
			key: 'name',
			dataIndex: '',
			render: (text, record: any) => {
				return (
					<Link
						to={routes.ADMIN_USER_Detail.link(record.user?.id)}
						className='w-full flex flex-col items-start justify-center gap-1 group'
					>
						<Text
							tid={`${record.user?.full_name} ${
								(record.user?.member_id || record?.user_member_id) &&
								`(${record.user?.member_id || record?.user_member_id})`
							}`}
						/>
						<Text tid={`(${record.user?.email || record.user?.username})`} />
					</Link>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='country' />,
			key: 'country',
			dataIndex: 'country',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='state' />,
			key: 'state',
			dataIndex: 'state',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='ip' />,
			key: 'ip',
			dataIndex: 'ip',
			render: (text: any) => text,
			sorter: false,
		},
		{
			title: <Text tid='device-activity' />,
			render: (text: any) =>
				`${text?.device || ''} - ${text?.platform || ''} - ${text?.browser || ''}`,
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			key: 'when',
			dataIndex: 'when',
			render: (text: any) => text,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('user')}
							value={
								<>
									<Link
										to={routes.ADMIN_USER_Detail.link(record.user?.id)}
										className='w-full flex flex-col items-end justify-center gap-1 group'
									>
										<Text
											tid={`${record.user?.full_name} ${
												(record.user?.member_id || record?.user_member_id) &&
												`(${record.user?.member_id || record?.user_member_id})`
											}`}
										/>
										<Text tid={`(${record.user?.email || record.user?.username})`} />
									</Link>
								</>
							}
						/>
						<DgTableColumnResponsive title={t('country')} value={record?.country} />
						<DgTableColumnResponsive title={t('state')} value={record?.state} />
						<DgTableColumnResponsive title={t('ip')} value={record?.ip} />
						<DgTableColumnResponsive
							title={t('device-activity')}
							value={
								<span>{`${record?.device || ''} - ${record?.platform || ''} - ${
									record?.browser || ''
								}`}</span>
							}
						/>
						<DgTableColumnResponsive title={t('date-time')} value={record?.when} />
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default UserActivityTableColumn;
