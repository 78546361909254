import React, { useEffect, useState } from 'react';
import DgDevider from 'styles/UI/Devider/DgDevider';
import DgShowInfo from 'styles/UI/ShowInfo/DgShowInfo';
import DgSwitch from 'styles/UI/Switch/DgSwitch';
import useUserBlockUnBlock from '../hooks/useUserBlockUnBlock';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgButton from 'styles/UI/Button/DgButton';
import useTranslate from 'core/utils/useTranslate';
import useUserInfo from '../../UserInfo/hooks/useUserInfo';
import { useUserBlockUnBlockQuery } from '../hooks/react-query/useUserBlockUnBlockQuery';
import { useUserCommissionQuery } from '../hooks/react-query/useUserCommissionQuery';
import { Form } from 'antd';
import { useMemo } from 'react';

const UserCommissionStatus = () => {
	const [form] = Form.useForm();

	const trigger = useState({});

	const { userInfo, refetchUserInfo } = useUserInfo();

	const deactivated_commission_types: string[] | null =
		userInfo?.data?.deactivated_commission_types || [];

	const { mutateAsync: toggleCommission, isLoading } = useUserCommissionQuery();

	const commissions = [
		{
			key: 'invest-profit-commission',
		},
		{
			key: 'marketing-profit-commission',
		},
		// {
		// 	key: 'team-profit-commission',
		// },
		// {
		// 	key: 'flush-commission',
		// },
		// {
		// 	key: 'cooperation-profit-commission',
		// },
	];

	const initialValues = useMemo(() => {
		const data: any = {};

		if (deactivated_commission_types?.length) {
			deactivated_commission_types.forEach((item) => (data[item] = true));
		}

		return data;
	}, [deactivated_commission_types]);

	const values = form.getFieldsValue(true);

	const submit = async () => {
		const deactivated_commission_type: any = [];

		if (Object.keys(values)?.length) {
			Object.entries(values).forEach(([key, value]) => {
				if (value) {
					deactivated_commission_type.push(key);
				}
			});
		}

		await toggleCommission({
			deactivated_commission_type: deactivated_commission_type?.length
				? deactivated_commission_type
				: undefined,
			user_id: userInfo?.data?.id,
		});
		refetchUserInfo();
		// Object.keys(values).map((item) => {
		// 	toggleCommission({
		// 		deactivated_commission_type: item,
		// 	});
		// });
	};

	return (
		<>
			<DgDevider orientation='center' title='disabled-user-commissions' />

			<DgForm shouldUpdate initialValues={initialValues} form={form} onFinish={submit}>
				<DgFormWrapper>
					{commissions.map((item) => (
						<Item trigger={trigger} key={item.key} data={item} form={form} />
					))}

					<DgFormItem button>
						<DgButton title='submit' isLoading={isLoading} />
					</DgFormItem>
				</DgFormWrapper>
			</DgForm>
		</>
	);
};

const Item = ({ data, form, trigger }: any) => {
	const { key } = data;

	const { userInfo } = useUserInfo();

	const deactivated_commission_types: string[] | null =
		userInfo?.data?.deactivated_commission_types || [];

	const defaultChecked = deactivated_commission_types.indexOf(key) >= 0;
	const checked = form.getFieldValue(key);

	const { t } = useTranslate();
	const values = form.getFieldsValue(true);

	const filtered = Object.keys(values);

	// console.log({
	// 	form,
	// 	key,
	// 	checked,
	// 	userInfo,
	// 	values,
	// 	filtered,
	// 	deactivated_commission_types,
	// 	trigger: trigger[0],
	// });

	useEffect(() => {
		// console.log('trigger');
	}, [checked]);

	return (
		<DgShowInfo
			key={key}
			title={t(key)}
			value={
				<DgSwitch
					// disabled={true}
					// defaultChecked={defaultChecked}
					key={key}
					defaultChecked={checked}
					onChange={() => {
						form.setFieldValue(key, !checked);

						trigger[1]({ ...trigger[0], [key]: !checked });
					}}
				/>
			}
		/>
	);
};

export default UserCommissionStatus;
