import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setDepositDetail } from 'core/store/slice/deposit/depositSlice';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { IDepositTypeMutationResponse } from 'core/types/models/depositType';
import { useDispatch } from 'react-redux';

export const useUpdateInvoiceQuery = () => {
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.UPDATE_INVOICE],
		(data: any) => api.post(endPointUrls.UPDATE_INVOICE, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					dispatch(setDepositDetail(response.data?.data));
					toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<IDepositTypeMutationResponse, Error>;
};
