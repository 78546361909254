import { useAdminRoleQuery } from 'components/Admin/Role/List/hooks/react-query/useAdminRoleQuery';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';

const DgSelectRole = ({ ...props }) => {
	const { data } = useAdminRoleQuery();

	const options = data?.data.data?.map((item) => ({ label: item.name, value: item.id, filter: item.name }));

	return <DgSelectLabel mode='multiple' label='user-role' options={options} {...props} />;
};

export default DgSelectRole;
