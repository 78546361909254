import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminPlanCommissionsTable from './components/AdminPlanCommissionsTable';
import AdminPlansCommissionFilter from './components/AdminPlansCommissionFilter';
import { handleDownloadExcel } from 'core/helpers/excel';
import { useExportAdminOrderQuery } from 'components/Admin/Orders/List/hooks/react-query/useAdminOrderQuery';
import { useExportAdminPlanCommissionsQuery } from './hooks/react-query/useAdminPlanCommissionsQuery';

const AdminPlanCommissions = () => {
	const { mutateAsync, data, isLoading } = useExportAdminPlanCommissionsQuery();

	return (
		<DgCard>
			<DgCardHeader
				title='profits'
				onClick={async () => {
					const data = await mutateAsync({});

					handleDownloadExcel('commissions-exported-at-', data);
				}}
				buttonText='export-as-excel'
				loading={isLoading}
			/>

			<AdminPlansCommissionFilter />
			<AdminPlanCommissionsTable />
		</DgCard>
	);
};

export default AdminPlanCommissions;
