import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import UserPlanCommissionsTable from './components/UserPlanCommissionsTable';
import UserPlansCommissionsFilter from './components/UserPlansCommissionsFilter';
import DgPackageDateDelay from '../../Basic/DgPackageDateDelay';

const UserPlanCommissions = () => {
	return (
		<>
			<div className={'mb-4'}>
				<DgPackageDateDelay />
			</div>
			<DgCard>
				<DgCardHeader title='my-profits' />

				<UserPlansCommissionsFilter />
				<UserPlanCommissionsTable />
			</DgCard>
		</>
	);
};

export default UserPlanCommissions;
