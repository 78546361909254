import Text from 'core/utils/Text';
import DgTab from 'styles/UI/Tab/DgTab';
import UserInfoUpdate from './UserInfo/UserInfoUpdate';
import UserStatusUpdate from './UserStatus/UserStatusUpdate';
import useAdminUserDetail from './hooks/useAdminUserDetail';
import { useParams } from 'react-router-dom';
import UserRoles from './UserRoles/UserRoles';
import UserHistory from './UserHistory/UserHistory';
import UserSecurity from './UserSecurity/UserSecurity';
import { getUserInfoQuery } from 'core/hooks/react-query/getUserInfoQuery';
import SidebarUserInfo from './components/SidebarUserInfo';
import UserWallets from './UserWallets/UserWallets';
import UserChargeWallets from './UserChargeWallets/UserChargeWallets';
import DgSelect from 'styles/UI/Select/DgSelect';
import DgCard from 'styles/UI/Card/DgCard';
import useTranslate from 'core/utils/useTranslate';

const AdminUserDetail = () => {
	const { activeTab, onChangeTabHandler } = useAdminUserDetail();
	getUserInfoQuery();
	const { t } = useTranslate();
	const { userId } = useParams();

	const tabs = [
		{
			key: userId + '',
			label: <Text tid='user-detail' />,
			children: <UserInfoUpdate />,
		},
		{
			key: 'user-status',
			label: <Text tid='user-status' />,
			children: <UserStatusUpdate />,
		},
		{
			key: 'user-security',
			label: <Text tid='user-security' />,
			children: <UserSecurity />,
		},
		{
			key: 'user-history',
			label: <Text tid='user-history' />,
			children: <UserHistory />,
		},
		{
			key: 'user-role',
			label: <Text tid='user-role' />,
			children: <UserRoles />,
		},
		{
			key: 'user-wallets',
			label: <Text tid='user-wallets' />,
			children: <UserWallets />,
		},
		{
			key: 'user-charge-wallets',
			label: <Text tid='user-charge-wallets' />,
			children: <UserChargeWallets />,
		},
	];

	const options = tabs.map((item) => ({
		label: item.key === userId + '' ? t('user-detail') : t(item.key),
		value: item.key,
		filter: item.key === userId + '' ? t('user-detail') : t(item.key),
	}));

	return (
		<>
			<DgCard className='md:hidden block'>
				<SidebarUserInfo />
				<DgSelect
					value={activeTab}
					options={options}
					className='w-full mb-3 text-center'
					customOnChange={(key) => onChangeTabHandler(key)}
				/>
			</DgCard>
			<DgTab
				tabBarExtraContent={{
					left: <SidebarUserInfo />,
				}}
				onChange={onChangeTabHandler}
				tabPosition='left'
				tabs={tabs}
				activeTab={activeTab}
				className='user-detail rtl:rtl-user-detail'
			/>
		</>
	);
};

export default AdminUserDetail;
