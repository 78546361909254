import { Form } from 'antd';
import {
	sendNotificationMessage,
	sendPrivateNotificationMessage,
} from 'core/hooks/react-query/sendNotificationMessage';
import { useDebounce } from 'core/hooks/useDebounce';
import useModal from 'core/hooks/useModal';
import { useState } from 'react';

const useSendNotificationWidget = () => {
	const { mutate: sendNotification, isLoading: isLoadingSendMessage } =
		sendNotificationMessage();

	const { mutate: sendPrivateNotification, isLoading: isLoadingSendPrivateMessage } =
		sendPrivateNotificationMessage();

	// hooks
	const { modal, hideModal, showModal } = useModal();
	const [form] = Form.useForm();

	const onSendNotificationHandler = (values: { title: string; body: string }) => {
		// return console.log({ values });

		(isGlobal ? sendNotification : sendPrivateNotification)(
			{ ...values },
			{
				onSuccess: () => {
					hideModal();
					form.resetFields();
				},
			}
		);
	};

	const [isGlobal, setIsGlobal] = useState(true);
	const [selectedUser, setSelectedUser] = useState([]);

	const [searchQuery, setSearchQuery] = useState<string>('');
	const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

	return {
		onSendNotificationHandler,
		modal,
		showModal,
		hideModal,
		isLoadingSendMessage,
		form,
		isGlobal,
		setIsGlobal,
		selectedUser,
		setSelectedUser,
		searchQuery,
		setSearchQuery,
		debouncedSearchQuery,
	};
};

export default useSendNotificationWidget;
