import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useUpdateBinaryPositionQuery = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.UPDATE_BINARY_POSITION],
		(data) => api.put(endPointUrls.UPDATE_BINARY_POSITION, data),
		{
			onSuccess: (res: any) => {
				if (res?.status === 200) {
					queryClient.invalidateQueries([endPointUrls.MLM_INFO]);
					toastHandler('success', res.data.message);
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult;
};
