import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminOrderTable from './components/AdminOrderTable';
import AdminOrderFilter from './components/AdminOrderFilter';
import { useExportAdminOrderQuery } from './hooks/react-query/useAdminOrderQuery';
import { useEffect } from 'react';
import { handleDownloadExcel } from 'core/helpers/excel';

const AdminOrder = () => {
	const { mutateAsync, data, isLoading } = useExportAdminOrderQuery();

	return (
		<DgCard>
			<DgCardHeader
				title='invests'
				onClick={async () => {
					const data = await mutateAsync({});

					handleDownloadExcel('orders-exported-at-', data);
				}}
				buttonText='export-as-excel'
				loading={isLoading}
			/>

			<AdminOrderFilter />
			<AdminOrderTable />
		</DgCard>
	);
};

export default AdminOrder;
