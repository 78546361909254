import { FC, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import Sidebar from './Sidebar';
import MasterHeader from './MasterHeader';
import { TABLET_SIZE } from '../../core/constants/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMainTheme } from '../../core/utils/theme';
import BottomBar from './BottomBar';
import SupportChat from './crisp/components/SupportChat';
import { routes } from 'core/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getCurrentUserInfoQuery } from '../../core/hooks/react-query/getCurrentUserInfoQuery';
import DgBreadcrumb from 'styles/UI/Breadcrumb/DgBreadcrumb';
import HeaderAlert from 'components/HeaderAlert/HeaderAlert';
import { getUserWalletsQuery } from 'core/hooks/react-query/getUserWalletsQuery';
import HijackLayout from './HijackLayout';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import ActiveGoogle2FA from 'components/Profile/components/ProfileSecurity/components/ActiveGoogle2FA';
import UserGoogleAuthPageList from 'pages/UserGoogleAuth/UserGoogleAuthPageList';
import DgSkeleton from 'styles/UI/Skeleton/DgSkeleton';
import { getExchangeKYCQuery } from 'core/hooks/react-query/kyc';
import toastHandler from 'core/helpers/toast/toast';
import { useLogoutQuery } from 'core/hooks/react-query/useLogoutQuery';
import useTranslate from 'core/utils/useTranslate';

interface IPrivateLayout {
	children: ReactNode;
}

const PrivateLayout: FC<IPrivateLayout> = ({ children }) => {
	const { t } = useTranslate();

	// query
	getCurrentUserInfoQuery();
	getUserWalletsQuery();

	// hooks
	const navigate = useNavigate();
	const location = useLocation();
	const windowSize = useWindowSize();
	const { width } = useWindowSize();

	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	const { data: kycInfo, refetch: refetchKyc } = getExchangeKYCQuery();
	const { refetch: logout, isFetching: isFetchingLogout } = useLogoutQuery('layout');

	// store
	const { isAuthenticated, isHijack } = useSelector(
		(store: RootState) => store.mainInfoStore
	);
	const { lang, theme, isEnglish } = useSelector(
		(store: RootState) => store.settingStore
	);

	// state
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// useEffect(() => {
	// 	if (isAuthenticated) {
	// 		refetchKyc();
	// 	}
	// }, [isAuthenticated]);

	useEffect(() => {
		if (kycInfo?.data?.is_deactivate && isAuthenticated) {
			toastHandler('error', t('please-verify-user-account'));
			logout();
			navigate(routes.REGISTER_SIGNING);
		}
	}, [kycInfo, isAuthenticated]);

	useEffect(() => {
		if (!isAuthenticated && location.pathname !== routes.REGISTER_SIGNING) {
			navigate(routes.REGISTER_SIGNING);
		}
	}, []);

	useEffect(() => {
		setIsMenuOpen(false);
	}, [location]);

	// console.log({ kycInfo });

	return (
		<ThemeProvider theme={getMainTheme(theme, lang)}>
			<MasterHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

			<div className='min-h-screen flex lg-969:flex-row flex-col'>
				<>
					<Sidebar
						windowSize={windowSize}
						isOpen={isMenuOpen}
						setIsMenuOpen={setIsMenuOpen}
					/>
				</>
				<div
					className={
						'flex-1 mt-7 mb-28 sm:mb-7 xs:px-7 px-3 lg-969:mx-auto mx-0 max-w-[1350px] w-full min-w-0'
					}
				>
					<div className={'mb-5 mx-auto flex flex-col gap-y-5'}>
						<div className='flex xs:items-center items-start justify-between xs:flex-row flex-col xs:space-y-0 space-y-5'>
							<DgBreadcrumb />
							{isHijack && <HijackLayout />}
						</div>

						<HeaderAlert />
					</div>
					{children}
				</div>
			</div>

			{width < TABLET_SIZE && <BottomBar />}

			<SupportChat />
		</ThemeProvider>
	);
};

export default PrivateLayout;
