import UserOrdersTable from 'components/UserOrders/List/components/UserOrdersTable';
import { routes } from 'core/constants/routes';
import { RootState } from 'core/store';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';

const DashboardPlans = () => {
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const navigate = useNavigate();

	const ButtonPlanHistory =
		lang === 'en' ? (
			<FaArrowCircleRight onClick={() => navigate(routes.ORDERS_LIST)} style={{ cursor: 'pointer' }} size={24} color='var(--dg-orange-color)' />
		) : (
			<FaArrowCircleLeft onClick={() => navigate(routes.ORDERS_LIST)} style={{ cursor: 'pointer' }} size={24} color='var(--dg-orange-color)' />
		);

	return (
		<DgCard isMarginBottom={false} className='col-span-12'>
			<DgCardHeader title='invests-history' customButton={ButtonPlanHistory} />

			<UserOrdersTable limit={10} scroll={{ y: '450px', x: 'auto' }} />
		</DgCard>
	);
};

export default DashboardPlans;
