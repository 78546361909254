import useQueryHook from '../../../core/hooks/masterQuery/useQueryHook';
import { endPointUrls } from '../../../core/constants/endPointUrls';
import useQueryUrlParam from '../../../core/hooks/useQueryUrlParam';
import api from '../../../core/services/fetch-api/useApi';
import { useMutation } from '@tanstack/react-query';

export const useAdminRegisters = () => {
	const { filterParams } = useQueryUrlParam();

	return useMutation(
		[endPointUrls.ADMIN_REGISTERS(), filterParams],
		(data) => api.post(endPointUrls.ADMIN_REGISTERS(filterParams), data),
		{
			select: (res) => res?.data,
			keepPreviousData: true,
		}
	);
};
