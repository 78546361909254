import Text from 'core/utils/Text';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminRoleQuery } from '../hooks/react-query/useAdminRoleQuery';
import { Badge } from 'antd';

const AdminRolesTable = () => {
	const { data: roles, isLoading: roleIsFetching } = useAdminRoleQuery();

	const columns = [
		{
			title: <Text tid='role-id' />,
			key: 'id',
			dataIndex: 'id',
			render: (text: any) => <Badge count={text} color='var(--dg-info-color)' />,
		},
		{
			title: <Text tid='role-title' />,
			key: 'name',
			dataIndex: 'name',
			render: (text: any) => text,
		},
	];

	return (
		<>
			<DgTable columns={columns} dataSource={roles?.data.data} loading={roleIsFetching} />
		</>
	);
};

export default AdminRolesTable;
