import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IPackageList } from '../../../../../../core/types/models/packageType';

export const usePackageQuery = () => {
	return useQueryHook(
		[endPointUrls.PACKAGES_LIST],
		(data: any) => api.get(endPointUrls.PACKAGES_LIST, data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IPackageList, Error>;
};
