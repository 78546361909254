import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import { useEffect } from 'react';
import DgTitle2 from 'styles/UI/Title/DgTitle2';
import ForgetPassword from './ForgetPassword/ForgetPassword';
import Login from './Login/components/Login';
import Register from './Register/components/Register';
import RegisterVerification from './Register/components/RegisterVerification';
import ForgetPasswordOtp from './ForgetPassword/components/ForgetPasswordOtp';
import DgAlert from 'styles/UI/Alert/DgAlert';

const LoginRegister = () => {
	const { query, setUrlParam } = useMasterQueryUrlParam();

	useEffect(() => {
		if ((query?.page && isNaN(query?.page)) || query?.page <= 0 || query?.page >= 6)
			setUrlParam({ ...query, page: 1 });
	}, [query]);

	return (
		<div className=''>
			<DgAlert
				className='mb-4 bg-[var(--dg-gray-color)] rounded-md border-none flex items-center justify-start'
				message={
					<p className='m-0 text-center whitespace-pre-line font-[yekan]'>
						<span className='font-[yekan] m-0 text-lg font-bold'>
							شرکت مدیریت سرمایه دنیای بی نهایت (سهامی خاص)
						</span>{' '}
						<br /> شناسه ملی 14013209669
					</p>
				}
			></DgAlert>

			<DgTitle2 className='text-center my-6 !text-3xl'>
				{!query?.page || query?.page === 1
					? 'signin'
					: query?.page === 2
					? 'signup'
					: query?.page === 3
					? 'verify-email'
					: query?.page === 4
					? 'forget-password'
					: query?.page === 5
					? 'reset-password'
					: ''}
			</DgTitle2>

			{!query?.page || query?.page === 1 ? <Login /> : query?.page === 2 && <Register />}
			{query?.page === 3 && <RegisterVerification />}
			{query?.page === 4 && <ForgetPassword />}
			{query?.page === 5 && <ForgetPasswordOtp />}

			{/* {firstStep ? <Login loginHandlers={loginHandlers} /> : <SignUp signUpHandlers={signUpHandlers} />} */}
		</div>
	);
};

export default LoginRegister;
