import Counts from './Counts/Counts';

//constants

const DashboardFake = () => {
	//states

	return (
		<div className='grid gap-3 mb-10'>
			<div className='col-span-full'>
				<Counts />
			</div>
		</div>
	);
};

export default DashboardFake;
