import { useEffect, useState } from 'react';
import useQueryUrlParam from './useQueryUrlParam';

const useSelected = (active?: string | number, hasUrlParam = true, deSelected = false, sort = false) => {
	const { query, setUrlParam, search, deleteSort } = useQueryUrlParam();
	const [selectedType, setSelectedType] = useState<{ index: number | string; data: any }>({ index: hasUrlParam ? query?.tab : active, data: {} });
	const [selectedSort, setSelectedSort] = useState({ sort: query?.sort, sort_way: query?.sort_way });

	const selectedTypeHandler = (index: string | number, data?: any) => {
		setSelectedType({ index: deSelected ? (index === selectedType.index ? -1 : index) : index, data: deSelected ? (index === selectedType.index ? {} : data) : data });
	};

	useEffect(() => {
		if (hasUrlParam) {
			const newQueryParam = {
				...query,
				tab: query?.tab || active,
			};

			setSelectedType({ index: query?.tab || active, data: {} });
			hasUrlParam && setUrlParam(newQueryParam);
		}

		if (sort) {
			setSelectedSort({ sort: query?.sort, sort_way: query?.sort_way });
		}
	}, [search]);

	const selectedSortHandler = (sort: string, sort_way: 'ASC' | 'DESC') => {
		if (sort === query?.sort && sort_way === query?.sort_way) return deleteSort();
		setUrlParam({ ...query, sort, sort_way });
	};

	return { selectedType, selectedTypeHandler, setUrlParam, selectedSortHandler, selectedSort };
};

export default useSelected;
