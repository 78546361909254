import DgTable from 'styles/UI/Table/DgTable';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import useQueryNoLocation from 'core/hooks/useNoQueryUrlParam';
import { useUserEmailVerificationHistoryQuery } from '../hooks/react-query/useUserEmailVerificationHistoryQuery';
import { paginateDataHandler } from 'core/utils/utils';
import UserEmailVerificationHistoryTableColumn from './Column/UserEmailVerificationHistoryTableColumn';

const UserEmailVerificationHistoryTable = () => {
	const { data, isFetching } = useUserEmailVerificationHistoryQuery();
	const { handlePagination, page, per_page } = useQueryNoLocation();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserEmailVerificationHistoryTableColumn()}
				dataSource={paginateDataHandler(data?.data || [], page, per_page)}
				count={data?.data?.length}
				loading={isFetching}
				current={page}
				per_page={per_page}
			/>
			<DgPagination onPaginationHandler={handlePagination} responsive={true} per_page={per_page} current={page} total={data?.data?.length} />
		</>
	);
};

export default UserEmailVerificationHistoryTable;
