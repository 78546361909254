import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import useAdminPackage from './hooks/useAdminPackage';
import DgModal from 'styles/UI/Modal/DgModal';
import AdminPackageTable from './components/AdminPackageTable';
import AdminPackageUpdate from '../Update/AdminPackageUpdate';

const AdminPackage = () => {
	const { hideModalHandler, modal, onEditPackageHandler } = useAdminPackage();

	return (
		<>
			<DgModal title='update-package' open={modal} onCancel={hideModalHandler}>
				<AdminPackageUpdate hideModalHandler={hideModalHandler} />
			</DgModal>

			<DgCard>
				<DgCardHeader title='package-management' />

				<AdminPackageTable onEditSettingsHandler={onEditPackageHandler} />
			</DgCard>
		</>
	);
};

export default AdminPackage;
