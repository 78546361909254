import { MenuProps } from 'antd';
import UserAvatar from 'components/Admin/User/Detail/UserInfo/components/userAvatar';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import { IoCameraOutline } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import { FC, RefObject, FormEvent } from 'react';
import { userDetail } from 'core/types/models/userInfo';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgReferralCode from 'styles/UI/RefferalCode/DgReferralCode';

interface IProfileSidebarHeader {
	onUpdateAvatarClicked: (e: FormEvent<HTMLInputElement>) => void;
	avatarRef: RefObject<HTMLInputElement>;
	isLoadingUpdateAvatar: boolean;
	isLoadingGetUser: boolean;
	getDropdownStatus: (openDropdown: any, hideDropdown: any, showDropdown: any) => void;
	currentUserInfo?: userDetail;
}

const ProfileSidebarHeader: FC<IProfileSidebarHeader> = ({ onUpdateAvatarClicked, avatarRef, isLoadingUpdateAvatar, isLoadingGetUser, currentUserInfo, getDropdownStatus }) => {
	const { t } = useTranslate();

	const items: MenuProps['items'] = [
		{
			label: (
				<div
					className='flex items-center gap-x-3 py-4 hover:text-[var(--dg-orange-color)] px-5 dark:bg-[var(--dg-card-bg-2)] bg-dg-white-color cursor-pointer'
					onClick={() => avatarRef?.current?.click()}
				>
					<IoCameraOutline size={20} />
					<span className='text-[var(--dg-primary-color)] hover:text-[var(--dg-orange-color)]'>{t('upload-avatar')}</span>
				</div>
			),
			key: '0',
		},
	];

	return (
		<DgSpinnerFull isLoading={isLoadingGetUser}>
			<div className='p-4 md:border-b border-b-0 border-[var(--dg-border-color)]'>
				<div className='flex items-center gap-x-4'>
					<div>
						<UserAvatar
							onUpdateAvatarClicked={onUpdateAvatarClicked}
							showUploadButton={false}
							className='w-12 h-12 text-base'
							avatarRef={avatarRef}
							isLoadingUpdateAvatar={isLoadingUpdateAvatar}
							userInfo={currentUserInfo}
						/>
					</div>
					<div className='flex flex-col flex-1'>
						<span className=''>{currentUserInfo?.data.full_name}</span>
						<span className='text-xs text-[var(--dg-disabled-color-light)] break-all'>{currentUserInfo?.data.email}</span>

						<hr className='border-[var(--dg-border-color)] my-4' />
					</div>

					<div className='ltr:text-right rtl:text-left'>
						<DgDropDown menu={{ items }} getDropdownStatus={getDropdownStatus}>
							<span className='hover:bg-[var(--dg-card-bg-2)] cursor-pointer p-0 xs:p-1 opacity-100 rounded-full transition-colors duration-300 before:opacity-0 inline-flex'>
								<MdMoreVert size={25} />
							</span>
						</DgDropDown>
					</div>
				</div>

				<DgReferralCode member_id={currentUserInfo?.data?.member_id || ''} />
			</div>
		</DgSpinnerFull>
	);
};

export default ProfileSidebarHeader;
