import i18n from '../../core/i18n';
import { FC, ReactNode } from 'react';
import ReduxProvider from './redux/ReduxProvider';
import ReactQueryProviders from './react-query/ReactQueryProviders';
import ToastProvider from './toast/ToastProvider';
import AppProvider from './app/AppProvider';
import { I18nextProvider } from 'react-i18next';
// import i18n from 'core/i18n';

interface IIndexProviders {
	children: ReactNode;
}

const IndexProviders: FC<IIndexProviders> = ({ children }) => {
	return (
		<I18nextProvider i18n={i18n} defaultNS={'translation'}>
			<ReduxProvider>
				<ReactQueryProviders>
					<AppProvider>
						{children}
						<ToastProvider />
					</AppProvider>
				</ReactQueryProviders>
			</ReduxProvider>
		</I18nextProvider>
	);
};

export default IndexProviders;
