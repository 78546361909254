import { getUserInfoQuery } from 'core/hooks/react-query/getUserInfoQuery';
import { useParams } from 'react-router-dom';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminUserWalletsQuery } from '../hooks/useAdminUserWalletsQuery';
import AdminUserWalletsTableColumn from './AdminUserWalletsTableColumn';

const AdminUserWalletsTable = () => {
	const { data: userWallets, isFetching: userWalletsIsFetching } = useAdminUserWalletsQuery();
	const { isFetching: userDetailIsFetching } = getUserInfoQuery();
	const { userId } = useParams();

	return (
		<DgTable
			rowKey='id'
			columns={AdminUserWalletsTableColumn(userId)}
			loading={userDetailIsFetching || userWalletsIsFetching}
			dataSource={userWallets?.data.filter((item) => item.name === 'Deposit Wallet')}
		/>
	);
};

export default AdminUserWalletsTable;
