import { Form } from 'antd';
import { useRunAfterUpdate } from 'core/hooks/useRunAfterUpdate';
import { onInputValueChangeUtil } from 'core/utils/useInputValueChange';
import { useState, FormEvent, useEffect } from 'react';
import { useDepositQuery } from './react-query/useDepositQuery';

const useDepositStepOne = () => {
	// hooks
	const [form] = Form.useForm();
	const runAfterUpdate = useRunAfterUpdate();

	// state
	const [amount, setAmount] = useState<string>('');

	// query
	const { mutate: depositWallet, isLoading: isLoadingDepositWallet } = useDepositQuery();

	const onChangeInputHandler = (e: FormEvent<HTMLInputElement>) => {
		setAmount(onInputValueChangeUtil(e, runAfterUpdate));
	};

	useEffect(() => {
		form.setFieldsValue({ amount });
	}, [amount]);

	const onFinishDeposit = (values: { amount: string; one_time_password: string }) => {
		depositWallet({
			one_time_password: values.one_time_password,
			amount: +values.amount.replace(/,/g, ''),
		});
	};

	return { onFinishDeposit, amount, onChangeInputHandler, form, isLoadingDepositWallet };
};

export default useDepositStepOne;
