import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IPlanCommissionList } from 'core/types/models/planCommissionsType';

export const useUserPlanCommissionsQuery = () => {
	const { filterParams } = useQueryUrlParam();

	return useQueryHook(
		[endPointUrls.MLM_GET_COMMISSIONS(), filterParams],
		(data: any) => api.get(endPointUrls.MLM_GET_COMMISSIONS(filterParams), data),
		{
			// select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<IPlanCommissionList, Error>;
};
