import useModal from 'core/hooks/useModal';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { setPackageDetail } from 'core/store/slice/package/packageReducer';
import { IPackage } from 'core/types/models/packageType';
import { useDispatch } from 'react-redux';

const useAdminPackage = () => {
	// hooks
	const dispatch = useDispatch();
	const { hideModal, modal, showModal } = useModal();

	const hideModalHandler = () => {
		hideModal();
		dispatch(setApiCatcherFormErrors([]));
	};

	const onEditPackageHandler = (value: IPackage) => {
		showModal();
		dispatch(setPackageDetail(value));
	};

	return { onEditPackageHandler, hideModalHandler, modal };
};

export default useAdminPackage;
