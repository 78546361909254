import silverIcon from 'assets/images/silver.png';
import goldIcon from 'assets/images/gold.png';
import bronzeIcon from 'assets/images/bronze.png';
import PackageItem from './components/PackageItem';
import usePackageList from './hooks/usePackageList';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';

const PackageList = ({ queryType }: { queryType: 'package_id' | 'category_id' }) => {
	const { packageList, selectedType, selectedTypeHandler, isFetchingPackageList } =
		usePackageList({ queryType });

	const newPackageList = packageList?.data.map((item, index) => {
		console.log({ index, item });
		const { name } = item;

		return {
			...item,
			src:
				name?.toLowerCase()?.indexOf('silver') >= 0
					? silverIcon
					: name?.toLowerCase()?.indexOf('gold') >= 0
					? goldIcon
					: name?.toLowerCase()?.indexOf('bronze') >= 0
					? bronzeIcon
					: undefined,
		};
	});

	return (
		<DgSpinnerFull isLoading={isFetchingPackageList}>
			<div className='grid grid-cols-1 lg-500:grid-cols-2 lg-1200:grid-cols-3 gap-5 min-h-[300px] max-w-6xl w-full mx-auto'>
				{newPackageList?.map((item) => (
					<PackageItem
						queryType={queryType}
						key={item.id}
						onClick={() => selectedTypeHandler(item.id, item)}
						selected={selectedType.index === item.id}
						item={item}
					/>
				))}
			</div>
		</DgSpinnerFull>
	);
};

export default PackageList;
