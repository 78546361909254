import DgCard from 'styles/UI/Card/DgCard';
import UserWithdrawFormStepOne from './components/UserWithdrawFormStepOne';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import UserWithdrawStepTwoForm from './components/UserWithdrawStepTwoForm';

const UserWithdraw = () => {
	const { query } = useMasterQueryUrlParam();

	return (
		<>
			<DgCard hasBackground={false} className='border-2 border-dashed border-[var(--dg-opposite-color)] max-w-2xl mx-auto'>
				{query?.tab !== 2 && <UserWithdrawFormStepOne />}
				{query?.tab === 2 && <UserWithdrawStepTwoForm />}
			</DgCard>
		</>
	);
};

export default UserWithdraw;
