import Text from 'core/utils/Text';
import { BsArrowBarUp } from 'react-icons/bs';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import { ReactComponent as StepTwo } from 'assets/images/step-2.svg';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import DgButton from 'styles/UI/Button/DgButton';
import { routes } from '../../../core/constants/routes';
import useWithdrawStepTwo from '../hooks/useWithdrawStepTwo';
import UserDepositWithdrawUpdateInvoice from 'components/UserDeposit/components/UserDepositWithdrawUpdateInvoice';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

const UserWithdrawStepTwoForm = () => {
	const { withdrawDetail, invoice, isFetchingInvoice, navigate } = useWithdrawStepTwo();
	const { isEnglish } = useSelector((store: RootState) => store.settingStore);

	return (
		<DgSpinnerFull isLoading={isFetchingInvoice}>
			<Text tid='withdraw-info' className='text-[var(--dg-orange-color)] font-bold' />

			<BsArrowBarUp size={50} className='mx-auto' />

			<div className='max-w-md mx-auto'>
				<div className='text-center mb-10 mt-5 flex flex-col gap-3'>
					<Text tid='wait-withdraw' className='text-lg font-bold' />
					{/* <Text tid='copy-address-or-scan-qr-code' /> */}
				</div>

				<DgFormWrapper singleColumn>
					<div className='flex flex-col flex-1 justify-around space-y-5'>
						<div className='flex items-center justify-between'>
							<Text tid='address' />
							<span className={`xs:max-w-full max-w-[20ch] break-all xs:break-before-auto ${isEnglish ? 'ml-auto' : 'mr-auto'}`}>{invoice?.data?.checkout_link}</span>
						</div>

						<div className='flex items-center justify-between'>
							<Text tid='amount' />
							<DgPrice price={invoice?.data?.amount} />
						</div>

						<div className='flex items-center justify-between'>
							<Text tid='payment-amount' />
							<DgPrice price={withdrawDetail?.paid_amount || invoice?.data?.paid_amount} />
						</div>

						<div className='flex items-center justify-between'>
							<Text tid='currency' />
							<span>USDT</span>
						</div>
					</div>

					<div className='flex justify-between items-center mt-3'>
						<Text tid='status' />
						{withdrawDetail?.status ? (
							withdrawDetail?.status === 'success' ? (
								<Text tid='success' className='bg-[var(--dg-green-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
							) : withdrawDetail?.status === 'pending' ? (
								<Text tid='pending' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md animate-bounce' />
							) : withdrawDetail?.status === 'confirming' ? (
								<Text tid='confirm' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md' />
							) : withdrawDetail?.status === 'error' ? (
								<Text tid='error' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
							) : withdrawDetail?.status === 'expired' ? (
								<Text tid='expired' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
							) : withdrawDetail?.status === 'partiallyPaid' ? (
								<Text tid='partially-paid' className='bg-[var(--dg-orange-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
							) : withdrawDetail?.status === 'refunded' ? (
								<Text tid='refunded' className='bg-[var(--dg-info-color)] py-2 px-5 rounded-md' />
							) : (
								''
							)
						) : invoice?.data?.status === 'success' ? (
							<Text tid='success' className='bg-[var(--dg-green-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'pending' ? (
							<Text tid='pending' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md animate-bounce' />
						) : invoice?.data?.status === 'confirming' ? (
							<Text tid='confirm' className='bg-[var(--dg-input-bg)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'error' ? (
							<Text tid='error' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'expired' ? (
							<Text tid='expired' className='bg-[var(--dg-red-color-2)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'partiallyPaid' ? (
							<Text tid='partially-paid' className='bg-[var(--dg-orange-color)] text-[var(--dg-primary-color-fixed)] py-2 px-5 rounded-md' />
						) : invoice?.data?.status === 'refunded' ? (
							<Text tid='refunded' className='bg-[var(--dg-info-color)] py-2 px-5 rounded-md' />
						) : (
							''
						)}
					</div>

					<UserDepositWithdrawUpdateInvoice timeStamp={invoice?.data?.expiration_time || 0} />

					{(withdrawDetail?.status?.includes('started') || invoice?.data?.status?.includes('started')) && (
						<DgButton title='view-invoice-list' className='w-full' onClick={() => navigate(routes.WALLETS)} />
					)}

					<StepTwo className='mx-auto mt-5' />
				</DgFormWrapper>
			</div>
		</DgSpinnerFull>
	);
};

export default UserWithdrawStepTwoForm;
