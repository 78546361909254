import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';

export const getExchangeKYCQuery = () => {
	// const { data: kycInfo, refetch: refetchKyc } = getExchangeKYCQuery();

	return useQueryHook(
		[endPointUrls.EXCHANGE_KYC],
		() => api.get(endPointUrls.EXCHANGE_KYC),
		{
			// select: (res: any) => res.data,
			refetchInterval: false,
			refetchOnMount: false,
			// enabled: kycInfo?.data?.is_deactivate === false,
			enabled: false,
		}
	) as UseQueryResult<any, Error>;
};
